import React from 'react'
import { Container} from "reactstrap";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from 'components/CustomInput/CustomInput.js';
import { useState } from 'react';
import { getToken } from 'common/Authentication';

function UserSettingsSection() {    

    const [loginData , setLoginData] = useState({
        oldpassword: "",
        newpassword: "",
        confirmpassword: ""
    });
    let token = getToken();

    const handleSubmit = (data) => {
        if(data.confirmpassword !== data.newpassword)
        {
            alert("please reenter correct password");
            return;
        }

        if(data.newpassword === data.oldpassword)
        {
            alert("new password should be different from old password!");
            return;
        }
        let URL ;
        console.log(process.env.REACT_APP_ENV)
        if(process.env.REACT_APP_ENV === 'local')
        {
            URL = "/auth/change_password";

        }
        else
        {
            URL =  "/auth/change_password"
        }
        console.log(URL)
        //URL = "http://localhost:5000/auth/change_password/"
        let jsonRequest = JSON.stringify({old_password: data.oldpassword,new_password: data.newpassword})
        fetch(URL, {method:'POST', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest }).then(res=>{
            if(res.status === 200){
                return res.json();
            } else {
                alert("Failed to update the password! Please check the credentials and try again after sometime.");
            }
        })
        .then((res) => {
            console.log(res);
            alert("Updated your password successfully.");
        }).catch(e=>{
            console.log(e);
            alert("Failed to update the password! Please check the credentials and try again after sometime.");
        })
    }

    return (
        <>           
            <Container>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                        labelText="old password"
                        id="oldPassword"
                        formControlProps={{
                            fullWidth: true
                        }}                     
                        inputProps={{
                            placeholder: "old password",
                            type: "password",
                            onChange: (e)=>{loginData.oldpassword=e.target.value},
                            autoComplete: "off",
                        }}
                    />
                    <CustomInput
                        labelText="new password"
                        id="newPassword"
                        formControlProps={{
                            fullWidth: true
                        }}                     
                        inputProps={{
                            placeholder: "new password",
                            type: "password",
                            onChange: (e)=>{loginData.newpassword=e.target.value},
                            autoComplete: "off",
                        }}
                    />
                    <CustomInput
                        labelText="confirm password"
                        id="confirmPassword"
                        formControlProps={{
                            fullWidth: true
                        }}                       
                        inputProps={{
                            placeholder: "confirm password",
                            type: "password",
                            onChange: (e)=>{loginData.confirmpassword=e.target.value},
                            autoComplete: "off",
                        }}
                    />
                    <button type='submit' className="btn btn-info btn-md" onClick={(e)=>{e.preventDefault();handleSubmit(loginData);}}>
                        change password
                    </button>
                </GridItem>
            </Container>
        </>
    )
}

export default UserSettingsSection
