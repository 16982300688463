import React , { useState, useEffect } from "react";
import UserSettingsSection from './Sections/UserSettingsSection';
import AdminSettingsSection from './Sections/AdminSettingsSection'
import { Container } from 'reactstrap';
import {checkUser} from "common/Authentication";
import VerticalNav from "container/NavBar/VerticalNav";

function Settings() {
    
    const [adminDetails, setAdminDetails] = useState([]);

    useEffect(async () => {
        let data = await checkUser();
        console.log(data)
        setAdminDetails(data)
    }, [])


    return (
        <>
        <div style={{height: '100vh', position: 'fixed', zIndex: '10'}}>
          <VerticalNav/>
        </div>
        <Container>
            <Container>
                <h2>Change Password</h2>
                <UserSettingsSection />
            </Container>
            {adminDetails["admin"] &&
                <Container>
                    <br></br>
                    <h2>Admin settings</h2>
                    <AdminSettingsSection adminDetails={adminDetails}/>
                </Container>
            }
        </Container>
        </>
    )
}

export default Settings
