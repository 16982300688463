export async function checkUser() {
    let data;
    let token = localStorage.getItem("sso_token");
    console.log("chack "+process.env.REACT_APP_BASE_URL);
    await fetch(process.env.REACT_APP_BASE_URL+"/auth/token-validate", 
        {
            method:'GET', 
            headers: 
                { 
                    Authorization: `Bearer ${token}`,
                    RequestTime:new Date()
                }
        })
    .then(res => {
        if(res.status === 200){
            return res.json();
        }
        else
        {
            throw "temp"
        }
    }
    )
    .then(res => {
        data = res.data;
        // console.log(data);
    })
    .catch(res => {
        data = null;
    })

    console.log(data);
    return data;
}

export function setToken(sso_token) {
    localStorage.setItem("sso_token",sso_token)
}

export function getToken() {
    return  localStorage.getItem("sso_token");
}
export function logout(){
    localStorage.removeItem("sso_token");
}
