/* eslint-disable */
import React, { Component } from "react";
import Table from "components/Table2/Table2.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import PlayButton from "../../CommonSections/PlayButton";
import download from "container/Landing/download.png";
import Button from "@mui/material/Button";
import ShareButton from "../../CommonSections/ShareButton";
import Checkbox from '@mui/material/Checkbox';
import WhatsAppModel from "container/CommonSections/WhatsAppModel/WhatsAppModel";
import AddIcon from '@mui/icons-material/Add';
import {Link} from "react-router-dom";

class ListSection extends Component {
    constructor(props){
        super(props);
        this.state = {
            arr: [],
            noUrl: false
        },
        this.prepareTableData = this.prepareTableData.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    handleCheckbox = (id, data) => {
        if(this.props.selected_urls[id]){
            let selected_urls = {...this.props.selected_urls};
            delete selected_urls[id];
            this.props.setSelectedUrls(selected_urls);
        }
        else{
            let selected_urls = {...this.props.selected_urls, [id]: data}
            this.props.setSelectedUrls(selected_urls);
        }
    }

    prepareTableData() {
        return this.props.arr.map((req, idx) =>
            [<div style={{overflowX: 'hidden', borderRadius: "8px" }}>
                <GridContainer 
                    style={{
                        paddingLeft:'15px', 
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        backgroundColor: "#E7E7E7", 
                        borderRadius: "8px", 
                        fontSize: "16px",
                        borderBottom: (idx!==this.props.arr.length-1)?"1px solid red":"",
                    }}
                >
                    <GridItem sm={2} md={2}>
                        <GridContainer>
                            <GridItem md={6} sm={6}>
                                <Checkbox 
                                    onChange={() => this.handleCheckbox(req.id, {url:req.response_url, title:req.title})} 
                                    name={req.response_url}
                                    checked={Boolean(this.props.selected_urls[req.id])}
                                />
                            </GridItem>
                            <GridItem md={6} sm={6}>
                                {req.thumbnail && req.thumbnail.split(':')[0]=='color'?
                                    <div style={{backgroundColor:req.thumbnail.split(':').slice(1).join(":"), borderRadius: "57%", width: "56px", height: "56px"}}></div>:
                                    <div 
                                        style={{borderRadius: "57%", width: "56px", height: "56px", backgroundSize: 'cover', backgroundPosition: 'center', 
                                            backgroundImage: `url(${req.thumbnail && req.thumbnail.split(':')[0]=='url'?req.thumbnail.split(':').slice(1).join(":"):
                                            "https://images.unsplash.com/photo-1500530855697-b586d89ba3ee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"})`}}
                                    />
                                }
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem sm={2} md={2} style={{display:'flex'}}>
                        <div>
                            {req.title}
                            <br />
                            <span style={{color: "#909090", fontSize: "12px"}}>{req.create_time}</span>
                            <br />
                        </div>
                    </GridItem>
                    <GridItem sm={2} md={2}>
                            <span style={{wordBreak: "break-word"}}>{req.id}</span>
                    </GridItem>
                    <GridItem sm={1} md={1}>
                        {req.synthesis_data.duration?req.synthesis_data.duration.toFixed(2):""}
                    </GridItem>
                    <GridItem sm={2} md={2}>
                            <p 
                                style={
                                    req.response_status==="success" ? 
                                        {color: "#499037"} 
                                        : (req.response_status==="pending" ? 
                                            {color: "#DBB11C"} : (req.response_status === "SAVED" ? {color: "#68BC00"} : {color: "#D03D3D"}))
                                }
                            >
                                {req.response_status}
                            </p>
                    </GridItem>
                    <GridItem md={3} sm={3}>
                        <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center",width:"100%",flexWrap:"wrap"}}>
                            {req.response_status === "success" ?
                                <> 
                                    <PlayButton videoUrl={req.response_url} />
                                    <Button 
                                        onClick={()=>{
                                            fetch(req.response_url, {method: 'GET'}).then((resp)=>resp.blob()).then((blob)=>{
                                                let a = document.createElement("a");
                                                a.href = window.URL.createObjectURL(blob);
                                                a.download = req.title+"."+req.response_url.split(".").pop();
                                                console.log(a);
                                                a.click();
                                            })
                                    }}
                                    style={{minWidth:"0px"}}
                                    >
                                        <div>
                                            <img src={download} alt="download button" />
                                        </div>
                                    </Button>
                                    <ShareButton 
                                        downloadLink={req.response_url} 
                                        shareButtonRequestId={req.id} 
                                        updateShareButtonRequestId={this.props.updateShareButtonRequestId}
                                    />
                                    <Link to={`/app/editor/${req.id}`}>
                                        <Button style={{minWidth:"0px"}}>
                                                <AddIcon style={{color:"#E58200"}}/>
                                        </Button>
                                    </Link>

                                </>
                                :
                                ""
                            }
                            {req.response_status !== "success"?
                                    <>
                                        <Link to={`/app/editor/${req.id}`}>
                                            <Button style={{minWidth:"0px"}}>
                                                    <AddIcon style={{color:"#E58200"}}/>
                                            </Button>
                                        </Link>

                                    </>
                                    :
                                    ""
                            }
                        </div>
                    </GridItem>
                </GridContainer>
            </div>]
        );
    }
    render () {
        let data = this.prepareTableData();
        return(
            <div className="mb-5" style={{border: "1px solid #FFF", borderRadius: "20px"}}>
                <Table
                    tableData={data}
                />
            </div>
        )
    }   
}

export default ListSection;