import React from 'react';
import passwordLogo from "assets/img/iconsSidebar/Password.png"
import BillingLogo from "assets/img/iconsSidebar/Billing.png"
import AdminSettingsLogo from "assets/img/iconsSidebar/AdminSettings.png"
import "./SideNavBar.css";

function SideNavBar(props) {
    let section = props.section;
    const userDetails = props.userDetails;
    // console.log(section);
    return (
    <>
    <div style={{width: "278px"}}>
        <div 
            className='sideNavBar_buttons' 
            style={(section === 'changePassword') ? {backgroundColor: "white"} : {}} 
            onClick={() => {
                props.setSection('changePassword')
            }}
        >
            <img src={passwordLogo} alt="logo" />
            <span>Change password</span>
        </div>
        <div
            className='sideNavBar_buttons' 
            style={(section === 'billingInfo') ? {backgroundColor: "white"} : {}} 
            onClick={() => {
                props.setSection('billingInfo')
            }}
        >
            <img src={BillingLogo} alt="logo" />
            <span>Billing info</span>
        </div>
        {
            userDetails.admin &&
            <>
                <div
                    className='sideNavBar_buttons' 
                    style={(section === 'adminSettings') ? {backgroundColor: "white"} : {}} 
                    onClick={() => {
                        props.setSection('adminSettings')
                    }}
                >
                    <img src={AdminSettingsLogo} alt="logo" />
                    <span>Admin settings</span>
                </div>
                <div
                    className='sideNavBar_buttons' 
                    style={(section === 'disseminationSettings') ? {backgroundColor: "white"} : {}} 
                    onClick={() => {
                        props.setSection('disseminationSettings')
                    }}
                >
                    <span>Dissemination settings</span>
                </div>
            </>
        }
    </div>
    </>
    );
}

export default SideNavBar;
