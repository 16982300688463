/* eslint-disable */
import React from "react";

import { useState } from "react";

import VolumeIcon from "assets/img/iconsSidebar/volume.png";
import PlaceHolderIcon from "assets/img/iconsSidebar/placeholder.png";
import InitiateRequestIcon from "assets/img/iconsSidebar/iconsSidebarMenu/InitiateRequest.png";
import GenerateMenuButton from "../CustomNavBarButtons/GenerateMenuButton";
import Popover from "@mui/material/Popover";
import { Autocomplete, TextField } from "@mui/material";

const GeneratePlaceHolderAndSilence = (props) => {
  const classes = props.isSideBarOpen === null ? " flex-row " : " flex-column ";
  const isRow = props.isSideBarOpen !== null;

  const { insertPlaceHolder, isEditorOpen, allPlaceholders } = props;

  const [popoverData, setPopoverData] = useState("");
  const [popoverInputString, setPopoverInputString] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event, inputString) => {
    setPopoverData("");
    setPopoverInputString(inputString);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (!popoverData) {
      return;
    }

    const isPlaceHolder = popoverInputString.includes("Placeholder");
    if (isPlaceHolder) {
      insertPlaceHolder(popoverData);
      return;
    }

    insertSilence(popoverData);
  };

  const buttonsData = [
    {
      name: "Initiate Request",
      icon: InitiateRequestIcon,
      onClick: ()=>{props.initiateRequest()},
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      className={ "d-flex  flex-column align-items-center" }
      style={{ borderRadius: "10px" }}
    >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        marginThreshold={100}
        PaperProps={{style:{overflow: "visible"}}}
      >
        <form className="d-flex flex-column align-items-center p-2 mb-0">
          {popoverInputString.includes("Placeholder")?
            <Autocomplete
              disablePortal
              id="popOverInput"
              options={allPlaceholders.includes(popoverData)?allPlaceholders:[popoverData].concat(allPlaceholders)}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Placeholder" />}
              inputValue={popoverData}
              onInputChange={(event, newInputValue) => {setPopoverData(newInputValue)}}
            />:
            <TextField
              id="popOverInput"
              label="seconds"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={popoverData}
              onChange={(e) => e.target.value>0 && setPopoverData(e.target.value)}
            />
          }
          {/* <input
            id="popOverInput"
            style={{
              borderRadius: "5px",
              border: "0.5px solid #d7d7d7",
              background: "#d7d7d7",
              padding: "5px 10px",
            }}
            value={popoverData}
            onChange={(e) => setPopoverData(e.target.value)}
          /> */}
          <button
            type="submit"
            className="btn btn-dark mt-2 btn-sm"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Insert
          </button>
        </form>
      </Popover>
      {buttonsData.map((button, index) => {
    
        return (
          <GenerateMenuButton
            buttonData={button}
            boxStyle={{
              margin: "10px 10px 0 10px",
              marginBottom: isRow ? "0px" : "10px",
              width:'100%',
               
              padding: "10px",
            }}
            iconStyle={{
              width: "28px",
            }}
            isRow={isRow}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default GeneratePlaceHolderAndSilence;




