/* eslint-disable */
import { container } from "assets/jss/material-kit-pro-react.js";  
import bg from "./bg.png";


const TemplateStyles = () => ({
    main: {
        background: `url(${bg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: "100%",
        paddingRight: "80px",
        display: "flex"
    },
    container: {
        ...container,
        borderRadius: "8px",
    },
    tableContent: {
        backgroundColor: "#FFF7F2",
        color: "#000",
        display: "flex",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        width: "100%"
        // height: "70vh",
    },
    tableHeader: {
        backgroundColor: "#E58200",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "21px",
        color: "#FFFFFF",
        paddingLeft: "2vw",
        paddingTop: "2vh",
        paddingBottom: "2vh"
    },
    table: {
        backgroundColor: "#FFF",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "25px",
        paddingLeft: "25px",
        color: "#231B35",
        fontFamily: "Rubik', sans-serif",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "162%"
    },
    Buttons: {
        color: "#C24500",
        marginTop: "2vh"
    },
    tableSpace: {
        height: "7vh"
    },
    error: {
        color: "red",
        alignItems: "center",
        margin: "0px auto",
        justifyContent: "center",
        marginLeft: "35vw",
        marginTop: "1vh"
    }
});

export default TemplateStyles;