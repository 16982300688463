/* eslint-disable */
import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import share from "./share.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WhatsAppModel from "./WhatsAppModel/WhatsAppModel";

class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let pttrs = process.env.REACT_APP_VIDEO_URL_PTTRS? process.env.REACT_APP_VIDEO_URL_PTTRS.split(','):[];
    let match_idx = null, new_link = null;
    for(let i=0;i<pttrs.length; i++){
      let out = this.props.downloadLink.match('^'+pttrs[i].replace('{}', '(.*)')+'$');
      if(out){
        match_idx = i;
        new_link = out[1];
      }
    }
    var encodedURL= btoa(new_link?(match_idx+"|"+new_link):this.props.downloadLink);
    let shareUrl = window.origin +"/app/share/" + encodedURL;
    let ClipBoardTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 'max-content',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }));


    const buttonStyle = {
      background: '#2196F3',
      color: '#ffffff',
      border: 'none',
      padding: '7px 15px',
      borderRadius: '12px',
      fontSize: '14px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginRight:"10px"
    };
  
    const iconStyle = {
      marginLeft: '10px',
    };

    return (
      <>
          <ClipBoardTooltip title={
            <div>
              {/* <input type="text" value={shareUrl} onClick={(e)=>{e.target.focus()}} /> */}
              {/* <WhatsAppIcon
                  style={{
                        color:"green",
                        fontWeight:"bolder",
                        cursor:"pointer",
                        zIndex:1000
                  }}
                  onClick={()=>this.props.updateShareButtonRequestId(this.props.shareButtonRequestId)}
              /> */}
              <IconButton onClick={()=>{navigator.clipboard.writeText(shareUrl)}}>
                 <ContentCopyIcon className="text-dark"/>
              </IconButton>
              <div>
                 <input size={50} value={shareUrl} onClick={(e)=>{e.target.select(); e.preventDefault();}} readOnly/>
              </div>
            </div>
         } placement="top">
            <Button style={buttonStyle}>Share
              {/* email, wa, copy link */}
              <svg style={{marginLeft:"10px",fill:"white"}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"/></svg>
            </Button>
          </ClipBoardTooltip>
      </>
  );
}
}

export default ShareButton;