/* eslint-disable */
import React, { Component } from "react";
import SideBarButton from "./CustomNavBarButtons/SideBarButton";
import TopNavBarButton from "./CustomNavBarButtons/TopNavBarButton";
import MODELIMAGE from "assets/img/model_image.png";
class NavBarEditor extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      sideBarTopButtons = [],
      isSideBarOpen,
      updateSideBarOpen,
    } = this.props;



    return (
      <div>
        <div style={{ width: "100%", height: "95vh" }}>
          <div style={{width: "100%" , backgroundColor: "#3E3A3A", padding: "10px",height:'100%' }}>
            <div class="flex-row">
              <div style={{ width: "100%"}} className="d-flex flex-row">
                {sideBarTopButtons.map((buttonData, index) => (
                  <SideBarButton
                    id={index}
                    key={index}
                    onClickButton={buttonData.onClick || updateSideBarOpen}
                    buttonData={buttonData}
                  />
                ))}
              </div>
            </div>

            <div class="flex-row" style={{width:"100%", height:'100%', background: "#3E3A3A" }}>
              {sideBarTopButtons.map((buttonData, index) => {
                if (index === isSideBarOpen && buttonData.componentToRender)
                  return (
                    <buttonData.componentToRender
                      key={index}
                      closeExpandedDiv={() => updateSideBarOpen(null)}
                      {...buttonData.props}
                    />
                  );
                return <div key={index}></div>;
              })}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default NavBarEditor;
