/* eslint-disable */
import React, { useState } from "react";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { logout } from "common/Authentication";
import EditIcon from "assets/img/iconsSidebar/EditIconPencil.svg";
import CustomInput from "components/CustomInput/CustomInput";

function EditorHeader(props) {
  const {title, setTitle} = props
  const [titleEdit, setTitleEdit] = useState(false);
  return (
    <div
        class="navbars-top"
        style={{
            width: "100%",
            display: "flex", flexDirection: "row" ,
            backgroundImage: "linear-gradient(to right, #3C02D5 0%, #84018D 100%)",
        }}
    >
      <div className="py-2 px-0"  style={{ }}>
        <Link to="/app/audio">
          <Typography
            sx={{ fontSize: 16 }}
            style={{
              padding: "5px 20px",
              color: "white",
              width: "fit-content",
            }}
          >
            Home
          </Typography>
        </Link>
      </div>

      <div
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {titleEdit ? (
          <CustomInput
            inputProps={{
              defaultValue: title,
              onBlur: (t) => {
                t.target.value && setTitle(t.target.value);
                setTitleEdit(false);
              },
              autoFocus: true,
            }}
            white={true}
            formControlProps={{
              className: "m-0 p-0",
            }}
          />
        ) : (
          <div
            className="d-flex align-items-center"
            onClick={() => {setTitleEdit(true)}}
          >
            <h3 className="m-0 text-white" style={{ whiteSpace: "nowrap" }}>
              {title}
            </h3>
            <img
              src={EditIcon}
              className="ml-2"
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
        {titleEdit ? (
          <Button
            variant="contained"
            onClick={()=>{setTitleEdit(true)}}
            style={{
              backgroundColor: "#00000080",
              marginLeft: "10px",
              textTransform: "capitalize",
            }}
          >
            Save
          </Button>
        ) : (
          <div></div>
        )}
      </div>

      <div style={{display: "flex", justifyItems:"right", alignItems: "center",justifyContent:"right"}}>
            <Button
                onClick={() => {
                    logout();
                    window.location.href = "/app";
                }}
                style={{
                    background: '#b4b4b4',
                    color: '#3902D8',
                    border: 'none',
                    padding: '7px 15px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight:"10px",
                    }}
            >
                Logout
                <PowerSettingsNewIcon style={{marginLeft:"4px"}}/> 
            </Button>
        </div>
    </div>
  );
}
export default EditorHeader;
