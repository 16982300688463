/*eslint-disable*/
import CloseSidebarIcon from "./CloseSidebarIcon";
import FilterSelector from "../../CommonSections/FilterSelector"
import NoData  from "container/CommonSections/NoDataContainer";

const VerticalAvatar = (props) => {
  const { avatarTemplates, onAvatarChange, categories, setAvatarEnabled, avatarEnabled, avatarProperties,updateAvatarProperties } = props;
  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ width: "100%",height:'85%' }}
    >
      <div
        style={{
          width: "92%",
          backgroundColor: "#e4e4e4",
          borderRadius: "5px",
          padding: "10px",
          cursor:"pointer"
        }}
        onClick={()=>setAvatarEnabled(!avatarEnabled)}
      >
        <p className="text-center" style={{fontWeight:'500',color:'grey'}}>
          {avatarEnabled? "Disable avatar for this slide" : "Enable avatar for this slide"}
        </p>
      </div>
      <div
        className=" px-0"
        style={{
          width: "92%",
          margin: "8px 5px 50px 0px",
          overflowY: "auto",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {avatarTemplates.length ? avatarTemplates.map((avatar, index) => (
          <div className="d-flex flex-row align-items-center" key={index} style={{marginTop:'20px',width:'100%',borderRadius: "10px",backgroundColor:'grey' ,padding: '10px'}}>
            <img
              src={avatar.thumbnail}
              alt={avatar.name}
              height={60}
              style={{ borderRadius: "30px",cursor:"pointer" }}
              onClick={() => onAvatarChange(avatar.id)}
            />
            <p
              style={{
                marginLeft: '10px',
                fontFamily: "Rubik",
                fontStyle: "italic",
                fontWeight: "normal",
                fontSize: "15px",
                lineHeight: "24px",
                textAlign: "center",
                color: "white",
              }}
            >
              {avatar.name}
            </p>
          </div>
        )) : <NoData />}
      </div>
    </div>
  );
};

export default VerticalAvatar;
