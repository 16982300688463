/* eslint-disable */
import React from "react";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { logout } from "common/Authentication";

function Header() {
  return (
    <div
        class="navbars-top"
        style={{
            width: "100%",
            display: "flex", flexDirection: "row" ,
            backgroundImage: "linear-gradient(to right, #3C02D5 0%, #84018D 100%)",
        }}
    >
      <div className="col-md-12 py-2 px-0"  style={{ }}>
        <Link to="/app/audioeditor">
          <Card
            sx={{ maxWidth: "13vw" }}
            style={{
              marginRight: "10px",
              backgroundColor: "white",
              padding: "5px 10px",
              borderRadius: "8px",
              color: "#3902D8",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              display: "flex",
              marginLeft: "10px",
            }}
          >
            <Typography
              sx={{ fontSize: 24, alignItems: "center" }}
              color="#3902D8"
            >
              +
            </Typography>
            <Typography sx={{ fontSize: 16 }} color="#3902D8">
              <i>Create New</i>
            </Typography>
          </Card>
        </Link>
      </div>
      <div style={{width:"100%",display: "flex", justifyItems:"right", alignItems: "center",justifyContent:"right"}}>
            <Button
                onClick={() => {
                    logout();
                    window.location.href = "/app";
                }}
                style={{
                    background: '#b4b4b4',
                    color: '#3902D8',
                    border: 'none',
                    padding: '7px 15px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginRight:"10px",
                    }}
            >
                Logout
                <PowerSettingsNewIcon style={{marginLeft:"4px"}}/> 
            </Button>
        </div>
    </div>
  );
}
export default Header;
