import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Assignment from "@material-ui/icons/Assignment";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import switchstyles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import {getToken} from "common/Authentication";

const useStyles = makeStyles(style);
const switchStyles = makeStyles(switchstyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function AdminAdduserSection() {
  const classes = useStyles();
  const switchClasses = switchStyles();
  const [addUserModal, setAddUserModal] = React.useState(false);
  const [admin, setAdmin] = React.useState("");
  const [active, setActive] = React.useState("");
  const [addUserData, setAddUserData] =  React.useState({});

  const submitForm = (data) => {
    data.admin = (admin == 1) ? true : false;
    data.is_active = (active == 1) ? true : false;

    let jsonRequest = JSON.stringify({email: data.email, password: data.password, admin: data.admin, is_active: data.is_active})
    console.log(jsonRequest);
    let URL = "/admin/add_user";
    let token = getToken();
    fetch(URL, {method:'POST', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest}).then(res=>{
      if(res.status === 201){
          return res.json();
      } else {
        throw "temp";
      }
    })
    .then((res) => {
        console.log(res);       
        window.location.reload(false)    
    }).catch(e=>{
        console.log(e);
    })
  }

  return (
    <div>
      <Button color="info" onClick={() => setAddUserModal(true)}>
        Add User <Assignment />
      </Button>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalLogin
        }}
        open={addUserModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddUserModal(false)}
        aria-labelledby="adduser-modal-slide-title"
        aria-describedby="adduser-modal-slide-description"
      >
        <Card plain className={classes.modalLoginCard}>
          <DialogTitle
            id="adduser-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <CardHeader
              plain
              color="primary"
              className={`${classes.textCenter} ${classes.cardLoginHeader}`}
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => setAddUserModal(false)}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h5 className={classes.cardTitleWhite}>Add user</h5>
            </CardHeader>
          </DialogTitle>
          <DialogContent
            id="adduser-modal-slide-description"
            className={classes.modalBody}
          >
            <form>
              <p className={`${classes.description} ${classes.textCenter}`}>
                Add a new user
              </p>
              <CardBody className={classes.cardLoginBody}>
              <CustomInput
                  id="adduser-modal-email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Email...",
                    type: "email",
                    onChange: (e)=>{addUserData.email=e.target.value}
                  }}
                />
                <CustomInput
                  id="adduser-modal-pass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    placeholder: "Password...",
                    type: "password",
                    onChange: (e)=>{addUserData.password=e.target.value}
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={admin}
                            onChange={event => {
                                setAdmin(event.target.checked);
                                console.log(event.target.checked);
                            }}
                            value="admin"
                            classes={{
                              switchBase: switchClasses.switchBase,
                              checked: switchClasses.switchChecked,
                              thumb: switchClasses.switchIcon,
                              track: switchClasses.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: switchClasses.label
                        }}
                        label="admin"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={active}
                            onChange={event => {
                                setActive(event.target.checked);
                                console.log(event.target.checked);
                            }}
                            value="active"
                            classes={{
                              switchBase: switchClasses.switchBase,
                              checked: switchClasses.switchChecked,
                              thumb: switchClasses.switchIcon,
                              track: switchClasses.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: switchClasses.label
                        }}
                        label="active"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </form>
          </DialogContent>
          <DialogActions
            className={`${classes.modalFooter} ${classes.justifyContentCenter}`}
          >
            <Button 
              color="primary" 
              simple size="lg"
              onClick={(e)=>{e.preventDefault();submitForm(addUserData);}}
            >
              Submit
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
    </div>
  );
}