/* eslint-disable */
import React, { Component } from "react";
import SideBarButton from "./CustomNavBarButtons/SideBarButton";

class NavBarVertical extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      sideBarTopButtons = [],
      sideBarBottomButtons = [],
      isSideBarOpen,
      updateSideBarOpen,
    } = this.props;

    return (
      <div className="d-flex">
        <div className="d-flex flex-column justify-content-around">
          <div className="d-flex flex-column">
            {sideBarTopButtons.map((buttonData, index) => (
           
              <SideBarButton
              
                id={index}
                key={index}
                onClickButton={buttonData.onClick || updateSideBarOpen}
                buttonData={buttonData}
              />
            ))}
          </div>
          <div className="d-flex flex-column-reverse">
            {sideBarBottomButtons.map((buttonData, index) => (
              <SideBarButton
                id={index + sideBarTopButtons.length}
                key={index}
                onClickButton={buttonData.onClick || updateSideBarOpen}
                buttonData={buttonData}
              />
            ))}
          </div>
        </div>
        <div style={{ background: "#E8E8E8" }}>
          {sideBarTopButtons.map((buttonData, index) => {
            if (index === isSideBarOpen && buttonData.componentToRender)
              return (
                <buttonData.componentToRender
                  key={index}
                  closeExpandedDiv={() => updateSideBarOpen(null)}
                  {...buttonData.props}
                />
              );
            return <div key={index}></div>;
          })}
          {sideBarBottomButtons.map((buttonData, index) => {
            if (
              index + sideBarTopButtons.length === isSideBarOpen &&
              buttonData.componentToRender
            )
              return (
                <buttonData.componentToRender
                  key={index}
                  closeExpandedDiv={() => updateSideBarOpen(null)}
                  {...buttonData.props}
                />
              );
            return <div key={index}></div>;
          })}
        </div>
      </div>
    );
  }
}

export default NavBarVertical;
