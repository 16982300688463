import React,{useEffect,useState,useRef} from 'react'
import { Modal,ModalBody,ModalHeader } from "reactstrap";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';


function Preview(props) {

  const video = useRef(null);
  const audio = useRef(null);
  let curr = 0;

  //Control for pause
  let pause = ()=>{
    if(video.current !== null){
      video.current.pause();
    }
    audio.current.pause();
  }

  //Control for play
  let play = ()=>{
    if(video.current !== null){
      video.current.play();
    }
    audio.current.play();
  }

  //Control for seek
  let seek = (event)=>{
    if (!curr || new Date().getTime() - curr > 20) {
      audio.current.currentTime = event.target.currentTime;
      if(video.current !== null){
        video.current.currentTime = audio.current.currentTime;
      }
      curr = new Date().getTime();
    }
  }
  

  let end = ()=>{
    audio.current.currentTime = 0;
    if(video.current !== null){
      video.current.currentTime = 0;
    }
    pause();
  }

  // Decide preview background based on user input
  let preview_background = (background)=>{
    if(background.preview_bg_color != undefined){
      return(
        <div style={{width: "100%", height: "85%", backgroundColor: background.preview_bg_color}}></div>
      )
    }
    if(background.preview_bg_thumbnail_url === background.preview_bg_url){
      return(
        <div style={{width: "100%", height: "85%"}}>
          <img src={background.preview_bg_url} width="100%" height="100%"></img>
        </div>
      )
    }
    return(
      <video ref={video} id="preview-video" width="100%" height="85%" poster={background.preview_bg_thumbnail_url} muted onEnded={end}>
        <source src={background.preview_bg_url} type="video/mp4"></source>
      </video> 
    )
  }

  return (
    <Modal isOpen={props.open}
          style={{width:"100vw",height:"500px"}}
          centered>
        <ModalHeader className='m-3'>
          <Button 
              style={{position:"absolute",right:"10px",top:"12px"}}
              onClick={props.toggle}
          >
          <CloseIcon
              style={{textAlign:"center",color:"#E7901F"}}
          />
          </Button>
        </ModalHeader>
        <ModalBody style={{height:"350px",padding: "0.5rem"}}>
            {preview_background(props.background)}
            <audio ref={audio} id="preview-audio2" style={{width: "100%",height: "14%"}} controls controlsList="noplaybackrate nodownload" onPlay={play} onPause={pause} onSeeked={seek} onEnded={end}>
              <source src={props.audio_url} type="audio/mp3"></source>
            </audio>
        </ModalBody>
  </Modal>
  )
}

export default Preview