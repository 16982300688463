import { useState } from "react";

import Dropzone2 from "./Dropzone2";
// import Modal from '@material-ui/core/Modal';
import Modal from "@mui/material/Modal";
import { Delete, Add } from "@material-ui/icons";

// import GenerateSlide from "../CustomNavBarButtons/GenerateSlide";
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem";
import axios from "axios";
import { getToken } from "common/Authentication";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import Dropzone from "../MainEditorComponents/Dropzone";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GenerateSlide from "../CustomNavBarButtons/GenerateSlide";
import CloseSidebarIcon from "../CustomNavBarButtons/CloseSidebarIcon";
import { Button } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";

const EditorPanel = (props) => {
  const { readFile } = props;
  const [uploadStatus, setUploadStatus] = useState(null);
  const { slidesList } = props;
  const [upload_modal, setUploadModal] = useState(false);
  const [alert_modal, setAlertModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const deleteSlide = (slideNumber) => {
    props.deleteSlideWithId(slideNumber);
  };

  const changeCurrentSlide = (slideNumber) => {
    props.changeCurrentSlide(slideNumber);
  };

  const addSlide = (slideNumber) => {
    props.addNewSlide(slideNumber);
  };

  const toggleUploadModal = () => {
    setUploadModal(!upload_modal);
  };

  const toggleAlertModal = () => {
    setAlertModal(!alert_modal);
  };

  const initiateSlidesFromPpt = (slides) => {
    props.initiateSlidesFromPpt(slides);
  };

  const uploadPpt = (file) => {
    setUploadStatus("pending");
    setUploadFile(file);
    let data = new FormData();
    data.append("file", file);
    data.append("fetch_thumbnail", false);
    axios
      .post("/util/resource", data, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          getSlidesFromPpt(res.data.data.url);
        } else {
          throw "temp";
        }
      })
      .catch(() => {
        setUploadStatus("fail");
      });
  };

  const getSlidesFromPpt = (input_file) => {
    axios
      .post(
        "/util/toslide",
        {
          input_file: input_file,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((res) => {
        setUploadStatus("success");
        initiateSlidesFromPpt(res.data.data);
      })
      .catch(() => {
        setUploadStatus("fail");
      });
  };

  const handleModalClose = () => setModalOpen(false);

  const [openModal, setModalOpen] = useState(false);

  return (
    <>
     <Modal
          onClose={toggleUploadModal}
          open={upload_modal}
          style={{
              position: "absolute",
              color: "black",
              height: "85vh",
              width: "80vh",
              backgroundColor:'blue',
              margin: "auto",
          }}
      >
          <div style={{ width: "100%", height: "100%" }}>
              <Dropzone
                  containerStyle={{ height: "100%" }}
                  onFilesAdded={uploadPpt}
                  status={uploadStatus}
                  file={uploadFile}
              />
          </div>
      </Modal>
      <Modal
        open={alert_modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "10px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            background: "white",
            border: "2px solid #000",
            boxShadow: 24,
            padding: "20px",
          }}
          className="d-flex flex-column align-items-center"
        >
          <p className="h3 fw-bold">
            Are you sure?
          </p>
          <p className="">Uploading slides will clear all current slides</p>
          <GridContainer className="my-1">
            <GridItem md={6}>
              <button
              style={{ backgroundColor: "#f2f2f2",
              color: "grey",
              borderRadius: "7px",}}
                className="btn"
                onClick={()=>{
                  toggleAlertModal();
                  toggleUploadModal();
                }}
              >
                Confirm
              </button>
            </GridItem>
            <GridItem md={6}>
              <button
               style={{ backgroundColor: "#d9d9d9",
               color: "grey",
               borderRadius: "7px",}}
                className="btn"
                onClick={toggleAlertModal}
              >
                Cancel
              </button>
            </GridItem>
          </GridContainer>
        </div>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            height: 600,
            p: 3,
          }}
        >
          <div className="d-flex justify-content-end mb-2">
            <IconButton onClick={handleModalClose} style={{ outline: "none" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Dropzone2 onFilesAdded={readFile} containerStyle={{ margin: "0" }} />
        </Box>
      </Modal>
      <div className="container  panel-entity-container d-flex flex-row ">
        <div className="d-flex flex-column" style={{ flexDirection: "row" }}>
          <div
            style={{
              backgroundColor: "#D7D7D7",
              borderRadius: "10px",
              width: "180px",
              padding: "10px",
              marginTop: "15px",
              textAlign: "center",
              cursor: "pointer",
              color: "grey",
              padding: "12px 0px",
              backgroundColor: "#d9d9d9",
            }}
            onClick={toggleAlertModal}
          >
            <p  style={{
             fontWeight:'500'
            }} >Import Slides</p>
          </div>
          <p
            style={{
              width: "180px",
              marginTop: "5px",
              position: "centre",
              textAlign: "center",
              alignItems: "center",
              marginBottom:'0px',
              color:'#797979'
            }}
          > Or</p>
          <div
           
            style={{
              visibility: "visible",
              backgroundColor: "#d9d9d9",
              borderRadius: "7px",
              textAlign: "center",
              position: "centre",
              alignItems: "center",
              display: "flex",
              width: "180px",
              marginTop:'3px',

              justifyContent: "center",
             
              cursor: "pointer",
            }}
            onClick={(e) => {
              // Add slide before current slide
              addSlide(slidesList.length - 1);
              e.stopPropagation();
            }}
          >
            <Button
             
              style={{
                width: "fit-content",
                color: "grey",
                padding: "2px 0px",
                outline: "0px",
                width: "180px",
                margin:'0px',
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Add
                style={{
                  visibility: "visible",
                  width: "80px",
                  height: "35px",
                  textAlign: "center",
                  position: "centre",
                  borderRadius: "7px",
                }}
              />
            </Button>
          </div>
        </div>

        <div
          className="container  panel-entity-container d-flex flex-row "
          style={{ height: "72vh", width: "100%" }}
        >
          {slidesList.map((slide, index) => {
            let url = props.idToBackUrlMapping[slide.bg_id];
            if (slide.custom_bg_thumbnail_url) {
              url = slide.custom_bg_thumbnail_url;
            }

            return (
              <GenerateSlide
                slideData={slide}
                backUrl={url}
                backColor={slide.bg_color}
                key={index}
                slideNumber={index + 1}
                deleteSlide={deleteSlide}
                isCurrent={props.currentSlide + 1}
                changeCurrentSlide={changeCurrentSlide}
                addSlide={addSlide}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EditorPanel;
