/* eslint-disable */
import React from "react";
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import Editor from "container/Editor/Editor";
import Report from "container/Report/Report";
import Landing from "container/Landing/Landing";
import AudioLanding from "container/AudioGeneration/audioLanding";
import AudioEditor from "container/AudioGeneration/audioEditor";
import ShareVideoPage from "container/ShareVideo/ShareVideoPage";
import NotFound from "container/NotFound/NotFound";
import { checkUser } from "common/Authentication";
import Template from "container/Template/Template";

import LoginPage from 'container/LoginPage/LoginPage3';
import Settings from 'container/Settings/Settings';
import ProfilePage from 'container/ProfilePage/Main'

// pages for this product
import { useState, useEffect } from "react";
import "./style.css";
var hist = createBrowserHistory();
require('dotenv').config()

function App() {
  const [loggedin, setLoggedin] = useState(null);
  useEffect(() => {
    checkUser().then((res) => {
      if(res == null) {
        setLoggedin(false);
      }else {
        setLoggedin(true);
      }
    });
  }, [loggedin]);
  console.log("loggedin:", loggedin);
  console.log();
  return (
    <>
      {loggedin ?
        <Router history={hist}>
          <Switch>
            <Route path="/app/report" component={Report} exact/>
            <Route path="/app/template/:templateId" component={Template} exact/>
            <Route path="/app/editor" component={Editor} exact/>
            <Route path="/app/editor/:requestId" component={Editor} exact/>
            <Route path="/app/share/:videoUrl" component={ShareVideoPage}/>
            <Route path="/app/settings" exact component={Settings}/>
            <Route path="/app/profile" exact component={ProfilePage}/>
            <Route path="/app/profile/:section" exact component={ProfilePage}/>
            <Route path="/app/audioeditor" component={AudioEditor}/>
            <Route path="/app/audio" component={AudioLanding}/>
            <Route path="/app" component={Landing}/>
            <Route component={NotFound} />
          </Switch>
        </Router>
        :
        <Router  history={hist}>
          <Switch>
            <Route path="/app/share/:videoUrl" component={ShareVideoPage}/>
            <Route path="/app">
              <LoginPage setLoggedin={setLoggedin}/>
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Router>
      }
    </>
  );
}

export default App;
