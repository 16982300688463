import React, {Component} from 'react';
import { GoogleLogin } from 'react-google-login';
import { setToken } from "common/Authentication";
import googlIcon from "./google.png";

const clientId = "1279596087-5pqoivjmie3ohp0o8m2apc3ssh0nufo9.apps.googleusercontent.com";

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      isbuttonClicked : false
    }
  }

  onLoginSuccess = (res) => {
    console.log('Login Success:', res);

    if (res.tokenId !== undefined) {
      let request = { oauth_token: res.accessToken };
      fetch('/auth/login/', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(request) }).then(r => {
        if (r.status === 200) {
          return r.json();
        } else {
          throw r;
        }
      }
      ).then(r => {
        console.log(r);
        setToken(r.data.sso_token);
        this.props.setLoggedin(true);
      }).catch(e => {
        console.log(e);
        this.onLoginFailure();
      });
    } else {
      console.log(res);
    }
  };

  onLoginFailure = (res) => {
    console.log('Login Failed:', res);
    if(this.state.isbuttonClicked)
      this.props.openSnackBar();
  };
  render() {
    return (
      <div onClick={() => {
        this.setState({isbuttonClicked: true})
      }}>
        <GoogleLogin
          clientId={clientId}
          onSuccess={this.onLoginSuccess}
          onFailure={this.onLoginFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={false}
          render = {(props) => {
            return (
              <img src={googlIcon} onClick={props.onClick} style={{"cursor": 'pointer'}} alt="google-icon"/>
            )
          }}
        />
      </div>
    );
  }
}
export default Login;