import React from 'react';
import "./BillingInfo.css";
import axios from 'axios';
import {Input} from 'reactstrap';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { getToken } from "common/Authentication";
import * as moment from 'moment';
import VideoCreatorLogo from 'container/CommonSections/VideoCreatorLogoContainer';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function BillingInfo(props) {
  //snackbar
  const [snackOpen , setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");

  const [creditsAvailable, setCreditsAvailable] = React.useState(0);
  const [creditCount, setCreditCount] = React.useState();
  const [newBillInfo, setNewBillInfo] = React.useState({"before_tax_amount": "-",  "tax_amount": "-",  "total_amount": "-"});
  const [orderList, setOrderList] = React.useState([]);
  const [paymentProcessing, setPaymentProcessing] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const userInfo = props.user;
  console.log(userInfo);

  const timeouts = [20,30,40,60,70,100,140,200,300,490,730,1090,1630];
  
  //refreshing
  const refreshList = () => {
    setRefresh(!refresh);
  }

  // calculating bill
  React.useEffect(() => {
    if(creditCount === "")
    {
      setNewBillInfo({"before_tax_amount": "-",  "tax_amount": "-",  "total_amount": "-"})
      return;
    }
    else
    {
      axios({
        method: 'GET',
        url: '/billing/order/bill-calculate',
        headers: {
            Authorization:'Bearer ' + getToken(),
        },
        params: {
            credit_count: creditCount
        }
        }).then((res)=>{
            if(res.status===200){
              // console.log(res.data.data)
              setNewBillInfo(res.data.data);
            }
      })
    }
  }, [creditCount])

  //calculating the credits available
  React.useEffect(() => {
    axios({
      method: 'GET',
      url: '/billing/credits/fetch',
      headers: {
        Authorization:'Bearer ' + getToken(), 
      }
      }).then((res) => {
        if(res.status === 200) {
          // console.log(res.data.data);
          setCreditsAvailable(res.data.data.available_credits);
        }
      })
  }, [refresh])

  //getting user order list
  React.useEffect(() => {
    axios({
      method: 'GET',
      url: '/billing/order/list',
      headers: {
          Authorization:'Bearer ' + getToken(),
      },
      }).then((res)=>{
          if(res.status === 200)
          {
            // console.log(res.data.data)
            setOrderList(res.data.data);
          }  
      })
  }, [refresh])

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  let orderId = "";
  const addCredits = () => {
    if(newBillInfo.total_amount === "-" || newBillInfo.total_amount === 0)
    {
      setSnackMessage("Please check the credits");
      setSnackSeverity("error");
      setSnackOpen(true);
      return;
    }
    else
    {
      axios({
        method: 'POST',
        url: '/billing/order/create',
        headers: {
            Authorization:'Bearer ' + getToken(),
            RequestTime: new Date()
        },
        data: {
            credit_count: parseInt(creditCount)
        }
      }).then((res)=>{
        if(res.status===200){
            // console.log(res);
            // console.log(res.data.data.order_id);
            orderId = res.data.data.order_id;
            window.open(
              res.data.data.other_data.redirect_url,
              '_blank'
            );
            setPaymentProcessing(true);
      
            checkPaymentStatus(0);
          }
      })
    }
  }

  const checkPaymentStatus = (retry_id) =>
  {
    // console.log("from status query")
    // console.log(orderId);
    axios({
      method: 'GET',
      url: '/billing/order/status',
      headers: {
        Authorization:'Bearer ' + getToken(),
        RequestTime: new Date()   
      },
      params: {
        order_id : orderId
      }
    }).then(res=>{
        if(res)
        {
          // console.log(res);
          if(res.data.data.order_status==='pending'){
              throw "temp";
          }
          else{
            // console.log("refreshing")
            refreshList();
          }
        }
        setCreditCount("");
        setPaymentProcessing(false);
        setSnackMessage("credits has been added");
        setSnackSeverity("success");
        setSnackOpen(true);

      }).catch(e =>{
        if(retry_id < timeouts.length){
          setTimeout(() => checkPaymentStatus(retry_id+1), timeouts[retry_id]*1000);
        } else {
          setPaymentProcessing(false);
          setSnackMessage("Could not process the request");
          setSnackSeverity("error");
          setSnackOpen(true);
        }
    })
  }

  return (
      <>
        <VideoCreatorLogo />
        <div className='billingInfo'>
          <div className="billing_info_cards">
            <div className="billing_info_cards_block credits_Available">
                <h2 style={{fontWeight: "500",fontSize: "64px"}}>{creditsAvailable}</h2>
                <h3>Available Credits</h3>
            </div>
            <div className="billing_info_cards_block bill_info" style={(userInfo.admin)?{}:{display:"none"}}>
              { !paymentProcessing && 
              <div>
              <div className='bill_info_row'>
                <div className='billing_info_card_number'>
                  <Input 
                    type="number" 
                    name="credits" 
                    id="credits" 
                    min="0" 
                    placeholder="enter credits" 
                    value={creditCount}
                    onChange={ (e)=>{
                      setCreditCount(e.target.value)
                    }}
                    style={{width: "150px"}}
                  />
                  

                </div>
                <p style={{"fontSize": "x-large"}}>Bill</p>
              </div>
              <div className='bill_info_row'>
                <p>Total Credits</p>
                <p>{creditCount}</p>
              </div>
              <div className='bill_info_row'>
                <p>Before Tax Amount</p>
                <p>{newBillInfo.before_tax_amount}</p>
              </div>
              <div className='bill_info_row'>
                <p>Tax Amount</p>
                <p>{newBillInfo.tax_amount}</p>
              </div>
              <div className='bill_info_row'>
                <p>Total(INR)</p>
                <p>{newBillInfo.total_amount}</p>
              </div>
            
              <div className='change_button' onClick={(e) => {addCredits()}} style={{maxWidth: "130px"}}>
                Add Credits
              </div>
              </div>
              } 
              {  paymentProcessing && 
                <div style={{height:"100%"}}>
                  <Box 
                  style={{ 
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%" ,
                    flexDirection: "column"
                  }}>
                    <CircularProgress />
                    <p>Waiting for confirmation</p>
                  </Box>
                </div>
              }
            </div>
          </div>
          
          <div className='order_info_table' style={(userInfo.admin)?{}:{display:"none"}}>
            <div className='order_info_table_header'>
              <div className='order_info_column' style={{width: "10%"}}>Order No</div>
              <div className='order_info_column'>created time</div>
              <div className='order_info_column'>Amount</div>
              <div className='order_info_column'>credit count</div>
              <div className='order_info_column'>Status</div>
            </div>
            <div className='order_info_table_body'>
            {
              orderList.map((orderInfo, index) =>(
                <div key={orderInfo.create_time} className='order_info_table_row'>
                  <div className='order_info_column'  style={{width: "10%"}}>{index+1}</div>
                  <div className='order_info_column'>{moment(orderInfo.create_time).format("MMM Do YY, h:mm:ss a")}</div>
                  <div className='order_info_column'>{orderInfo.amount}</div>
                  <div className='order_info_column'>{orderInfo.credit_count}</div>
                  <div className='order_info_column'
                    style={orderInfo.order_status === "success" ? {color: "#499037"} :
                          (orderInfo.order_status === "pending" ? {color: "#A177FF"} : {color : "red"})}
                  >
                    {orderInfo.order_status === "success" ? "Success" :( orderInfo.order_status === "pending" ? "Pending" : "Failed")}
                  </div>
                </div>
              ))
            }
            </div>
          </div>

        </div>


        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
            {snackMessage}
          </Alert>
        </Snackbar>

      </>
  );
}

export default BillingInfo;
