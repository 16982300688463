import React from 'react'
import  { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import AdminAdduserSection from './AdminAdduserSection';
import Slide from "@material-ui/core/Slide";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import switchstyles from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { getToken } from 'common/Authentication';

const useStyles = makeStyles(style); // for table 
const modalStyles = makeStyles(styles); // for model
const switchStyles = makeStyles(switchstyles); // for switch

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

function AdminSettingsSection(props) {
    const classes = useStyles();
    const modelClasses = modalStyles();
    const switchClasses = switchStyles();
    const [users, setUsers] = useState([]);
    const [admin, setAdmin] = React.useState("");
    const [active, setActive] = React.useState("");
    const [editEmail, setEditEmail] = useState([]);
    const [editUid, setEditUid] = useState("");
    const [editUser, setEditUser] = useState([]);
    const [editModal, setEditModal] = React.useState(false);
    let token = getToken();

    let adminDetails = props.adminDetails;
    // console.log(adminDetails);

    const edit = (data) => {
        setEditEmail(data[0]);
        setEditUid(data[2]);
        data[1] == 'true' ? setAdmin(true) : setAdmin(false);  
        data[3] == 'true' ? setActive(true) : setActive(false);  
    }

    const deleteUser = (data) => {
        if (window.confirm('Are you sure to delete the user '+data[0])) {
            // Delete it!
            let jsonRequest = JSON.stringify({uid: data[2]})
            console.log(jsonRequest);
            let URL = "/admin/delete_user/";
            fetch(URL, {method:'DELETE', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest}).then(res=>{
            if(res.status === 200){
                return res.json();
            } else {
                throw "temp";
            }
            })
            .then((res) => {
                console.log(res);  
                console.log(data[0] + 'User deleted');   
                window.location.reload(false)
            }).catch(e=>{
                console.log(e);
            })
          } 
          else {
            // Do nothing!
            console.log('Not being deleted');
          }
    }

    let editURL = "/admin/edit_user";
    const submitEdit = (data) => {
        let jsonRequest = JSON.stringify({uid: editUid ,password: data.password, admin: admin, is_active: active})
        console.log(jsonRequest); 
        fetch(editURL, {method:'POST', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest}).then(res=>{
            if(res.status === 201){
                return res.json();
            } else {
              throw "temp";
            }
          })
        .then((res) => {   
            console.log(res);       
            window.location.reload(false)  
        }).catch(e=>{
            console.log(e);
        })
    }

    //for fetching users info
    useEffect(() => {
        fetch("/admin/users", 
            {
                method:'GET', 
                headers: 
                    { 
                        Authorization: `Bearer ${token}`,
                        RequestTime:new Date()
                    }
            })
        .then(res => {
            if(res.status === 200){
                return res.json();
            }}
        )
        .then(data => {
            let res = data.data.users
            //console.log(res)
            let value = []
            for (var j = 0; j < res.length; j++){
                let arr = []
                arr.push(res[j].email_id);
                res[j].admin ? arr.push("true") : arr.push("false");
                arr.push(res[j].uid)
                res[j].is_active ? arr.push("true") : arr.push("false");

                const editButton = (
                    <Button justIcon size="sm" color="success" key={j} 
                        onClick={()=>{
                            edit(arr);
                            setEditModal(true);
                        }}
                    >
                        <Edit />
                    </Button> )

                const deleteButton = (
                    <Button justIcon size="sm" color="danger" key={j} 
                        onClick={()=>{
                            deleteUser(arr);
                        }}
                    >
                        <Delete />
                    </Button> )

                arr.push(editButton)
                arr.push(deleteButton)
                if(arr[2] != adminDetails["uid"])
                    value.push(arr)
            }
            //console.log(value);
            setUsers(value)
        })
    },[])
    
    return (
        <>
            <Table
                tableHead={[ "email", "admin","uid", "active"]}
                tableData={
                    users
                }
                customCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                ]}
                customHeadClassesForCells={[0, 4, 5]}
            />
            
            <Dialog
                classes={{
                root: modelClasses.modalRoot,
                paper: modelClasses.modal + " " + modelClasses.modalLogin
                }}
                open={editModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setEditModal(false);
                    setAdmin("");
                    setActive("");
                }}
                aria-labelledby="adduser-modal-slide-title"
                aria-describedby="adduser-modal-slide-description"
            >
                <Card plain className={modelClasses.modalLoginCard}>
                <DialogTitle
                    id="adduser-modal-slide-title"
                    disableTypography
                    className={modelClasses.modalHeader}
                >
                    <CardHeader
                    plain
                    color="primary"
                    className={`${modelClasses.textCenter} ${modelClasses.cardLoginHeader}`}
                    >
                    <Button
                        simple
                        className={modelClasses.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => {
                            setEditModal(false)
                            setAdmin("");
                            setActive("");
                        }}
                    >
                        {" "}
                        <Close className={modelClasses.modalClose} />
                    </Button>
                    <h5 className={modelClasses.cardTitleWhite}>Edit user</h5>
                    </CardHeader>
                </DialogTitle>
                <DialogContent
                    id="adduser-modal-slide-description"
                    className={modelClasses.modalBody}
                >
                    <form>
                    <p className={`${modelClasses.description} ${modelClasses.textCenter}`}>
                        Edit the user
                    </p>
                    <CardBody className={modelClasses.cardLoginBody}>
                        <CustomInput
                            id="adduser-modal-email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                placeholder: "Email...",
                                type: "email",
                                value : editEmail,
                                disabled: true
                            }}
                        />
                        <CustomInput
                            id="adduser-modal-pass"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                placeholder: "Set New Password...",
                                type: "password",
                                onChange: (e)=>{editUser.password=e.target.value}
                            }}
                        />
                        <GridContainer>
                        <GridItem xs={12} sm={6} md={5} lg={5}>
                            <div>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={admin}
                                    onChange={event => {
                                        setAdmin(event.target.checked);
                                        //console.log(event.target.checked);
                                    }}
                                    value="admin"
                                    classes={{
                                    switchBase: switchClasses.switchBase,
                                    checked: switchClasses.switchChecked,
                                    thumb: switchClasses.switchIcon,
                                    track: switchClasses.switchBar
                                    }}
                                />
                                }
                                classes={{
                                label: switchClasses.label
                                }}
                                label="admin"
                            />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={5} lg={5}>
                            <div>
                            <FormControlLabel
                                control={
                                <Switch
                                    checked={active}
                                    onChange={event => {
                                        setActive(event.target.checked);
                                        //console.log(event.target.checked);
                                    }}
                                    value="active"
                                    classes={{
                                    switchBase: switchClasses.switchBase,
                                    checked: switchClasses.switchChecked,
                                    thumb: switchClasses.switchIcon,
                                    track: switchClasses.switchBar
                                    }}
                                />
                                }
                                classes={{
                                label: switchClasses.label
                                }}
                                label="active"
                            />
                            </div>
                        </GridItem>
                        </GridContainer>
                    </CardBody>
                    </form>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.justifyContentCenter}`}
                >
                    <Button 
                    color="primary" 
                    simple size="lg"
                    onClick={(e)=>{e.preventDefault();submitEdit(editUser);}}
                    >
                    Submit
                    </Button>
                </DialogActions>
                </Card>
            </Dialog>
            <AdminAdduserSection />
        </>
    )
}

export default AdminSettingsSection
