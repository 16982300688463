/* eslint-disable */
/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import "assets/css/bootstrap.min.css";
import './index.css';
import './assets/css/App.css';
import './App.css';
import App from './App';
import axios from 'axios';
import "assets/scss/material-kit-pro-react.scss?v=1.10.0";
import 'semantic-ui-css/semantic.min.css';

// pages for this product
// import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
// import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
// import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
// import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
// import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
// import EcommercePage from "views/EcommercePage/EcommercePage.js";
// import LandingPage from "views/LandingPage/LandingPage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";
// import PresentationPage from "views/PresentationPage/PresentationPage.js";
// import PricingPage from "views/PricingPage/PricingPage.js";
// import ProfilePage from "views/ProfilePage/ProfilePage.js";
// import ProductPage from "views/ProductPage/ProductPage.js";
// import SectionsPage from "views/SectionsPage/SectionsPage.js";
// import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.js";
// import SignupPage from "views/SignupPage/SignupPage.js";
// import ErrorPage from "views/ErrorPage/ErrorPage.js";

// axios.defaults.baseURL = 'https://canvas.artifyce.ai';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
  console.log(request);
  // Edit request config
  return request;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  console.log(response);
  // Edit response config
  return response;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

ReactDOM.render(
  // <Router history={hist}>
  //   <Switch>
  //     <Route path="/about-us" component={AboutUsPage} />
  //     <Route path="/blog-post" component={BlogPostPage} />
  //     <Route path="/blog-posts" component={BlogPostsPage} />
  //     <Route path="/components" component={ComponentsPage} />
  //     <Route path="/contact-us" component={ContactUsPage} />
  //     <Route path="/ecommerce-page" component={EcommercePage} />
  //     <Route path="/landing-page" component={LandingPage} />
  //     <Route path="/login-page" component={LoginPage} />
  //     <Route path="/pricing" component={PricingPage} />
  //     <Route path="/profile-page" component={ProfilePage} />
  //     <Route path="/product-page" component={ProductPage} />
  //     <Route path="/sections" component={SectionsPage} />
  //     <Route path="/shopping-cart-page" component={ShoppingCartPage} />
  //     <Route path="/signup-page" component={SignupPage} />
  //     <Route path="/error-page" component={ErrorPage} />
  //     <Route path="/presentation" component={PresentationPage} />
  //   </Switch>
  // </Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ,
  document.getElementById("root")
);
