import EllipseIcon from "assets/img/iconsSidebar/Ellipse.png";
import EllipseColoredIcon from "assets/img/iconsSidebar/PlayIconColored.svg";
import PlayIcon from "assets/img/iconsSidebar/PlayIcon.svg";
import PauseIcon from "assets/img/iconsSidebar/voicePause.png";

const GenerateVoiceButton2 = (props) => {
  const {
    musicDetails: track,
    playing,
    setPlaying,
    audioId,
    changeAudioId,
  } = props;

  const color = audioId === track.id ? "#004f5b" : "grey";
  const filterForImage =
    audioId == track.id
      ? "invert(58%) sepia(80%) saturate(3405%) hue-rotate(9deg) brightness(98%) contrast(104%)"
      : "invert(29%) sepia(16%) saturate(7%) hue-rotate(314deg) brightness(85%) contrast(83%)";
  const backgroundGradient =
    playing === track.id
      ? "linear-gradient(90deg, #FFFFFF 1.14%, #E58200 100%)"
      : "transparent";

  return (
    <div
      className="d-flex align-items-center "
      style={{
        width: "100%",
        borderRadius: "8px",
        marginLeft:"20px",
        position: "relative",
        marginTop:'20px',
        background:'#f2f2f2',
        padding:"10px"
      }}
      onClick={() => {
        audioId === track.id ? changeAudioId(null) : changeAudioId(track.id);
      }}
    >
      <div>
        <img
          src={audioId === track.id ? EllipseColoredIcon :  PlayIcon}
          style={{ borderRadius: "8px", color: color }}
          width={40}
        />
      </div>
      
      
<div
  className="flex-grow-1"
  style={{
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "17px",
    color: color,
    marginTop:"0px",
    paddingLeft:"10px"
    
  }}
>
  {track.name} ({track.categories.gender})
</div>
<button style={{ marginRight:'10px',
background:"red",
  marginTop:"0px",
  border: "none",
          background: "none",
  borderRadius: "8px",
  background: "grey", display: "inline-block"
}}>{track.categories.language}

</button>
      
      <audio
        id={track.id}
        src={track.url || track.thumbnail}
        width="0"
        height="0"
        controls={false}
      />
      <button
        style={{
          border: "none",
          background: "none",
          marginRight: "10px",
          border: "1px solid grey", 
          padding: "5px",
           borderRadius: "8px",
           color:'grey'
        }}
        onClick={(event) => {
          event.stopPropagation();
          if (playing === track.id) {
            document.getElementById(track.id).pause();
            document.getElementById(track.id).currentTime = 0;
            setPlaying(null);
            return;
          }

          if (playing) {
            document.getElementById(playing).pause();
            document.getElementById(playing).currentTime = 0;
          }
          document.getElementById(track.id).play();
          setPlaying(track.id);
        }}
      >
       {playing === track.id ? "Pause" : "Play"}
      </button>
    
    </div>
  );
};

export default GenerateVoiceButton2;
