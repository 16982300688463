import CloseSidebarIcon from "./CloseSidebarIcon";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GenerateVoiceButton from "./GenerateVoiceButton";
import GenerateVoiceButton2 from "./GenerateVoiceButton2";
import { useState } from "react";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import Dropzone from "../MainEditorComponents/Dropzone";
import { getToken } from "common/Authentication";
import FilterSelector from "../../CommonSections/FilterSelector";
import NoData from "../../CommonSections/NoDataContainer";
import { makeStyles } from "@material-ui/core/styles";
import MicIcon from "@mui/icons-material/Mic";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import ThemeTabView from "../../CommonSections/ThemeTabView";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";


const useStyles = makeStyles({
    sliderStyle: {
        "&.MuiSlider-rail": {
            color: "#A8A8A8",
        },
        "&.MuiSlider-track": {
            color: "#E58200",
        },
        "&.MuiSlider-thumb": {
            color: "#E58200",
        },
        "&.MuiSlider-valueLabel":{
            background: "#E58200",
        },
    },
});

const VerticalMusic = (props) => {
    const {
        musicTemplates,
        voiceTemplates,
        voiceId,
        changeVoiceId,
        musicId,
        changeMusicId,
        categories,
        setCustomMusic,
        customMusic,
        voiceSpeed,
        changeVoiceSpeed,
        avatarProperties,
        updateAvatarProperties
    } = props;

    const [playing, setPlaying] = useState(null);
    const [custom_music_modal, setCustomMusicModal] = useState(false);
    const [musicUploadStatus, setMusicUploadStatus] = useState(null);
    const [musicUploadFile, setMusicUploadFile] = useState(null);
    const [tabValue, setTabValue] = useState("voice");

    const toggleModal = () => {
        setCustomMusicModal(!custom_music_modal);
    };

    const handleSliderValue = (event, newValue) => {
        changeVoiceSpeed(newValue)
    };

    const upload_music = (file) => {
        setMusicUploadStatus("pending");
        setMusicUploadFile(file);
        let data = new FormData();
        data.append("file", file);
        data.append("fetch_thumbnail", true);
        axios
            .post("/util/resource", data, {
                headers: {
                    Authorization: "Bearer " + getToken(),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setMusicUploadStatus("success");
                    setCustomMusic(file.name, res.data.data.url);
                } else {
                    throw "temp";
                }
            })
            .catch(() => {
                setMusicUploadStatus("fail");
            });
    };

    const tabs = [
        {
            value: "voice",
            label: "Voice",
            icon : <MicIcon/>
        },
        {
            value: "music",
            label: "Background Music",
            icon : <LibraryMusicIcon/>
        }
    ]

    const classes = useStyles();

    const sliderStyleClasses = {
        rail: classes.sliderStyle,
        track: classes.sliderStyle,
        thumb: classes.sliderStyle,
        valueLabel: classes.sliderStyle,
    };
    return (
        <div
            className="d-flex flex-column align-items-center h-100"
            style={{ width: "100%" }}
        >
            <Modal
                onClose={toggleModal}
                open={custom_music_modal}
                style={{
                    position: "absolute",
                    // backgroundColor: 'white',
                    color: "black",
                    height: "85vh",
                    width: "80vh",
                    margin: "auto",
                }}
            >
                <div style={{ width: "100%", height: "100%" }}>
                    <Dropzone
                        containerStyle={{ height: "100%" }}
                        onFilesAdded={upload_music}
                        status={musicUploadStatus}
                        file={musicUploadFile}
                    />
                </div>
            </Modal>
           
            <div
                style={{
                    width: "100%",
                }}
            >
                <ThemeTabView
                    tabs={tabs}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                />
                {tabValue === "voice" && (
                    <>
                       
                        <div style={{marginTop:'10px'}} className="container">
                           
                        </div>
                        <div className="container">
                            <List
                                sx={{
                                    width: "95%",
                                  
                                    bgcolor: "background.gray",
                                    position: "relative",
                                    overflow: "auto",
                                    maxHeight: 450,
                                    "& ul": { padding: 0 },
                                }}
                            >
                                {voiceTemplates.length ? (
                                    voiceTemplates.map((voice, index) => (
                                        <ListItem
                                            key={index}
                                            component="div"
                                            disablePadding
                                        >
                                            <GenerateVoiceButton2
                                                musicDetails={voice}
                                                playing={playing}
                                                setPlaying={setPlaying}
                                                audioId={voiceId}
                                                changeAudioId={changeVoiceId}
                                                changeVoiceSpeed={changeVoiceSpeed}
                                                key={index}
                                            />
                                        </ListItem>
                                    ))
                                ) : (
                                    <NoData label="Name" value="name" />
                                )}
                            </List>
                        </div>
                    </>
                )}
                {tabValue === "music" && (
                    <>
                        <div className="container ">
                            <List
                                sx={{
                                    width: "100%",
                                    maxWidth: 460,
                                    bgcolor: "background.gray",
                                    position: "relative",
                                    overflow: "auto",
                                    maxHeight: 360,
                                    "& ul": { padding: 0 },
                                }}
                            >
                                {musicTemplates.map((voice, index) => (
                                    <ListItem
                                        key={index}
                                        component="div"
                                        disablePadding
                                    >
                                        <GenerateVoiceButton
                                            musicDetails={voice}
                                            playing={playing}
                                            setPlaying={setPlaying}
                                            audioId={musicId}
                                            changeAudioId={changeMusicId}
                                            key={index}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <br />
                            {customMusic && (
                                <GenerateVoiceButton
                                    musicDetails={{
                                        name: customMusic.name,
                                        thumbnail: customMusic.url,
                                        id: "custom-music",
                                    }}
                                    playing={playing}
                                    setPlaying={setPlaying}
                                    audioId={"custom-music"}
                                    changeAudioId={() => changeMusicId(null)}
                                />
                            )}
                        </div>
                        <div
                            className="container mt-2"
                            style={{
                                backgroundColor: "grey",
                                borderRadius: "10px",
                                width: "95%",
                                padding: "10px",
                                cursor: "pointer",
                                textTransform: "none"
                            }}
                            onClick={toggleModal}
                        >
                            <h5 className="mt-2 text-center">Upload Audio</h5>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default VerticalMusic;
