
export const mainContentWidthAndHeight = (isSideBarOpen) => {
    const currentWidth=window.innerWidth;
    const currentHeight=window.innerHeight;
    if(currentWidth >= 1400 && currentHeight >= 700){
        
      return {width:"127vh",height:"71.60vh"}

    }else if(currentWidth >= 900 && currentHeight <= 660){
       
            return {width:"114.55vh",height:"64.5vh"}
        
    }else{
            return {width:"114.55vh",height:"64.5vh"}
    }
}