import { styled } from '@mui/material/styles';
import TextField from 'material-ui/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Navbar } from 'react-bootstrap';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F5F5DC",
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const TopNavBarButton = (props) => {
  const { onClickButton, id, buttonData } = props;
  return (
    <div
      style={{
        padding: "3px",
        margin: "5px 5px 0 10px",
        cursor: "pointer",
      }}
      onClick={() => onClickButton(id)}
    > 

<Navbar>
        <Navbar.Brand style={{ color: "white" ,fontWeight:'500'}} href="#home"> {buttonData.name}</Navbar.Brand>
        <Navbar.Toggle />
        {/* <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Signed in as: <a href="#login">Mark Otto</a>
          </Navbar.Text>
        </Navbar.Collapse> */}
      </Navbar>
     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  
      <p></p>
    </div>
      <p>  </p>
    </div>
  );
};

export default TopNavBarButton;
