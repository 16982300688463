import React from 'react';
import "./DisseminationSettings.css";
import axios from 'axios';
import { Modal, ModalBody, Form, FormGroup, Input } from "reactstrap";
import { getToken } from 'common/Authentication';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import Card from 'components/Card/Card';
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import VideoCreatorLogo from 'container/CommonSections/VideoCreatorLogoContainer';
// import { Button } from '@mui/material';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DisseminationSettings(props) {
  //snackbar options
  const [snackOpen , setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [clientInfo, setClientInfo] = React.useState(null);
  const [dialog360ApiKey, setDialog360ApiKey] = React.useState("");
  const [addTemplateModalOpen, setAddTemplateModalOpen] = React.useState(false);
  const [addTemplateFormData, setAddTemplateFormData] = React.useState({});
  const [templateData, setTemplateData] = React.useState({});

  React.useEffect(() => {
    if(clientInfo === null) {
      refreshData();
    }
  });

  const refreshData = () => {
    axios({
      method: 'GET',
      url: '/admin/client-details',
      headers: {
          Authorization:'Bearer ' + getToken(),
          RequestTime:new Date()
      }
      }).then((res)=>{
        if(res.status===200){
          // console.log(res.data.data)
          setClientInfo(res.data.data);
          if(res.data.data && res.data.data.dissemination && res.data.data.dissemination['360dialog_api_key']){
            setDialog360ApiKey(res.data.data.dissemination['360dialog_api_key']);
            fetchWATemplates();
          }
        }
    })
  }

  const fetchWATemplates = () => {
    axios({
      method: 'GET',
      url: '/dissemination/wa-template/all',
      headers: {
          Authorization:'Bearer ' + getToken(),
          RequestTime:new Date()
      }
      }).then((res)=>{
        if(res.status===200){
          setTemplateData(res.data.data)
        }
    })
  }

  const set360DialogAPIKey = () => {
    axios({
      method: 'POST',
      url: '/dissemination/update-details',
      headers: {
          Authorization:'Bearer ' + getToken(),
      },
      data:{
        '360dialog_api_key': dialog360ApiKey
      }
      }).then((res)=>{
        if(res.status===200){
          openSnack("Successfully added 360dialog API Key.", "success");
          refreshData();
        }
    })
  }

  const setPlaceHolders = (text) => {
    let placeholders_list = text.match(/\{\{[^!\}]*\}\}/g)
    let placeholders = {};
    placeholders_list.forEach((e)=>{placeholders[e]=null});
    setAddTemplateFormData((prevData)=>{ return {...prevData, placeholders:placeholders}});
  }

  const createTemplate = () => {
    let text_example_placeholders = [];
    for(let i=0;i<Object.entries(addTemplateFormData.placeholders).length;i++){
      if(addTemplateFormData.placeholders["{{"+i+"}}"]){
        text_example_placeholders.push(addTemplateFormData.placeholders["{{"+i+"}}"])
      }
    }
    axios({
      method: 'POST',
      url: '/dissemination/wa-template',
      headers: {
          Authorization:'Bearer ' + getToken(),
      },
      data:{
        name: addTemplateFormData.name,
        category: addTemplateFormData.category,
        text: addTemplateFormData.text,
        text_example_placeholders: text_example_placeholders,
      }
      }).then((res)=>{
        if(res.status===200){
          openSnack("Successfully added 360dialog API Key.", "success");
          refreshData();
        }
    })
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const openSnack = (message, severity) => {
    setSnackOpen(true);
    setSnackMessage(message);
    setSnackSeverity(severity);
  }

  return (
    <>
      <VideoCreatorLogo />
      <div className='WASettings m-5 w-90' style={{height:"100vh",position:"relative"}}>
        {!clientInfo ? "Loading..." : 
          clientInfo.dissemination && clientInfo.dissemination['360dialog_api_key'] ? 
          <>
          <div style={{height:"80%"}}>
            <GridContainer className='mt-2 w-100'>
              <GridItem className="text-center">
                <h3><b>Update 360dialog API Key</b></h3>
                <h5><a target="_blank" href='https://hub.360dialog.com/lp/whatsapp/g2tKghPA'>click here</a> to create another WABA account. Once created update your API key below.</h5>
              </GridItem>
            </GridContainer>
            <GridContainer className='align-items-center'>
              <GridItem md={1}></GridItem>
              <GridItem md={8}>
                <Input
                  placeholder="360DIALOG API KEY"
                  value={dialog360ApiKey}
                  onChange={(e) => setDialog360ApiKey(e.target.value)}
                />
              </GridItem>
              <GridItem md={3}>
                <Button className="api_key_button" onClick={() => set360DialogAPIKey()}>Update key</Button>
              </GridItem>
            </GridContainer>
            <div className='m-1 customScrollbar' style={{position:"absolute",overflowY:"scroll",height:"45%",overflowX:"hidden"}}>
              {Object.entries(templateData).map((elem, idx) => 
                <Card>
                  <GridContainer className='m-1'>
                    <GridItem md={10}>
                      <h4 className='pb-0 mb-0'><b>{elem[0]}</b></h4>
                      <span className='mx-1 mt-0 pt-0'>category: {elem[1]['category']}</span>
                      <h5>{elem[1]['text']}</h5>
                    </GridItem>
                    <GridItem md={2}>
                      <h6 className='text-end text-secondary mx-1'>{elem[1]['status']}</h6>
                    </GridItem>
                  </GridContainer>
                </Card>
              )}
            </div>
          </div>
          <div className='w-100 text-center' style={{position:"absolute",top:"69%"}}>
              <Button className="api_key_button" onClick={() => setAddTemplateModalOpen(true)}>Add template</Button>
           </div>
          </>
          :
          <>
            <GridContainer className='m-5'>
              <GridItem className="text-center">
                <h3><b>Use Whatsapp Business API to send messages via WhatsApp directly through Ai vido Studio.</b></h3>
                <h5>Ai video Studio natively supports integration with 360dialog for WhatsApp Business API. </h5>
                <h5><a target="_blank" href='https://hub.360dialog.com/lp/whatsapp/g2tKghPA'>click here</a> to create your WABA account. Once created submit your API key below.</h5>
              </GridItem>
            </GridContainer>
            <GridContainer className='m-5 align-items-center'>
              <GridItem md={1}></GridItem>
              <GridItem md={8}>
                <Input
                  placeholder="360DIALOG API KEY"
                  value={dialog360ApiKey}
                  onChange={(e) => setDialog360ApiKey(e.target.value)}
                />
              </GridItem>
              <GridItem md={3}>
                <Button className="api_key_button" onClick={() => set360DialogAPIKey()}>Add key</Button>
              </GridItem>
            </GridContainer>
          </>
        }
      </div>
      <Modal
        isOpen={addTemplateModalOpen}
        toggle={()=>setAddTemplateModalOpen(false)}
        size="lg"
      >
        <ModalBody className='m-5'>
            <h2 className='text-center'>Add Template</h2>
            <Form>
              <FormGroup>
                <TextField
                  variant="outlined"
                  label="template name"
                  value={addTemplateFormData.name}
                  onChange={ (e)=>{
                    setAddTemplateFormData({...addTemplateFormData, name: e.target.value})
                  }}
                  fullWidth
                />
              </FormGroup>
              <FormGroup>
              <FormControl fullWidth>
                <InputLabel id="category_label">category</InputLabel>
                <Select
                  labelId='category_label'
                  inputProps={{
                    name: "category"
                  }}
                  label="Category"
                  value={addTemplateFormData.category}
                  onChange={(e)=>{setAddTemplateFormData({...addTemplateFormData, category: e.target.value})}}
                >
                    <MenuItem value="ACCOUNT_UPDATE">ACCOUNT_UPDATE</MenuItem>
                    <MenuItem value="PAYMENT_UPDATE">PAYMENT_UPDATE</MenuItem>
                    <MenuItem value="PERSONAL_FINANCE_UPDATE">PERSONAL_FINANCE_UPDATE</MenuItem>
                    <MenuItem value="SHIPPING_UPDATE">SHIPPING_UPDATE</MenuItem>
                    <MenuItem value="RESERVATION_UPDATE">RESERVATION_UPDATE</MenuItem>
                    <MenuItem value="APPOINTMENT_UPDATE">APPOINTMENT_UPDATE</MenuItem>
                    <MenuItem value="TRANSPORTATION_UPDATE">TRANSPORTATION_UPDATE</MenuItem>
                    <MenuItem value="TICKET_UPDATE">TICKET_UPDATE</MenuItem>
                    <MenuItem value="ALERT_UPDATE">ALERT_UPDATE</MenuItem>
                    <MenuItem value="AUTO_REPLY">AUTO_REPLY</MenuItem>
                    <MenuItem value="ISSUE_RESOLUTION">ISSUE_RESOLUTION</MenuItem>
                </Select>
                </FormControl>
              </FormGroup>
              <FormGroup>
                <TextField
                  variant="outlined"
                  value={addTemplateFormData.text} 
                  label='caption'
                  onChange={ (e)=>{
                    setAddTemplateFormData(prevData => {return {...prevData, text: e.target.value}})
                    setPlaceHolders(e.target.value);
                  }}
                  multiline
                  
                  rows={6}
                />
                {"* Add dynamic fields enclosed in double curly braces. sample template - Hello {{1}}, the delivery {{2}} will arrive at {{3}}."}
              </FormGroup>
              <GridContainer>
              {addTemplateFormData.placeholders && Object.entries(addTemplateFormData.placeholders).map((elem, _)=>
                <GridItem md={6}>
                  <FormGroup>
                    <TextField
                      variant="outlined"
                      label={"Example "+elem[0]}
                      value={elem[1]}
                      onChange={ (e)=>{
                        setAddTemplateFormData({...addTemplateFormData, placeholders: {...addTemplateFormData.placeholders, [elem[0]]:e.target.value}})
                      }}
                      fullWidth
                    />
                  </FormGroup>
                </GridItem>
              )}
              </GridContainer>
              <div className='text-center m-4'>
                <Button onClick={()=>{createTemplate();}} className='api_key_button'> Add Template</Button>
              </div>
            </Form>
          </ModalBody>
      </Modal>
      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default DisseminationSettings;
