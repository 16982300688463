/* eslint-disable */
import { useState } from "react";
import { TwitterPicker } from "react-color";
import CloseSidebarIcon from "./CloseSidebarIcon";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GenerateBackground from "./GenerateBackground";

import axios from "axios";
import Modal from "@material-ui/core/Modal";
import Dropzone from "../MainEditorComponents/Dropzone";
import { getToken } from "common/Authentication";
import ThemeTabView from "../../CommonSections/ThemeTabView";
import PaletteIcon from "@mui/icons-material/Palette";
import CollectionsIcon from "@mui/icons-material/Collections";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";

const VerticalBackground = (props) => {
    const [tabValue, setTabValue] = useState("images");

    const colorArray = [
        "#4D4D4D",
        "#999999",
        "#FFFFFF",
        "#F44E3B",
        "#FE9200",
        "#FCDC00",
        "#DBDF00",
        "#A4DD00",
        "#68CCCA",
        "#73D8FF",
        "#AEA1FF",
        "#FDA1FF",
        "#333333",
        "#808080",
        "#cccccc",
        "#D33115",
        "#E27300",
        "#FCC400",
        "#B0BC00",
        "#68BC00",
        "#16A5A5",
        "#009CE0",
        "#7B64FF",
        "#FA28FF",
        "#000000",
        "#666666",
        "#B3B3B3",
        "#9F0500",
        "#C45100",
        "#FB9E00",
    ];

    const tabs = [

        {
            value: "images",
            label: "Images",
            icon: <CollectionsIcon />,
        },
        {
            value: "videos",
            label: "Videos",
            icon: <VideoLibraryIcon />,
        },
        {
            value: "colors",
            label: "Colors",
            icon: <PaletteIcon />,
        }
    ];

    const [color, setColor] = useState("");
    const [custom_bg_modal, setCustomBgModal] = useState(false);
    const [bgUploadStatus, setBgUploadStatus] = useState(null);
    const [bgUploadFile, setBgUploadFile] = useState(null);

    const toggleModal = () => {
        setCustomBgModal(!custom_bg_modal);
    };

    const set_bg_template = (file) => {
        setBgUploadStatus("pending");
        setBgUploadFile(file);
        let data = new FormData();
        data.append("file", file);
        data.append("fetch_thumbnail", true);
        axios
            .post("/util/resource", data, {
                headers: {
                    Authorization: "Bearer " + getToken(),
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setBgUploadStatus("success");
                    props.setCustomBg(
                        res.data.data.url,
                        res.data.data.thumbnail_url
                    );
                } else {
                    throw "temp";
                }
            })
            .catch(() => {
                setBgUploadStatus("fail");
            });
    };

    return (
        <div
            className="d-flex flex-column align-items-center h-100"
            style={{ width: "100%" }}
        >
            <Modal
                onClose={toggleModal}
                open={custom_bg_modal}
                style={{
                    position: "absolute",
                    // backgroundColor: 'white',
                    color: "black",
                    height: "85vh",
                    width: "80vh",
                    margin: "auto",
                }}
            >
                <div style={{ width: "100%", height: "100%" }}>
                    <Dropzone
                        containerStyle={{ height: "100%" }}
                        onFilesAdded={set_bg_template}
                        status={bgUploadStatus}
                        file={bgUploadFile}
                    />
                </div>
            </Modal>
            <div style={{ width: "100%" }}>
                <ThemeTabView
                    tabs={tabs}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                />
                  <div
                    className="container row mt-4"
                >
                    <div className="col-6">
                        <div
                            style={{
                                backgroundColor: "#e4e4e4",
                                borderRadius: "5px",
                                padding: "10px",
                                cursor:"pointer"
                            }}
                            onClick={toggleModal}
                        >
                            <p className="text-center" style={{fontWeight:'500',color:'grey'}}>
                                
                                Upload your own Background
                            </p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div
                            style={{
                                backgroundColor: "#e4e4e4",
                                borderRadius: "5px",
                                padding: "10px",
                                cursor:"pointer"
                            }}
                            onClick={()=>{props.onBgChange("transparent", "color")}}
                        >
                            <p className="text-center" style={{fontWeight:'500',color:'grey'}}>
                                
                            Select no Background
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container mt-3" style={{
                  height: "160vh"
                }}>
                    {tabValue === "colors" && (
                        <TwitterPicker
                            color={color}
                            colors={colorArray}
                            onChangeComplete={(color) => {
                                setColor(color.hex);
                                props.onBgChange(color.hex, "color");
                            }}
                            triangle="hide"
                            className="mt-2"
                            width="450px"
                            styles={{
                                default: {
                                    card: {
                                        backgroundColor: "#3E3A3A",
                                    },
                                    label: {
                                        display: "block",
                                        marginTop: "10px",
                                    },
                                },
                            }}
                        />
                    )}
                    {tabValue === "images" && (
                        <GenerateBackground
                            onBgChange={props.onBgChange}
                            pexelBackgrounds={props.imageTemplatesPexel}
                            updatePexelPageOrSearch={
                                props.updatePexelPageOrSearch
                            }
                            isPexelImagesOrVideos={true}
                            isPexelsSuccessImg={props.isPexelsSuccessImg}
                        />
                    )}
                    {tabValue === "videos" && (
                        <GenerateBackground
                            onBgChange={props.onBgChange}
                            pexelBackgrounds={props.videoTemplatesPexel}
                            updatePexelPageOrSearch={
                                props.updatePexelPageOrSearch
                            }
                            isPexelImagesOrVideos={false}
                            isPexelsSuccessVideos={props.isPexelsSuccessVideos}
                            
                        />
                    )}
                </div>
              
            </div>
        </div>
    );
};

export default VerticalBackground;
