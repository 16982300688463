const SideBarButton = (props) => {
  const { onClickButton, id, buttonData } = props;

  return (
    <div
      style={{
        padding: "8px",
        margin: "20px 20px 0 20px",
        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px #FFFFFF",
        borderRadius: "10px",
        ...props.style
      }}
      onClick={() => onClickButton(id)}
    >
      {buttonData.icon_component ? <buttonData.icon_component /> : <img src={buttonData.icon} width={24} height={24} />}
    </div>
  );
};

export default SideBarButton;
