/*eslint-disable*/
import React, { Component } from "react";
import axios from "axios";
import { getToken } from "common/Authentication";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import { Navbar } from "react-bootstrap";
import NavBarEditor from "./NavBarEditor";
import NavBarHorizontal from "./NavBarHorizontal";
import VerticalMenu from "./CustomNavBarButtons/VerticalMenu";
import VerticalSlides from "./CustomNavBarButtons/VerticalSlides";
import Screen from "./MainEditorComponents/PreviewScreen";
import VerticalAvatar from "./CustomNavBarButtons/VerticalAvatar";
import VerticalBackground from "./CustomNavBarButtons/VerticalBackground";
import VerticalMusic from "./CustomNavBarButtons/VerticalMusic";
import CustomInput from "components/CustomInput/CustomInput";
import SideBarMenuIcon from "assets/img/iconsSidebar/sidebarMenuIcon.png";
import SideBarSlideIcon from "assets/img/iconsSidebar/SlidesIcon.svg";
import SideBarAvatarIcon from "assets/img/iconsSidebar/AvtarIcon.svg";
import SideBarBackgroundIcon from "assets/img/iconsSidebar/BackgroundIcon.svg";
import SideBarAudioIcon from "assets/img/iconsSidebar/AudioIcon.svg";
import EditorPanel from "./MainEditorComponents/EditorPanel";
import HomeIcon from "assets/img/iconsSidebar/Home.png";
import DatabaseIcon from "assets/img/iconsSidebar/database.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { logout } from "common/Authentication";
import PowerSettingNewIcon from "@mui/icons-material/PowerSettingsNew";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox"
import {Grid} from "@mui/material";
import { mappedDetectLanguage } from "../../utils/mappedLanguage";
import { Pexels_default_img } from "../../utils/image_pexels";
import { Pexels_default_Videos } from "../../utils/video_pexels";
var DetectLanguage = require("detectlanguage");
var detectlanguage = new DetectLanguage("21c6e8c055faf66f8c1d845ef19a23b7");
import { mainContentWidthAndHeight } from "../../utils/mainContentWidthAndHeight";
import VerticalScripts from "./CustomNavBarButtons/VerticalScripts";
import VerticalScriptTabs from "./CustomNavBarButtons/VerticalSciptTabs";

const boxModalStyle = {
  position: "absolute",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  background: "white",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "20px",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class Editor extends Component {
  constructor(props) {
    super(props);
    this.timeouts = {
      t2v: [20, 20, 40, 60, 70, 100, 150, 220, 330, 490, 730, 1090, 1630],
      preview_t2a: [5, 5, 10, 20, 30, 35, 50, 75, 110, 170, 250, 320],
    };
    this.snackBarVariantsMap = {
      error: "error",
      warning: "warning",
      info: "info",
      success: "success",
    };
    this.default_config = {
      avatarEnabled: true,
      avatarPosition: { width: 0.2, x: 0, y: 0.3625 }, // 16x9 ascpact ratio
      bg_id: null,
      audio_url: null,
      text: null,
      custom_bg_url: null,
      custom_bg_thumbnail_url: null,
      preview_audio_processing: false,
      preview_audio_url: "",
      preview_panel_display: false,
      preview_audio_playing: false,
      backgroundColorAvatar: null,
    };
    this.state = {
      title: "Untitled",
      previewSize: { width: 1000, height: 1000 },
      editor_open: true,
      avatar_id: null,
      voice_id: null,
      voiceSpeed: 1,
      music_id: null,
      processing: false,
      ready: false,
      req_id: null,
      allPlaceholders: [],
      slides: [],
      currentSlide: 0,
      backgroundTemplates: [],
      avatarTemplates: [],
      musicTemplates: [],
      voiceTemplates: [],
      categories: {},
      snackBarMessage: null,
      snackBarVariant: "success",
      dataLoadingState: null,
      isSideBarOpen: null,
      isModalOpen: false,
      requestInfo: null,
      imageTemplatesPexel: [],
      searchTextPexelForBackgroundTemplates:
        "Universe,Nature,Thunder,white background",
      currentPagePexelForBackgroundTemplates: 1,
      videoTemplatesPexel: [],
      searchTextPexelForVideoTemplates:
        "Universe,Nature,Thunder,white background",
      currentPagePexelForVideoTemplates: 1,
      isPexelsSuccessVideos: true,
      isPexelsSuccessImg: true,
      avatarProperties: {
        gender: "all",
        language: "all",
        apparel: "all",
      },
      detectLanguageText: "",
      requestId: null,
      requestType: "initiate",
    };
    this.idToBackUrlMapping = {};
  }

  componentDidMount() {
    this.props.match.params?.requestId &&
      this.prefilledDetails(this.props.match.params.requestId);
    this.initiateLoadingOfData();
    this.setState({ requestId: this.props.match.params.requestId });
  }

  prefilledDetails = async (requestId) => {
    try{
        const data=await axios.get(`/t2v/synthesis/fetch_request?request_id=${requestId}`,{
            headers: {
               Authorization: "Bearer " + getToken(),
               RequestTime: new Date()
            }
        });
        this.setState({
          title:data.data.data?.title,
          avatar_id:data.data.data?.configs.avatar?.id,
          voice_id:data.data.data?.configs.voice?.id,
          music_id:data.data.data?.configs.music?.id,
          slides:data.data.data?.configs?.background.map((config,index) => {
               return {
                   avatarEnabled: config?.avatar_enabled,
                   backgroundColorAvatar: config?.bg_avatar_color,
                   avatarPosition:{ width: config.crop[2], x:  config.crop[0], y:  config.crop[1] },
                   custom_bg_url:config?.url,
                   custom_bg_thumbnail_url:config?.thumbnail_url,
                   bg_color:config?.color,
                   text:data.data.data?.synthesis_data?.text[index],
                   audio:data.data.data?.synthesis_data?.audio[index],
                   bg_id: null,
                   preview_audio_processing: false,
                   preview_audio_url: "",
                   preview_panel_display: false,
               }
          }),
        });
    }catch(err){
        window.location.href="/app"
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.voice_id !== this.state.voice_id ||
      prevState.music_id !== this.state.music_id ||
      this.state.voiceSpeed !== prevState.voiceSpeed
    ) {
      this.resetAudio();
    }
  };

  updateAvatarBGColor = (color) => {
    const updateSlides = [...this.state.slides];
    updateSlides[this.state.currentSlide]["backgroundColorAvatar"] = color;
    this.setState({ slides: updateSlides });
  };

  applyFilterForVoiceAndAvatar = async () => {
    await this.handleApplyFilter("VOICE");
    await this.handleApplyFilter("AVATAR");
  };

  detectLanguage = async (currentText, previousText) => {
    let detectLanguageText =
      currentText.slice(0, 5) + currentText.slice(5).split(" ")[0];
    if (
      currentText.length >= 5 &&
      this.state.detectLanguageText !== detectLanguageText &&
      currentText.length > detectLanguageText.length
    ) {
      const languages = await detectlanguage.detect(detectLanguageText);
      this.setState({ detectLanguageText: detectLanguageText });
      languages.length !== 0 &&
        this.updateAvatarProperties({
          ...this.state.avatarProperties,
          language: mappedDetectLanguage.get(languages[0].language),
        });
    }
  };

  updateAvatarProperties = (properties) => {
    (async () => {
      let prev_properties = { ...this.state.avatarProperties };
      await this.setState({ avatarProperties: properties });
      if (prev_properties.gender !== properties.gender) {
        this.applyFilterForVoiceAndAvatar();
      } else if (prev_properties.language !== properties.language) {
        this.handleApplyFilter("VOICE");
      } else if (prev_properties.apparel !== properties.apparel) {
        this.handleApplyFilter("AVATAR");
      }
    })();
  };

  handleApplyFilter = async (section) => {
    const { gender, language, apparel } = this.state.avatarProperties;
    let queryString = `service=t2v&config_type=ENTITY-${section}${
      gender === "all" ? "" : `&gender=${gender}`
    }`;
    queryString +=
      section === "VOICE"
        ? `${language === "all" ? "" : `&language=${language}`}`
        : `${apparel === "all" ? "" : `&apparel=${apparel}`}`;
    try {
      const data = await axios.get(`/filter-service-config?${queryString}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          RequestTime: new Date(),
        },
      });
      section === "AVATAR"
        ? this.setAvatarTemplates(data.data.data)
        : this.setVoiceTemplates(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  initiateLoadingOfData = async () => {
    try {
      await Promise.all([
        this.getBackgroundTemplates(
          this.state.searchTextPexelForBackgroundTemplates
        ),
        this.fetchServiceConfig("ENTITY-MUSIC", "musicTemplates"),
        this.handleApplyFilter("VOICE"),
        this.handleApplyFilter("AVATAR"),
      ]);
      this.default_config.custom_bg_thumbnail_url =
        this.state.imageTemplatesPexel.length !== 0
          ? this.state.imageTemplatesPexel[0].image
          : null;
      this.default_config.custom_bg_url =
        this.state.imageTemplatesPexel.length !== 0
          ? this.state.imageTemplatesPexel[0].image
          : null;
      let genders = [
        ...new Set(
          this.state.avatarTemplates
            .map((data) => data.categories.gender)
            .filter((x) => x)
        ),
        "all",
      ];
      let apparels = [
        ...new Set(
          this.state.avatarTemplates
            .map((data) => data.categories.apparel_type)
            .filter((x) => x)
        ),
        "all",
      ];
      let languages = [
        ...new Set(
          this.state.voiceTemplates
            .map((data) => data.categories.language)
            .filter((x) => x)
        ),
        "all",
      ];
      await this.setState((prev_state) => {
        return {
          slides:
            prev_state.slides.length !== 0
              ? prev_state.slides
              : [this.default_config],
          dataLoadingState: "success",
          categories: {
            genders: genders,
            apparels: apparels,
            languages: languages,
          },
        };
      });
    } catch (error) {
      this.setState(
        { dataLoadingState: "error" },
        this.openSnackBar(
          `Error Loading Data ${error}`,
          this.snackBarVariantsMap.error
        )
      );
    }
  };
  filterResolution(data) {
    var height = 720;
    var width = 1280;
    var curr = data.video_files[0];
    for (var i = 0; i < data.video_files.length; i++) {
      if (
        Math.abs(height - data.video_files[i].height) <
          Math.abs(height - curr.height) &&
        Math.abs(width - data.video_files[i].width) <
          Math.abs(width - curr.width)
      )
        curr = data.video_files[i];
    }
    return curr.link;
  }
  fetchServiceConfig = async (config_type, propertyName, callback) => {
    const res = await axios({
      method: "GET",
      url: "/filter-service-config",
      headers: {
        Authorization: "Bearer " + getToken(),
        RequestTime: new Date(),
      },
      params: {
        service: "t2v",
        config_type: config_type,
      },
    });
    if (res.status == 200) {
      this.setState({ [propertyName]: res.data.data }, callback);
    }
  };

  setIdToBackUrlMapping = () => {
    this.state.backgroundTemplates.forEach((ele) => {
      this.idToBackUrlMapping[ele.id] = ele.image || ele.thumbnail;
    });
  };

  getBackgroundTemplates = async (
    searchText,
    isSearchForImagesTempletesPexel = true,
    isSeachForVideoTemplatesPexel = true
  ) => {
    /*return this.fetchServiceConfig(
      "ENTITY-BACKGROUND",
      "backgroundTemplates",
      this.setIdToBackUrlMapping
    );*/

    //fetching images from pexel
    if (isSearchForImagesTempletesPexel) {
      try {
        const resImage = await axios({
          method: "GET",
          url: `https://api.pexels.com/v1/search?query=${searchText}&orientation=landscape&per_page=80&page=${this.state.currentPagePexelForBackgroundTemplates}`,
          headers: {
            Authorization:
              "gkzBtAmL1LM5KKnbAOIylhX9fhyzPbmV1xAogvt4aBqgajJCBRTCYJ8W",
          },
        });
        const obj = [];
        resImage.data.photos.map((image) => {
          obj.push({
            id: image.id,
            name: image.alt,
            image: image.src.large,
          });
        });
        this.setState({ searchTextPexelForBackgroundTemplates: searchText });
        this.setState({ ["imageTemplatesPexel"]: obj });
        this.setState({ isPexelsSuccessImg: true });

        isSeachForVideoTemplatesPexel === false &&
          obj.length !== 0 &&
          this.bgChange(
            {
              custom_bg_url: obj[0].image,
              custom_bg_thumbnail_url: obj[0].image,
            },
            "custom_bg"
          );
      } catch (err) {
        this.setState({ ["imageTemplatesPexel"]: Pexels_default_img });
        this.setState({ isPexelImagesOrVideos: false });
        this.setState({ isPexelsSuccessImg: false });

        isSeachForVideoTemplatesPexel === false &&
          Pexels_default_img.length !== 0 &&
          this.bgChange(
            {
              custom_bg_url: Pexels_default_img[0].image,
              custom_bg_thumbnail_url: Pexels_default_img[0].image,
            },
            "custom_bg"
          );
      }
    }
    //fetching videos from pexel
    if (isSeachForVideoTemplatesPexel) {
      try {
        const resVideo = await axios({
          method: "GET",
          url: `https://api.pexels.com/v1/videos/search?query=${searchText}&orientation=landscape&per_page=80&page=${this.state.currentPagePexelForVideoTemplates}`,
          size: "4K",
          headers: {
            Authorization:
              "gkzBtAmL1LM5KKnbAOIylhX9fhyzPbmV1xAogvt4aBqgajJCBRTCYJ8W",
          },
        });
        if (resVideo.status == 200) {
          const obj = [];
          resVideo.data.videos.map((video) => {
            obj.push({
              id: video.id,
              name: video.url.split("/")[4],
              image: video.image,
              video: this.filterResolution(video),
            });
          });
          this.setState({ ["videoTemplatesPexel"]: obj });
          this.setState({ isPexelsSuccessVideos: true });

          isSeachForVideoTemplatesPexel === false &&
            obj.length !== 0 &&
            this.bgChange(
              {
                custom_bg_url: obj[0].image,
                custom_bg_thumbnail_url: obj[0].image,
              },
              "custom_bg"
            );
        }
      } catch (err) {
        this.setState({ ["videoTemplatesPexel"]: Pexels_default_Videos });
        this.setState({ isPexelsSuccessVideos: false });

        isSeachForVideoTemplatesPexel === false &&
          Pexels_default_Videos.length !== 0 &&
          this.bgChange(
            {
              custom_bg_url: Pexels_default_Videos[0].image,
              custom_bg_thumbnail_url: Pexels_default_Videos[0].image,
            },
            "custom_bg"
          );
      }
    }
  };
  updatePexelPageOrSearch = (
    searchText,
    isUserSearching = true,
    isUserSearchingForImages = true
  ) => {
    if (isUserSearchingForImages) {
      this.setState(
        {
          currentPagePexelForBackgroundTemplates: isUserSearching
            ? 1
            : this.state.currentPagePexelForBackgroundTemplates + 1,
        },
        () => {
          this.getBackgroundTemplates(
            isUserSearching
              ? searchText
              : this.state.searchTextPexelForBackgroundTemplates,
            true,
            false
          );
        }
      );
    } else {
      this.setState(
        {
          currentPagePexelForVideoTemplates: isUserSearching
            ? 1
            : this.state.currentPagePexelForVideoTemplates + 1,
        },
        () => {
          this.getBackgroundTemplates(
            isUserSearching
              ? searchText
              : this.state.searchTextPexelForVideoTemplates,
            false,
            true
          );
        }
      );
    }
  };
  deleteSlideWithId = (slideNumber) => {
    if (this.state.slides.length === 1) return;
    this.setState((prevState) => {
      return {
        currentSlide:
          prevState.currentSlide < slideNumber || slideNumber === 0
            ? prevState.currentSlide
            : prevState.currentSlide - 1,
        slides: prevState.slides.filter((ele, id) => id != slideNumber),
      };
    });
  };

  changeCurrentSlide = (slideNumber) => {
    this.setState({ currentSlide: slideNumber });
  };

  setAvatarTemplates = (avatars) => {
    this.setState({ avatarTemplates: avatars });
    this.setState((prev_state) => {
      if (
        avatars.length !== 0 &&
        avatars.map((x) => x.id).indexOf(prev_state.avatar_id) === -1
      ) {
        console.log(
          prev_state.avatar_id,
          this.state.avatar_id,
          avatars.map((x) => x.id)
        );
        return { ...prev_state, avatar_id: avatars[0].id };
      } else {
        return prev_state;
      }
    });
  };

  setVoiceTemplates = (voices) => {
    this.setState({ voiceTemplates: voices });
    this.setState((prev_state) => {
      if (
        voices.length !== 0 &&
        voices.map((x) => x.id).indexOf(prev_state.voice_id) === -1
      ) {
        return { ...prev_state, voice_id: voices[0].id };
      } else {
        return prev_state;
      }
    });
  };

  setVoiceSpeed = (voice_speed) => {
    this.setState({ voiceSpeed: voice_speed });
  };

  addNewSlide = (slideNumber) => {
    this.setState((prevState) => {
      let updatedSlides;
      if (slideNumber === -1) {
        updatedSlides = [
          {
            ...this.default_config,
            avatarPosition: prevState.slides[0].avatarPosition,
          },
          ...prevState.slides,
        ];
      } else if (slideNumber === prevState.slides.length - 1) {
        updatedSlides = [
          ...prevState.slides,
          {
            ...this.default_config,
            avatarPosition: prevState.slides[slideNumber].avatarPosition,
          },
        ];
      } else {
        updatedSlides = [
          ...prevState.slides.slice(0, slideNumber + 1),
          {
            ...this.default_config,
            avatarPosition: prevState.slides[slideNumber].avatarPosition,
          },
          ...prevState.slides.slice(slideNumber + 1),
        ];
      }
      return {
        slides: updatedSlides,
      };
    });
  };

  initiateSlidesFromPpt = (slides_url) => {
    this.setState((prevState) => {
      let updatedSlides = [];
      slides_url.forEach((slide) => {
        updatedSlides = [
          ...updatedSlides,
          {
            ...this.default_config,
            avatarPosition: prevState.slides[0].avatarPosition,
            custom_bg_url: slide["url"],
            custom_bg_thumbnail_url: slide["url"],
          },
        ];
      });
      return {
        slides: updatedSlides,
      };
    });
  };

  // SnackBar
  openSnackBar = (message, variant) => {
    this.setState({
      snackBarMessage: message,
      snackBarVariant: variant,
      isSnackBarOpen: true,
    });
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackBarMessage: null });
  };

  setAvatarEnabled = () => {
    const updateSlides = [...this.state.slides];
    updateSlides[this.state.currentSlide]["avatarEnabled"] =
      !updateSlides[this.state.currentSlide]["avatarEnabled"];
    this.setState({
      slides: updateSlides,
    });
  };

  setAvatarPosition = (x_val, y_val) => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            avatarPosition: {
              ...slide.avatarPosition,
              x: x_val,
              y: y_val,
            },
          };
        }),
      };
    });
  };

  setPreview = (area) => {
    this.setState({ previewSize: area });
  };

  setAvatarSize = (info) => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            avatarPosition: {
              ...slide.avatarPosition,
              width: info,
            },
          };
        }),
      };
    });
  };

  // additional

  initiateOrSaveRequest = (isSaveReport = false) => {
    if (this.state.processing) {
      return;
    }
    let totalTextLength = 0;
    this.state.slides.forEach(
      (slide) => (totalTextLength += slide.text?.length || 0)
    );
    let totalAudios = 0;
    this.state.slides.forEach(
      (slide) => (totalAudios += slide.audio?.url ? 1 : 0)
    );

    if (totalTextLength === 0 && totalAudios === 0 && !isSaveReport) {
      this.openSnackBar(
        "You need to enter text in atleast one slide",
        this.snackBarVariantsMap.info
      );
      return;
    }

    this.setState({ processing: true });
    const slideDataToUpload = this.state.slides.map((slide) => {
      if (slide.audio) {
        let { audio, ...without_audio } = slide;
        return { ...without_audio, audio_url: audio.url };
      }
      return {
        ...slide,
        text: this.processString(slide.text),
      };
    });
    console.log(slideDataToUpload);
    axios({
      method: "POST",
      url: !isSaveReport
        ? "/t2v/synthesis/initiatev2"
        : "/t2v/synthesis/save-request",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      data: {
        title: this.state.title,
        type: "t2v",
        slides: slideDataToUpload,
        avatar_configs: { id: this.state.avatar_id },
        music_configs: this.state.custom_music
          ? { url: this.state.custom_music.url }
          : { id: this.state.music_id },
        voice_configs: {
          id: this.state.voice_id,
          voice_speed: this.state.voiceSpeed,
        },
        extra_configs: { watermark: true },
        request_id: this.state.requestId,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            requestInfo: {
              status: res.data.data.request_status,
              type: "video",
            },
          });
          this.setState({
            isModalOpen: true,
            requestType: !isSaveReport ? "initiate" : "save",
          });
          console.log(res.data.data.request_id);
        } else {
          this.setState({ processing: false });
          throw "temp";
        }
      })
      .catch((error) => {
        this.setState({ processing: false });
        this.openSnackBar(error.message, this.snackBarVariantsMap.error);
      });
  };

  

  setCustomBg = (bg_url, thumbnail_url) => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            bg_id: null,
            custom_bg_url: bg_url,
            custom_bg_thumbnail_url: thumbnail_url,
          };
        }),
      };
    });
  };

  readFile = (file) => {
    var reader = new FileReader();
    let setText = this.setText;
    reader.onload = function (event) {
      setText(event.target.result);
    };
    reader.readAsText(file);
  };

  setAudioFile = (file) => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            text: null,
            audio: {
              file: file,
              status: "pending",
            },
          };
        }),
      };
    });
    let data = new FormData();
    data.append("file", file);
    data.append("fetch_thumbnail", false);
    axios
      .post("/util/resource", data, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.setState((prevState) => {
            return {
              slides: prevState.slides.map((slide, index) => {
                if (index !== prevState.currentSlide) return slide;
                return {
                  ...slide,
                  text: null,
                  audio: {
                    url: res.data.data.url,
                    file: file,
                    status: "success",
                  },
                };
              }),
            };
          });
        } else {
          throw "temp";
        }
      })
      .catch(() => {
        console.log("failed to upload audio");
        this.setState((prevState) => {
          return {
            slides: prevState.slides.map((slide, index) => {
              if (index !== prevState.currentSlide) return slide;
              return {
                ...slide,
                audio: {
                  file: file,
                  status: "fail",
                },
              };
            }),
          };
        });
      });
  };

  resetAudio = () => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            audio: null,
            preview_audio_url: "",
          };
        }),
      };
    });
  };

  setText = (text) => {
    let previousText = this.state.slides[this.state.currentSlide]["text"];
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            text: text,
            audio: null,
            preview_audio_url: "",
          };
        }),
      };
    });
    // this.detectLanguage(text, previousText);
  };

  initiateAudio = () => {
    const textToSend = this.processString(
      this.state.slides[this.state.currentSlide].text
    );
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            preview_audio_processing: true,
          };
        }),
      };
    });
    var data = {
      title: this.state.title,
      slides: [{ text: textToSend }],
      type: "t2a",
      music_configs: this.state.custom_music
        ? { url: this.state.custom_music.url }
        : { id: this.state.music_id },
      voice_configs: {
        id: this.state.voice_id,
        voice_speed: this.state.voiceSpeed,
      },
    };
    axios({
      method: "POST",
      url: "/t2v/synthesis/initiatev2",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          let retry_id = 0;
          setTimeout(
            () => this.audioStatusQuery(res.data.data.request_id, retry_id + 1),
            this.timeouts["preview_t2a"][retry_id] * 1000
          );
        } else {
          throw "temp";
        }
      })
      .catch((error) => {
        this.setState((prevState) => {
          return {
            slides: prevState.slides.map((slide, index) => {
              if (index !== prevState.currentSlide) return slide;
              return {
                ...slide,
                preview_audio_processing: false,
              };
            }),
          };
        });
      });
  };

  audioStatusQuery = (request_id, retry_id) => {
    axios({
      method: "GET",
      url: "/t2v/synthesis/status",
      headers: {
        Authorization: "Bearer " + getToken(),
        RequestTime: new Date(),
      },
      params: {
        request_id: request_id,
      },
    })
      .then((res) => {
        if (res) {
          if (res.data.data.response_status === "pending") {
            throw "temp";
          } else {
            this.setState((prevState) => {
              return {
                slides: prevState.slides.map((slide, index) => {
                  if (index !== prevState.currentSlide) return slide;
                  return {
                    ...slide,
                    preview_audio_url: res.data.data.response_url,
                    preview_panel_display: true,
                    preview_audio_processing: false,
                  };
                }),
              };
            });
          }
        }
      })
      .catch((e) => {
        if (retry_id < this.timeouts["preview_t2a"].length) {
          setTimeout(
            () => this.audioStatusQuery(request_id, retry_id + 1),
            this.timeouts["preview_t2a"][retry_id] * 1000
          );
        } else {
          this.setState((prevState) => {
            return {
              slides: prevState.slides.map((slide, index) => {
                if (index !== prevState.currentSlide) return slide;
                return {
                  ...slide,
                  preview_audio_processing: false,
                };
              }),
            };
          });
        }
      });
  };

  showPreview = () => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            preview_panel_display: true,
          };
        }),
      };
    });
  };

  hidePreview = () => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) return slide;
          return {
            ...slide,
            preview_panel_display: false,
          };
        }),
      };
    });
  };

  voiceChange = (id) => {
    this.setState({ voice_id: id });
  };

  editorToggle = () => {
    if (this.state.editor_open) {
      this.setState({ upload_open: true, editor_open: false });
    } else {
      this.setState({ upload_open: false, editor_open: true });
    }
  };

  bgChange = (bg, type = "id") => {
    this.setState((prevState) => {
      return {
        slides: prevState.slides.map((slide, index) => {
          if (index !== prevState.currentSlide) {
            return slide;
          }
          if (type === "id")
            return {
              ...slide,
              bg_color: null,
              bg_id: bg,
              custom_bg_url: null,
              custom_bg_thumbnail_url: null,
            };
          else if (type === "color")
            return {
              ...slide,
              bg_color: bg,
              bg_id: null,
              custom_bg_url: null,
              custom_bg_thumbnail_url: null,
            };
          else if (type === "custom_bg")
            return {
              ...slide,
              bg_color: null,
              bg_id: null,
              custom_bg_url: bg.custom_bg_url,
              custom_bg_thumbnail_url: bg.custom_bg_thumbnail_url,
            };
        }),
      };
    });
  };

  musicChange = (id) => {
    this.setState({ music_id: id, custom_music: null });
  };

  setCustomMusic = (name, url) => {
    this.setState({ music_id: null, custom_music: { name: name, url: url } });
  };

  handleGlobalConfigChange = (name, callback) => {
    if (this.state.slides.length === 1) {
      callback();
    } else {
      this.setState({ globalConfigChange: { name: name, callback: callback } });
    }
  };

  avatarChange = (id) => {
    this.setState({ avatar_id: id });
  };

  addAllPlaceholders = (placeholder) => {
    this.state.allPlaceholders.includes(placeholder) ||
      this.setState({
        allPlaceholders: [...this.state.allPlaceholders, placeholder],
      });
  };


  getAllPlaceholders = (slideText) => {
    let placeholdersArray = [];
    for (let i = 0; i < slideText.length; i++) {
      if (slideText.substr(i, 2) === "{{") {
        let j = i + 1;
        while (
          j + 1 < slideText.length &&
          slideText.substr(j + 1, 2) !== "}}"
        ) {
          j++;
        }
        placeholdersArray.push(slideText.substr(i + 2, j - i - 1));
      }
    }
    return placeholdersArray;
  };

  processString = (currentText) => {
    if (!currentText) currentText = "";
    currentText = currentText.replaceAll("&nbsp;", " ");
    currentText = currentText.replaceAll("<br>", " ");
    let lastOneId = [],
      processedText = "";
    for (let i = 0; i < currentText.length; i++) {
      if (currentText[i] === "<") {
        let j = i + 1;
        while (j + 1 < currentText.length && currentText[j + 1] !== ">") j++;
        if (j + 1 < currentText.length && currentText[j + 1] === ">") j++;

        let content = currentText.substr(i, j - i + 1);

        if (currentText[i + 1] === "/") {
          if (lastOneId.at(-1) === "placeholder") processedText += "}}";
          else if (lastOneId.at(-1) === "silence") processedText += "]";
          lastOneId.pop();
        } else {
          if (content.search("placeholder") !== -1)
            lastOneId.push("placeholder"), (processedText += "{{");
          else if (content.search("silence") !== -1)
            lastOneId.push("silence"), (processedText += "[");
          else lastOneId.push(null);
        }
        i = j;
      } else processedText += currentText[i];
    }
    return processedText;
  };

  saveAsTemplate = () => {
    let totalTextLength = 0;
    this.state.slides.forEach(
      (slide) => (totalTextLength += slide.text?.length || 0)
    );
    let totalAudios = 0;
    this.state.slides.forEach(
      (slide) => (totalAudios += slide.audio?.url ? 1 : 0)
    );

    if (totalTextLength === 0 && totalAudios === 0) {
      this.openSnackBar(
        "You need to enter text in atleast one slide",
        this.snackBarVariantsMap.info
      );
      return;
    }
    let placeholders = [];
    const slideDataToUpload = this.state.slides.map((slide) => {
      if (!slide.text) return slide;
      const processedString = this.processString(slide.text);
      placeholders.push(...this.getAllPlaceholders(processedString));
      return {
        ...slide,
        text: processedString,
        custom_bg_thumbnail_url:
          slide.custom_bg_thumbnail_url || this.idToBackUrlMapping[slide.bg_id],
      };
    });

    axios({
      method: "POST",
      url: "/t2v/template/",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      data: {
        title: this.state.title,
        type: "t2v",
        slides: slideDataToUpload,
        placeholders: [...new Set(placeholders)],
        avatar_configs: { id: this.state.avatar_id },
        music_configs: this.state.custom_music
          ? { url: this.state.custom_music.url }
          : { id: this.state.music_id },
        voice_configs: { id: this.state.voice_id },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            requestInfo: {
              status: res.data.data.template_status,
              type: "template",
            },
          });
          this.setState({ isModalOpen: true });
        } else {
          throw "Internal Server Error";
        }
      })
      .catch((error) => {
        this.openSnackBar(error.message, this.snackBarVariantsMap.error);
      });
  };

  openEditor = () => {
    this.setState({ editor_open: true, upload_open: false });
  };

  render() {
    const mainContentWidth = mainContentWidthAndHeight(
      this.state.isSideBarOpen
    ).width;
    const previewContentHeight = mainContentWidthAndHeight(
      this.state.isSideBarOpen
    ).height;
    return (
      <>
        <div></div>

        <div class="flex-column" style={{ display: "flex", height: "100vh" }}>
          <NavBarHorizontal 
            sideBarBottomButtons={[
              {
                name: "Home",
                icon: HomeIcon,
                onClick: () => this.props.history.push("/app"),
              },
              {
                name: "logout",
                icon_component: PowerSettingNewIcon,
                onClick: () => {
                  logout();
                  window.location.href = "/app";
                },
              },
            ]}

            title={this.state.title}
            setTitle={(title)=>{this.setState({title: title})}}
          
            initiateOrSaveRequest = {this.initiateOrSaveRequest}
            saveAsTemplate=  {this.saveAsTemplate}
            
            isSideBarOpen={this.state.isSideBarOpen}
            updateSideBarOpen={(ele) => this.setState({ isSideBarOpen: ele })}
          ></NavBarHorizontal>
<Grid style={{marginTop:"0px" ,marginBottom:"0px"}} container my={12}>

<Grid item  lg={4} md={4}>
        
<NavBarEditor
            sideBarTopButtons={[
              // {
              //   name: "menu",
              //   icon: SideBarMenuIcon,
              //   componentToRender: VerticalMenu,
              //   props: {
              //     initiateOrSaveRequest: this.initiateOrSaveRequest,
              //     saveAsTemplate: this.saveAsTemplate,
              //   },
              // },
              {
                
                name: "scripts",
                icon: SideBarSlideIcon,
                componentToRender: VerticalScriptTabs,
                props: { mainContentWidth:  mainContentWidth,
                  editorOpen:  this.state.editor_open,
                  currentSlide : this.state.slides[this.state.currentSlide],
                  setEditorText:  this.setText,
                  setAudioFile: this.setAudioFile,
                  uploadAudioFile:  this.uploadAudioFile,
                  editorToggle:  this.editorToggle,
                  openEditor:  this.openEditor,
                  isSideBarOpen:  this.state.isSideBarOpen,
                  initiateOrSaveRequest:  this.initiateOrSaveRequest,
                  initiateAudio:  this.initiateAudio,
                  showPreview:  this.showPreview,
                  hidePreview:  this.hidePreview,
                  allPlaceholders:  this.state.allPlaceholders,
                  addAllPlaceholders:  this.addAllPlaceholders,
                  slideslength : this.state.slides.length,
                  readFile:  this.readFile,
                  setAvatarEnabled:  this.setAvatarEnabled,
                  updateAvatarBGColor:  this.updateAvatarBGColor ,
                  voiceSpeed: this.state.voiceSpeed,
                  changeVoiceSpeed: (voice_speed)=>this.handleGlobalConfigChange("speaking rate", ()=>this.setVoiceSpeed(voice_speed))
                },
              },
              {
                name: "Actor",
                icon: SideBarAvatarIcon,
                componentToRender: VerticalAvatar,
                props: {
                  avatarTemplates: this.state.avatarTemplates,
                  categories: this.state.categories,
                  setAvatarEnabled:  this.setAvatarEnabled,
                  avatarEnabled: this.state.dataLoadingState === "success" && this.state.slides[this.state.currentSlide]["avatarEnabled"],
                  onAvatarChange: (id) =>
                    this.handleGlobalConfigChange("avatar", () =>
                      this.avatarChange(id)
                    ),
                  avatarProperties: this.state.avatarProperties,
                  updateAvatarProperties: this.updateAvatarProperties,
                },
              },
              {
                name: "background",
                icon: SideBarBackgroundIcon,
                componentToRender: VerticalBackground,
                props: {
                  imageTemplatesPexel: this.state.imageTemplatesPexel,
                  setCustomBg: this.setCustomBg,
                  onBgChange: this.bgChange,
                  updatePexelPageOrSearch: this.updatePexelPageOrSearch,
                  videoTemplatesPexel: this.state.videoTemplatesPexel,
                  isPexelsSuccessVideos: this.state.isPexelsSuccessVideos,
                  isPexelsSuccessImg: this.state.isPexelsSuccessImg,
                },
              },
              {
                name: "audio",
                icon: SideBarAudioIcon,
                componentToRender: VerticalMusic,
                props: {
                  musicTemplates: this.state.musicTemplates,
                  voiceTemplates: this.state.voiceTemplates,
                  categories: this.state.categories,
                  voiceId: this.state.voice_id,
                  voiceSpeed: this.state.voiceSpeed,
                  musicId: this.state.music_id,
                  customMusic: this.state.custom_music,
                  changeVoiceSpeed: (voice_speed) =>
                    this.handleGlobalConfigChange("speaking rate", () =>
                      this.setVoiceSpeed(voice_speed)
                    ),
                  changeVoiceId: (id) =>
                    this.handleGlobalConfigChange("voice", () =>
                      this.voiceChange(id)
                    ),
                  changeMusicId: (id) =>
                    this.handleGlobalConfigChange("music", () =>
                      this.musicChange(id)
                    ),
                  setCustomMusic: (name, url) =>
                    this.handleGlobalConfigChange("music", () =>
                      this.setCustomMusic(name, url)
                    ),
                  avatarProperties: this.state.avatarProperties,
                  updateAvatarProperties: this.updateAvatarProperties,
                },
              },
            ]}
            sideBarBottomButtons={[
              {
                name: "logout",
                icon_component: PowerSettingNewIcon,
                onClick: () => {
                  logout();
                  window.location.href = "/app"},
              },
              {
                name: "settings",
                icon_component: SettingsOutlinedIcon,
                onClick: () => {window.location.href = "/app/profile"},
              },
              {
                name: "home",
                icon: HomeIcon,
                onClick: () => this.props.history.push("/app"),
              },
              {
                name: "database",
                icon: DatabaseIcon,
                onClick: () => this.props.history.push("/app/report"),
              },
            ]}
            isSideBarOpen={this.state.isSideBarOpen}
            updateSideBarOpen={(ele) => this.setState({ isSideBarOpen: ele })}
         
          ></NavBarEditor>
         

        </Grid>

        <Grid item lg={8} md={8}>
          {this.state.dataLoadingState === "success" && (
            <div
              style={{width:"100%", display:"flex", backgroundColor: "#EFEFEF" }}
              className="flex-column"
            >
             
              <Screen
                avatar_templates={this.state.avatarTemplates}
                bg_templates={this.state.backgroundTemplates}
                slides={this.state.slides}
                currentSlide={this.state.currentSlide}
                avatar_id={this.state.avatar_id}
                previewSize={this.state.previewSize}
                setPreview={this.setPreview}
                setAvatarPosition={this.setAvatarPosition}
                setAvatarSize={this.setAvatarSize}
                mainContentWidth={mainContentWidth}
                previewContentHeight={previewContentHeight}
                currentAvatarBackgroundColor={
                  this.state.slides.length !== 0 &&
                  this.state.slides[this.state.currentSlide][
                    "backgroundColorAvatar"
                  ]
                }
                avatarVisible={
                  this.state.slides[this.state.currentSlide]["avatarEnabled"]
                }
                slidesList= {this.state.slides}
                idToBackUrlMapping = {this.idToBackUrlMapping}
                deleteSlideWithId = {this.deleteSlideWithId}
                changeCurrentSlide = {this.changeCurrentSlide}
                addNewSlide = {this.addNewSlide}
                initiateSlidesFromPpt =  {this.initiateSlidesFromPpt}
              />
              <EditorPanel
                mainContentWidth={mainContentWidth}
                editorOpen={this.state.editor_open}
                editorText={this.state.slides[this.state.currentSlide].text}
                editorAudio={this.state.slides[this.state.currentSlide].audio}
                setEditorText={this.setText}
                setAudioFile={this.setAudioFile}
                uploadAudioFile={this.uploadAudioFile}
                editorToggle={this.editorToggle}
                openEditor={this.openEditor}
                isSideBarOpen={this.state.isSideBarOpen}
                initiateOrSaveRequest={this.initiateOrSaveRequest}
                initiateAudio={this.initiateAudio}
                showPreview={this.showPreview}
                hidePreview={this.hidePreview}
                allPlaceholders={this.state.allPlaceholders}
                addAllPlaceholders={this.addAllPlaceholders}
                preview_panel_display={
                  this.state.slides[this.state.currentSlide]
                    .preview_panel_display
                }
                preview_audio_processing={
                  this.state.slides[this.state.currentSlide]
                    .preview_audio_processing
                }
                readFile={this.readFile}
                preview_audio_url={
                  this.state.slides[this.state.currentSlide].preview_audio_url
                }
                preview_bg_thumbnail_url={
                  this.state.slides[this.state.currentSlide]
                    .custom_bg_thumbnail_url
                }
                preview_bg_url={
                  this.state.slides[this.state.currentSlide].custom_bg_url
                }
                preview_bg_color={
                  this.state.slides[this.state.currentSlide].bg_color
                }
                avatarEnabled={
                  this.state.slides[this.state.currentSlide]["avatarEnabled"]
                }
                setAvatarEnabled={this.setAvatarEnabled}
                currentAvatarBackgroundColor={
                  this.state.slides.length !== 0 &&
                  this.state.slides[this.state.currentSlide][
                    "backgroundColorAvatar"
                  ]
                }
                updateAvatarBGColor={this.updateAvatarBGColor}
                slidesList= {this.state.slides}
                idToBackUrlMapping= {this.idToBackUrlMapping}
                deleteSlideWithId= { this.deleteSlideWithId}
                currentSlide= {this.state.currentSlide}
                changeCurrentSlide= { this.changeCurrentSlide}
                addNewSlide= { this.addNewSlide}
                initiateSlidesFromPpt= {this.initiateSlidesFromPpt}
              />
            </div>
          )}
            </Grid>
</Grid>
         
        </div>
        <div>
          <Snackbar
            open={Boolean(this.state.snackBarMessage)}
            autoHideDuration={6000}
            onClose={this.handleSnackBarClose}
          >
            <Alert
              onClose={this.handleSnackBarClose}
              severity={this.state.snackBarVariant}
              sx={{ width: "100%" }}
            >
              {this.state.snackBarMessage}
            </Alert>
          </Snackbar>
        </div>
        <div>
          <Modal
            open={Boolean(this.state.isModalOpen)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={boxModalStyle}
              className="d-flex flex-column align-items-center"
            >
              <p style={{color:'#00c7e5'}} className="h2 mb-0 fw-bold">
                {this.state.requestInfo &&
                  (this.state.requestInfo.type === "template"
                    ? this.state.requestInfo.status === "approval_pending"
                      ? "Your template has been sent for approval."
                      : "Your template is created successfully."
                    : this.state.requestInfo.status === "approval_pending"
                    ? "Your video request has been sent for approval."
                    : `Your request is ${
                        this.state.requestType === "initiate"
                          ? "created"
                          : "saved"
                      } successfully.`)}
              </p>
              <p>
                {(this.state.requestInfo &&
                  this.state.requestInfo.type === "template" &&
                  this.state.requestInfo.status !== "approval_pending") ||
                  "Homepage will show you the current status of this request."}
                You can leave this page now.
              </p>
              <button
               style={{
                backgroundColor: "#D7D7D7",
                borderRadius: "10px",
                width: "80%",
                padding: "10px",
                marginTop: "15px",
                textAlign: "center",
                cursor: "pointer",
                color: "black",
                padding: "12px 0px",
                backgroundColor: "#d9d9d9",
              }}
                className="btn"
                onClick={() => this.props.history.push("/app")}
              >
                Check status
              </button>
            </div>
          </Modal>
          <Modal
            open={Boolean(this.state.globalConfigChange)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={boxModalStyle}
              className="d-flex flex-column align-items-center"
            >
              <p className="h3 fw-bold">Please confirm the change.</p>
              <p className="">
                Your change in{" "}
                {this.state.globalConfigChange &&
                  this.state.globalConfigChange.name}{" "}
                will be applied globally.
              </p>
              <GridContainer className="my-1">
                <GridItem md={6}>
                  <button
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => {
                      this.state.globalConfigChange.callback();
                      this.setState({ globalConfigChange: null });
                    }}
                  >
                    Confirm
                  </button>
                </GridItem>
                <GridItem md={6}>
                  <button
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => {
                      this.setState({ globalConfigChange: null });
                    }}
                  >
                    Discard
                  </button>
                </GridItem>
              </GridContainer>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default Editor;
