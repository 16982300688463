import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import axios from "axios";
import { getToken } from "common/Authentication";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Button } from "reactstrap";
import PlayButton from "container/CommonSections/PlayButton";

const useStyles = makeStyles(imagesStyles);

function ListElement(props) {
  const classes = useStyles();
  const [audioData, setAudioData] = useState([]);
  const [nextPageStart, setNextPageStart] = useState(0);

  const fetchData = (num) => {
    axios({
      method: "GET",
      url: "/t2v/report/fetch",
      headers: {
        Authorization: "Bearer " + getToken(),
        RequestTime: new Date(),
      },
      params: {
        limit: 10,
        type: "t2a",
        start: num,
      },
    }).then((res) => {
      console.log("Res: ", res.data.data.synthesis_requests);
      setNextPageStart((prevPostNumber) => prevPostNumber + 10);
      setAudioData((prevArr) => prevArr.concat(res.data.data.synthesis_requests));
    });
  };

  useEffect(() => {
    fetchData(nextPageStart);
  }, []);

  const parseDate = (request_id) => {
    try {
     return format(
        new Date(request_id),
        "dd:MM:yyyy"
      )
    } catch (error) {
      return 'Invalid date';
    }    
  };

  const parseTime = (request_id) => {
    try {
     return format(
      new Date(request_id),
      "hh:mm a"
    )
    } catch (error) {
      return '';
    }
  };

  return (
      <div
        style={{ display: "flex", height: "90%", flexDirection: "column" }}
      >
        <div
          style={{
            marginTop: "20px",
            marginLeft: "40px",
            color: "grey",
            fontSize: "20px",
            fontWeight: "900",
          }}
        >
          Generated Audios
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowX: "scroll",
          }}
        >
          <div style={{ paddingTop: "2vh" }}>
            <InfiniteScroll
              dataLength={audioData.length}
              next={() => {
                fetchData(nextPageStart);
              }}
              hasMore={true}
              loader={
                <Loader
                  style={{ alignItems: "center", marginLeft: "140px" }}
                  type="ThreeDots"
                  color="#00BFFF"
                  height={54}
                  width={54}
                />
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",

                  minWidth: "100vh",
                }}
              >
                {audioData.map((req, idx) => {
                  return (
                    <>
                      <div style={{ marginTop: "15px" }} key={idx}>
                        <div
                          style={{
                            marginLeft: "20px",
                            display: "flex",
                            flexDirection: "column",
                            width: "95%",
                            minWidth: "300px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#e4e4e4",
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "16px",
                              lineHeight: "19px",
                              borderRadius: "8px",
                              marginLeft: "10px",

                              width: "100%",
                              display: "flex",
                              borderRadius: "10px",
                              flexDirection: "column",
                            }}
                          >
                            <GridContainer style={{ padding: "15px" }}>
                              <GridItem
                                sm={5}
                                md={5}
                                style={{ display: "flex" }}
                              >
                                <div
                                  style={{ marginTop: "10px", width: "100%" }}
                                >
                                  <div
                                    style={{
                                      fontFamily: "Rubik",
                                      fontStyle: "normal",
                                      fontWeight: "normal",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {req.title}
                                  </div>
                                  <br />
                                  <div style={{marginBottom:"3px",color:"grey"}}>
                                    {" "}
                                    { parseDate(req.create_time)}{" "}
                                    {parseTime(req.create_time)}
                                  </div>

                                  <p
                                    style={
                                      req.response_status === "success"
                                        ? {
                                            color: "white",
                                            border: "none",
                                            background: "none",
                                            borderRadius: "8px",
                                            background: "#499037",
                                            display: "inline-block",
                                            paddingLeft:"6px",
                                            paddingRight:"6px"
                                          }
                                        : req.response_status === "pending"
                                        ? {
                                          background: "#00c7e5",
                                            border: "none",
                                            borderRadius: "8px",
                                            color: "white",
                                            display: "inline-block",
                                            paddingLeft:"6px",
                                            paddingRight:"6px"
                                          }
                                        : req.response_status === "SAVED"
                                        ? {
                                          background: "#68BC00",
                                            border: "none",
                                            borderRadius: "8px",
                                            color: "white",
                                            display: "inline-block",
                                            paddingLeft:"6px",
                                            paddingRight:"6px"
                                          }
                                        : {
                                          background: "red",
                                            border: "none",
                                            borderRadius: "8px",
                                            color: "white",
                                            display: "inline-block",
                                            paddingLeft:"6px",
                                            paddingRight:"6px"
                                          }
                                    }
                                  >
                                    {req.response_status}
                                  </p>
                                </div>
                              </GridItem>
                              <GridItem md={7} sm={7}>
                                <GridContainer
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: "15px",
                                  }}
                                >
                                  <GridItem sm={12} mmd={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "start",
                                        width: "100%",

                                        flexWrap: "wrap",
                                        height: "100%",
                                      }}
                                    >
                                      {/* <br /> */}
                                      {req.response_status === "success" ? (
                                        <>
                                          <PlayButton
                                            style={{
                                              width: "100%",
                                            }}
                                            videoUrl={req.response_url}
                                          >
                                            Play
                                          </PlayButton>
                                          <Button
                                            onClick={() => {
                                              fetch(req.response_url, {
                                                method: "GET",
                                              })
                                                .then((resp) => resp.blob())
                                                .then((blob) => {
                                                  let a =
                                                    document.createElement(
                                                      "a"
                                                    );
                                                  a.href =
                                                    window.URL.createObjectURL(
                                                      blob
                                                    );
                                                  a.download =
                                                    req.title +
                                                    "." +
                                                    req.response_url
                                                      .split(".")
                                                      .pop();
                                                  console.log(a);
                                                  a.click();
                                                });
                                            }}
                                            style={{
                                              minWidth: "0px",
                                              justifyItems: "right",
                                              marginRight: "10px",
                                              background: "#2196F3",
                                              color: "#ffffff",
                                              border: "none",
                                              padding: "7px 15px",
                                              borderRadius: "12px",
                                              fontSize: "14px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                            }}
                                          >
                                            DOWNLOAD
                                            <div>
                                            <svg style={{marginLeft:"10px",fill:"white"}} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/></g></svg>
                                            </div>
                                          </Button>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </GridItem>

                                  <GridItem sm={12} md={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        // alignItems: "center",
                                        width: "100%",
                                        justifyContent: "flex-end",
                                        alignItems: "end",
                                        flexWrap: "wrap",
                                        height: "100%",
                                      }}
                                    >
                                      <Link to={`/app/editor/${req.id}`}>
                                          <Button
                                          style={{
                                              minWidth: "0px",
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              background: "#2fbdb8",
                                              color: "#ffffff",
                                              border: "none",
                                              padding: "10px 20px",
                                              borderRadius: "5px",
                                              fontSize: "14px",
                                              cursor: "pointer",
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                          }}
                                          >
                                          Edit in Console
                                          <svg style={{marginLeft:"10px",fill:"white"}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.41 5.8L17.2 4.59c-.78-.78-2.05-.78-2.83 0l-2.68 2.68L3 15.96V20h4.04l8.74-8.74 2.63-2.63c.79-.78.79-2.05 0-2.83zM6.21 18H5v-1.21l8.66-8.66 1.21 1.21L6.21 18zM11 20l4-4h6v4H11z"/></svg>

                                          </Button>
                                      </Link>
                                    </div>
                                  </GridItem>
                                </GridContainer>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
  );
}
export default ListElement;
