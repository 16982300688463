async function getCredits() {
    let data;
    let token = localStorage.getItem("sso_token");
    await fetch('/billing/credits/fetch', {
        method: 'GET',
        headers:
        {
            Authorization: `Bearer ${token}`,
            RequestTime: new Date()
        }
    })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
            else {
                throw "temp"
            }
        }
        )
        .then(res => {
            data = res.data;
            // console.log(data);
        })
        .catch(res => {
            data = null;
        })

    return {
        credits: data.available_credits,
        lowCredits: data.available_credits <= 25
    };
}

export async function getPlanDetails() {
    let data = {}
    let token = localStorage.getItem("sso_token");
    await fetch('/billing/plan-details', {
        method: 'GET',
        headers:
        {
            Authorization: `Bearer ${token}`,
            RequestTime: new Date()
        }
    })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
            else {
                throw "temp"
            }
        }
        )
        .then(res => {
            data.planType = res.data.type;
        })
        .catch(res => {
            data = null;
        })

        if (data.planType && data.planType !== 'offline') {
            await getCredits().then(resp => {
                data.credits = resp.credits;
                data.lowCredits = resp.lowCredits;
            }).catch(err => null)
        }

    return data;
}
