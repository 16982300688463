/* eslint-disable */
import React, { Component } from "react";
import classNames from "classnames";
import EditorHeader from "./editorHeader";
import VoicePanel from "./VoicePanel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { getToken } from "common/Authentication";
import TextEditor from "./TextEditor";
import Grid from "@material-ui/core/Grid";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class AudioEditor extends Component {
    constructor(props) {
        super(props);
        this.timeouts = {
            preview_t2a: [5, 5, 10, 20, 30, 35, 50, 75, 110, 170, 250, 320],
        };
        this.snackBarVariantsMap = {
            error: "error",
            warning: "warning",
            info: "info",
            success: "success",
        };
        this.state = {
            title: "Untitled",
            voiceId: null,
            voiceSpeed: 1,
            voiceTemplates: [],
            snackBarMessage: null,
            snackBarVariant: "success",
            dataLoadingState: null,
            requestId: null,
            text: "",
            processing: false,
            previewAudioUrl: "",
        };
        this.idToBackUrlMapping = {};
    }

    componentDidMount() {
        this.props.match.params?.requestId &&
          this.prefilledDetails(this.props.match.params.requestId);
        this.initiateLoadingOfData();
        this.setState({ requestId: this.props.match.params.requestId });
    }

    prefilledDetails = async (requestId) => {
        console.log("Fetching Request Data", requestId=requestId);
        try{
            const data=await axios.get(`/t2v/synthesis/fetch_request?request_id=${requestId}`,{
                headers: {
                   Authorization: "Bearer " + getToken(),
                   RequestTime: new Date()
                }
            });
            this.setState({
                title:data.data.data?.title,
                voiceId:data.data.data?.configs.voice?.id,
                text:data.data.data?.synthesis_data?.text[0],
                processing: false,
                previewAudioUrl: "",
            });
        }catch(err){
            window.location.href="/app/audio"
        }
    };

    audioStatusQuery = (request_id, retry_id) => {
        axios({
            method: "GET",
            url: "/t2v/synthesis/status",
            headers: {
                Authorization: "Bearer " + getToken(),
                RequestTime: new Date(),
            },
            params: {
                request_id: request_id,
            },
        })
        .then((res) => {
            if (res) {
                if (res.data.data.response_status === "pending") {
                    throw "temp";
                } else {
                    this.setState({
                        previewAudioUrl: res.data.data.response_url,
                        processing: false,
                    });
                }
            }
        })
        .catch((e) => {
            if (retry_id < this.timeouts["preview_t2a"].length) {
                setTimeout(
                    () => this.audioStatusQuery(request_id, retry_id + 1),
                    this.timeouts["preview_t2a"][retry_id] * 1000
                );
            } else {
                this.setState({
                    processing: false,
                });
            }
        });
    };
    
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.voiceId !== this.state.voiceId ||
            this.state.voiceSpeed !== prevState.voiceSpeed
        ) {
            this.setState({previewAudioUrl: ""});
        }
    };

    setVoiceTemplates = (voices) => {
        this.setState({
            voiceTemplates: voices,
            dataLoadingState: "success"
        });
        this.setState((prev_state) => {
            if (
                voices.length !== 0 &&
                voices.map((x) => x.id).indexOf(prev_state.voiceId) === -1
            ) {
                return { ...prev_state, voiceId: voices[0].id };
            } else {
                return prev_state;
            }
        });
    };

    initiateLoadingOfData = async () => {
        console.log("Fetching Voice Data");
        try {
            const data = await axios.get(`/filter-service-config?service=t2v&config_type=ENTITY-VOICE`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    RequestTime: new Date(),
                },
            });
            this.setVoiceTemplates(data.data.data);
        } catch (error) {
            console.log("Error ocurred while fetching Voice Data", error=error);
            this.setState(
            { dataLoadingState: "error" },
            this.openSnackBar(
              `Error Loading Data ${error}`,
              this.snackBarVariantsMap.error
            )
          );
        }
    };

    openSnackBar = (message, variant) => {
        this.setState({
            snackBarMessage: message,
            snackBarVariant: variant,
            isSnackBarOpen: true,
        });
    };

    handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ snackBarMessage: null });
    };

    initiateOrSaveRequest = () => {
        if (this.state.processing) {
            return;
        }
        if (!this.state.text) {
            this.openSnackBar(
                "You need to enter text",
                this.snackBarVariantsMap.info
            );
            return;
        }
    
        this.setState({ processing: true });
        const slideDataToUpload = [{
            text: this.state.text
        }];
        axios({
            method: "POST",
            url: "/t2v/synthesis/initiatev2",
            headers: {
                Authorization: "Bearer " + getToken(),
            },
            data: {
                title: this.state.title,
                type: "t2a",
                slides: slideDataToUpload,
                voice_configs: {
                    id: this.state.voiceId,
                    voice_speed: this.state.voiceSpeed,
                },
                request_id: this.state.requestId,
            },
        })
        .then((res) => {
            if (res.status === 200) {
                let retry_id = 0;
                setTimeout(
                    () => this.audioStatusQuery(res.data.data.request_id, retry_id + 1),
                    this.timeouts["preview_t2a"][retry_id] * 1000
                );
            } else {
                this.setState({ processing: false });
                throw "temp";
            }
        })
        .catch((error) => {
            this.setState({ processing: false });
            this.openSnackBar(error.message, this.snackBarVariantsMap.error);
        });
    };

    setText = (text) => {
        this.setState({text: text, previewAudioUrl: ""});
    };

    render = () => {
        return (
            <div
                style={{height: "100vh"}}
                className={classNames("_font", "bg-light", "w-100")}
            >
                <EditorHeader title={this.state.title} setTitle={(title)=>{this.setState({title: title})}} />
                <div className="container d-flex align-items-center" style={{height: "90%"}} >
                    <Grid container style={{height: "fit-content"}}>
                        <Grid item xs={6}>
                            <VoicePanel
                                voiceTemplates={this.state.voiceTemplates}
                                voiceId={this.state.voiceId}
                                changeVoiceId={(id) => this.setState({ voiceId: id })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextEditor
                                title={this.state.title}
                                editorText={this.state.text}
                                setEditorText={this.setText}
                                previewAudioProcessing={this.state.processing}
                                previewAudioUrl={this.state.previewAudioUrl}
                                initiateAudio={this.initiateOrSaveRequest}
                                voiceSpeed={this.state.voiceSpeed}
                                changeVoiceSpeed={this.changeVoiceSpeed}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    open={Boolean(this.state.snackBarMessage)}
                    autoHideDuration={6000}
                    onClose={this.handleSnackBarClose}
                >
                    <Alert
                        onClose={this.handleSnackBarClose}
                        severity={this.state.snackBarVariant}
                        sx={{ width: "100%" }}
                    >
                        {this.state.snackBarMessage}
                    </Alert>
                </Snackbar>
            </div>
        )
    };
}
export default AudioEditor;
