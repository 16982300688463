/* eslint-disable */
import React, { Component } from "react";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import ShareButton from "../../CommonSections/ShareButton";
import PlayButton from "../../CommonSections/PlayButton";
import download from "../download.png";
import Button from "@mui/material/Button";

import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
class ListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: [],
      rows: [],
    };
    this.prepareTableData = this.prepareTableData.bind(this);
    this.addRow = this.addRow.bind(this);
  }
  componentDidMount() {
    this.addRow();
  }
  addRow = () => {
    this.setState({
      ...this.state,
      rows: [...this.state.rows, {}],
      st: true,
    });
  };
  prepareTableData() {}
  render() {
    let data = this.prepareTableData();
    console.log("Props: ", this.props, "\nArr: ", this.state.arr);

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          {this.props.arr.map((req, idx) =>
            this.state.rows.map((row, id) => {
              // return (
              //   <>

              //   </>)
              return (
                <>
                  <div key={idx}>
                    <div
                      style={{
                        marginLeft: "20px",
                        display: "flex",
                        flexDirection: "column",
                        minWidth:'300px'
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f2f2f2",
                          fontFamily: "Rubik",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "19px",
                          borderRadius: "8px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <GridContainer style={{ padding: "15px" }}>
                          <GridItem sm={12} mmd={12}>
                            {req.thumbnail &&
                            req.thumbnail.split(":")[0] == "color" ? (
                              <div
                                style={{
                                  backgroundColor: req.thumbnail
                                    .split(":")
                                    .slice(1)
                                    .join(":"),

                                  width: "100%",
                                  height: "156px",
                                }}
                              ></div>
                            ) : (
                              <div
                                style={{
                                  width: "256px",
                                  height: "256px",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundImage: `url(${
                                    req.thumbnail &&
                                    req.thumbnail.split(":")[0] == "url"
                                      ? req.thumbnail
                                          .split(":")
                                          .slice(1)
                                          .join(":")
                                      : "https://images.unsplash.com/photo-1500530855697-b586d89ba3ee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
                                  })`,
                                }}
                              />
                            )}
                          </GridItem>
                          <GridItem sm={12} md={12} style={{ display: "flex" }}>
                            <div style={{ marginTop: "10px" ,width:"100%"}}>
                              {req.title}
                              <br />
                             <div style={{display:"inline"}}> {req.create_time}</div>
                            
                              <p
                                style={
                                  req.response_status === "success"
                                    ? { color: "#499037" }
                                    : req.response_status === "pending"
                                    ? { color: "#00c7e5" }
                                    : req.response_status === "SAVED"
                                    ? { color: "#68BC00" }
                                    : { color: "#D03D3D" }
                                }
                              >
                                {req.response_status}
                              </p>
                            </div>
                          </GridItem>
                          <GridItem md={12} sm={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                width: "100%",
                                flexWrap: "wrap",
                              }}
                            >
                              {/* <br /> */}
                              {req.response_status === "success" ? (
                                <>
                                  <PlayButton videoUrl={req.response_url} />
                                  <Button
                                    onClick={() => {
                                      fetch(req.response_url, { method: "GET" })
                                        .then((resp) => resp.blob())
                                        .then((blob) => {
                                          let a = document.createElement("a");
                                          a.href =
                                            window.URL.createObjectURL(blob);
                                          a.download =
                                            req.title +
                                            "." +
                                            req.response_url.split(".").pop();
                                          console.log(a);
                                          a.click();
                                        });
                                    }}
                                    style={{
                                      minWidth: "0px",
                                      display: "inline-block",
                                    }}
                                  >
                                    <div>
                                      <img
                                        src={download}
                                        alt="download button"
                                      />
                                    </div>
                                  </Button>
                                  <ShareButton
                                    downloadLink={req.response_url}
                                    shareButtonRequestId={req.id}
                                    updateShareButtonRequestId={
                                      this.props.updateShareButtonRequestId
                                    }
                                    style={{
                                      minWidth: "0px",
                                      display: "inline-block",
                                    }}
                                  />
                                  <Link to={`/app/editor/${req.id}`}>
                                    <Button style={{ minWidth: "0px" }}>
                                      <ArrowForwardIcon style={{ color: "#00c7e5" }} />
                                    </Button>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                              {req.response_status !== "success" ? (
                                <>
                                  <Link to={`/app/editor/${req.id}`}>
                                    <Button style={{ minWidth: "0px" }}>
                                      <ArrowForwardIcon style={{ color: "#00c7e5" }} />
                                    </Button>
                                  </Link>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          )}
        </div>
      </>
    );
  }
}

export default ListSection;
