import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle'

export default function AlertComponent(props) {
  const [show, setShow] = useState(true)
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Collapse in={show}>
          <Alert
            severity={props.type}
            variant={props.variant}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShow(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {/* <AlertTitle>{props.title}</AlertTitle> */}
            {props.title} {props.text} {props.redirectPath && props.redirectText ? <strong style={{ cursor: 'pointer' }} onClick={() => window.location.href = props.redirectPath}>{props.redirectText}</strong> : null}
          </Alert>
        </Collapse>
      </Box>
    </>
  )
}
