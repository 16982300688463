/* eslint-disable */
import React, { Component } from "react";
import "./Editor.css";

class Editor extends Component {
  constructor(props) {
    super(props);
    this.editorSelection = null;
    this.insertSilence = this.insertSilence.bind(this);
    this.showFile = this.showFile.bind(this);
    this.detectChange = this.detectChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.duration_update = this.duration_update.bind(this);
    this.insertPlaceHolder = this.insertPlaceHolder.bind(this);
  }
  componentDidUpdate() {
    var editable = document.getElementById("text-editor");
    if (editable.innerHTML !== this.props.text)
      editable.innerHTML = this.props.text;
  }

  duration_update(e) {
    this.silence_duration = e.target.value;
  }

  showFile() {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var preview = document.getElementById("text-editor");
      var file = document.getElementById("filetext").files[0];
      var reader = new FileReader();
      reader.onload = function (event) {
        preview.innerHTML = event.target.result;
      };
      reader.readAsText(file);
    }
  }
  insertSilence(silence_duration) {
    if (silence_duration) {
      var duration = silence_duration;
      var range, expandedSelRange, node;
      var html =
        "<span contenteditable='false' style='background:yellow; color:black' id='silence'><u>" +
        duration +
        "</u></span>";
      if (this.editorSelection) {
        range = this.editorSelection;
        expandedSelRange = range.cloneRange();
        range.collapse(false);
        var el = document.createElement("span");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
      }
    }
    var editable = document.getElementById("text-editor");
    this.props.setText(editable.innerHTML);
    //  this.pasteHtmlAtCaret("<button contenteditable='false'>&&</button>")
    // element.insertAdjacentHTML("beforeend","<button contenteditable='false'>##</button>");
  }
  inputChange() {
    var editable = document.getElementById("text-editor");
    this.props.setText(editable.innerHTML);
  }
  detectChange() {
    // var element= document.getElementById("text-editor")
    var editable = document.getElementById("text-editor"),
      selection,
      range;
    if (editable) {
      var isOrContainsAnchor = false,
        isOrContainsFocus = false,
        sel = window.getSelection(),
        parentAnchor = sel.anchorNode,
        parentFocus = sel.focusNode;

      while (parentAnchor && parentAnchor != document.documentElement) {
        if (parentAnchor == editable) {
          isOrContainsAnchor = true;
        }
        parentAnchor = parentAnchor.parentNode;
      }

      while (parentFocus && parentFocus != document.documentElement) {
        if (parentFocus == editable) {
          isOrContainsFocus = true;
        }
        parentFocus = parentFocus.parentNode;
      }

      if (!isOrContainsAnchor || !isOrContainsFocus) {
        return;
      }
      selection = window.getSelection();

      // Get range (standards)
      if (selection.getRangeAt !== undefined) {
        range = selection.getRangeAt(0);

        // Get range (Safari 2)
      } else if (
        document.createRange &&
        selection.anchorNode &&
        selection.anchorOffset &&
        selection.focusNode &&
        selection.focusOffset
      ) {
        range = document.createRange();
        range.setStart(selection.anchorNode, selection.anchorOffset);
        range.setEnd(selection.focusNode, selection.focusOffset);
      } else {
        // Failure here, not handled by the rest of the script.
        // Probably IE or some older browser
      }
      this.editorSelection = range;
      this.props.setText(editable.innerHTML);
      console.log(editable.innerHTML);
    }
  }
  insertPlaceHolder(placeholderValue) {
    var range, expandedSelRange, node;
    var html =
      "<span contenteditable='false' style='background:purple; color:white' id='placeholder'>" +
      placeholderValue +
      "</span>";
    if (this.editorSelection) {
      range = this.editorSelection;
      expandedSelRange = range.cloneRange();
      range.collapse(false);
      var el = document.createElement("span");
      el.innerHTML = html;
      var frag = document.createDocumentFragment(),
        node,
        lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);
    }
    var editable = document.getElementById("text-editor");
    this.props.setText(editable.innerHTML);
  }
  componentDidMount() {
    var editable = document.getElementById("text-editor");
    if (editable.innerHTML !== this.props.text)
      editable.innerHTML = this.props.text;
    this.detectChange;
  }
  
  handlePaste = (e) => {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, text);
  };

  render() {
    return (
      <div
        className="flex-grow-1 editor panel-entity-container"
        style={{display: "flex", borderRadius: "10px", height: this.props.isSideBarOpen !== null ? "270px" :"100px" }}
      >
        <div
          id="text-editor"
          onClick={this.detectChange}
          onPaste={this.handlePaste}
          onKeyUp={this.detectChange}
          onInput={this.detectChange}
          contentEditable={true}
          data-placeholder="Enter Text Here....."
          style={{
            color:'white',
            borderRadius: "10px",
            padding: "1px",
            placeholderColor :'grey',
            backgroundColor: "transparent",
            wordBreak:"break-word"
          }}
        ></div>
      </div>
    );
  }
}
export default Editor;
