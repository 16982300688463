const Pexels_default_img =[
    {
      id: 11513081,
      image:"https://images.pexels.com/photos/11513081/pexels-photo-11513081.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Brown Leaf Trees",
    },
    {
      id: 14177817,
      image:"https://images.pexels.com/photos/14177817/pexels-photo-14177817.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Snow Capped Mountain under a Blue Sky",
    },
    {
      id: 14177727,
      image:"https://images.pexels.com/photos/14177727/pexels-photo-14177727.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Cloudy Sky over a Snow Capped Mountain",
    },
    {
      id: 14089016,
      image:"https://images.pexels.com/photos/14089016/pexels-photo-14089016.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Poplar trees in the autumn period of the year.",
    },
    {
      id: 14089014,
      image:"https://images.pexels.com/photos/14089014/pexels-photo-14089014.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Poplar trees in the autumn period of the year.",
    },
    {
      id: 11504459,
      image:"https://images.pexels.com/photos/11504459/pexels-photo-11504459.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Rock Formations with Cloudy Sky in Background",
    },
    {
      id: 12824640,
      image:"https://images.pexels.com/photos/12824640/pexels-photo-12824640.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "White Textile in Close Up Image",
    },
    {
      id: 11454142,
      image: "https://images.pexels.com/photos/11454142/pexels-photo-11454142.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Grayscale Photo of Metal Fence",
    },
    {
      id: 2559941,
      image:"https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Brown Mountains",
    },
    {
      id: 36717,
      image:"https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Silhouette of Tree Near Body of Water during Golden Hour",
    },
    {
      id: 15286,
      image:"https://images.pexels.com/photos/15286/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Person Walking Between Green Forest Trees",
    },
    {
      id: 167699,
      image:"https://images.pexels.com/photos/167699/pexels-photo-167699.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Green Pine Trees Covered With Fogs Under White Sky during Daytime",
    },
    {
      id: 624015,
      image:"https://images.pexels.com/photos/624015/pexels-photo-624015.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Aurora Borealis",
    },
    {
      id: 325185,
      image:"https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "View of Cityscape",
    },
    {
      id: 268533,
      image:"https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Green Tree",
    },
    {
      id: 1287145,
      image:"https://images.pexels.com/photos/1287145/pexels-photo-1287145.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Snowy Mountain",
    },
    {
      id: 1072179,
      image:"https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Green Leaves",
    },
    {
      id: 2387793,
      image:"https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Black Sand Dunes",
    },
    {
      id: 459301,
      image:"https://images.pexels.com/photos/459301/pexels-photo-459301.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Purple Leaf",
    },
    {
      id: 2150,
      image:"https://images.pexels.com/photos/2150/sky-space-dark-galaxy.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Gray and Black Galaxy Wallpaper",
    },
    {
      id: 3454270,
      image:"https://images.pexels.com/photos/3454270/pexels-photo-3454270.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Black and White Photography of Trees on a Foggy Day",
    },
    {
      id: 1114690,
      image:"https://images.pexels.com/photos/1114690/pexels-photo-1114690.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Photo of Lightning",
    },
    {
      id: 2531709,
      image:"https://images.pexels.com/photos/2531709/pexels-photo-2531709.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Photo of Thunderstorm",
    },
    {
      id: 2387873,
      image:"https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Three Men Standing Near Waterfalls",
    },
    {
      id: 924824,
      image:"https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Time Lapse Photo of Stars on Night",
    },
    {
      id: 1292115,
      image:"https://images.pexels.com/photos/1292115/pexels-photo-1292115.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      alt: "Aerial Photography of Pine Trees",
    }
]
export {Pexels_default_img}