import React, { useState } from "react";
import Home from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import Report from "@material-ui/icons/DescriptionOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import { withRouter } from "react-router-dom";
import VerticalNavBarListItem from "./VerticalNavBarListItem";
import "./VerticalNavBar.css";
import { logout } from "common/Authentication";
import PowerSettingNewIcon from "@mui/icons-material/PowerSettingsNew";

function VerticalNav() {
  const [open, setOpen] = useState(false);
  const linkAndItsComponentList = [
    { href: "/app", text: "Home", currentComponent: Home },
    {
      href: "/app/editor",
      text: "Create New Request",
      currentComponent: Add,
    },
    { href: "/app/report", text: "Report", currentComponent: Report },
    {
      href: "/app/settings",
      text: "Settings",
      currentComponent: SettingsIcon,
    },
    {
      onClick: () => {
        logout();
        window.location.href = "/";
      },
      text: "Logout",
      currentComponent: PowerSettingNewIcon,
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        color: "white",
        fontWeight: "bold",
        display: "flex",
      }}
    >
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        style={{ background: "black", width: open ? "200px" : "34px" }}
        className="animateNavBar"
      >
        {linkAndItsComponentList.map((ele, index) => (
          <VerticalNavBarListItem
            key={index}
            redirectLink={ele.link}
            onClick={() => {
              ele.onClick && ele.onClick();
              ele.href && (window.location.href = ele.href);
            }}
            // style={{ cursor: 'pointer' }}
            text={ele.text}
            currentComponent={ele.currentComponent}
            isOpen={open}
          />
        ))}
      </div>
    </div>
  );
}
export default withRouter(VerticalNav);
