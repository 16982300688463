import React from 'react'
import "./LoginPage.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import MuiAlert from "@mui/material/Alert";
import Snackbar from '@mui/material/Snackbar';
import {setToken,getToken} from "common/Authentication";

import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import GoogleLogin from './GoogleLogin';
import classNames from 'classnames';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function LoginPage3(props) {
    const [loginData, setLoginData] =  React.useState({"email": "", "password": ""});
    const [modal, setModal] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false)

    const message = "Failed to login. Please check the credentials.";

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    const submitForm = () =>{
        let jsonRequest = JSON.stringify({email: loginData.email, password: loginData.password})
        let loginURL = process.env.REACT_APP_BASE_URL+"/auth/login_email/"
        console.log(jsonRequest);
        fetch(loginURL, {method:'POST', headers: {'Content-Type': 'application/json'}, body:jsonRequest}).then(res=>{
            if(res.status === 200){
                return res.json();
            } else {
              throw res.json();
            }
          })
        .then((res) => {
            setToken(res.data.sso_token)
            props.setLoggedin(true)
            let token  = getToken()
            console.log(token)
            
        }).catch(e=>{
            console.log(e);
            setOpen(true);
        })
    }

    const openSnackBar = () => {
        setOpen(true);
    }

    return (
        <>
            <div className='loginPage'>
                <div className='align-self-center'>
                <h1  style={{marginTop: "24vh", marginBottom: "4vh", height: "4vh" ,display: "flex", justifyContent: "center", alignItems: "center"}} >Welcome</h1>
                
                <h5  style={{marginTop: "2vh", marginBottom: "4vh", height: "4vh" ,display: "flex", justifyContent: "center", alignItems: "center"}} > Login to AI video Creator</h5>
              
                <GridContainer direction="centre" className="w-100 m-0" style={{height: "80%",display: "flex", justifyContent: "center", alignItems: "center"}}>
                  
                    
                    <GridItem md={4} className='align-self-center'>
                        <form>
                            <CustomInput
                                id="email"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    placeholder: "Email",
                                    type: "email",
                                    onChange: (e)=>{
                                        setLoginData({...loginData, "email": e.target.value})
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <CustomInput
                                id="password"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    placeholder: "Password",
                                    type: showPassword ? "text" : "password",
                                    onChange: (e)=>{
                                        setLoginData({...loginData, "password": e.target.value})
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOpenIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end" onClick={()=>setShowPassword(!showPassword)} style={{cursor:"pointer"}}>
                                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <div  style={{display: "flex", justifyContent: "center", alignItems: "center"}} className='login_buttons'>
                                <div className={classNames('register_toggle')}>
                                <div className='login_submit' onClick ={submitForm}>
                                    Login
                                </div>
                                </div>
                            </div>
                        </form>
                    </GridItem>
                </GridContainer>
                </div>
            </div>
            
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default LoginPage3
