const mappedDetectLanguage=new Map([
    ["en","english"],["ml","malayalam"],
    ["hi","hindi"],["pa","punjabi"],
    ["mr","marathi"],["bn","bengali"],
    ["ta","tamil"],["gu","gujarati"],
    ["te","telugu"],["kn","kannada"],
    ["aa","afar"],["ab","abkhazian"],
    ["zu","zulu"],["bo","tibetan"],
    ["de","german"],["dv","dhivehi"],
    ["es","spanish"],["egy","egyptian"],
    ["fr","french"],["ga","irish"],
    ["ja","japanese"],["ga","irish"]
]);
export {mappedDetectLanguage};