/* eslint-disable */
import React from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportIcon from "@mui/icons-material/Report";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function Template(props) {
  const history = useHistory();
  const {
    title,
    create_time,
    configs,
    template_status,
    id: templateId,
  } = props.templateData;
  console.log(props.templateData);
  return (
    <div style={{ marginLeft:'18px' }} className="col-md-13 py-2 px-0">
      <Card
        sx={{
          
          cursor: template_status === "approved" ? "pointer" : null,
        }}
        onClick={() => {
          template_status === "approved" &&
            history.push("/app/template/" + templateId);
        }}
      >
        <Tooltip title={"Status: " + template_status}>
          <span style={{marginLeft:"10px",marginTop:'20px'}}>
            {template_status === "approved" ? (
              <CheckCircleIcon sx={{ color: "springgreen" }} />
            ) : template_status === "approval_denied" ? (
              <ReportIcon sx={{ color: "red" }} />
            ) : (
              <InfoIcon sx={{ color: "yellow" }} />
            )}
          </span>
        </Tooltip>
        {configs.background[0].thumbnail_url ? (
          <CardMedia
            component="img"
            height="150"
            image={
              configs.background[0].thumbnail_url ||
              "https://lumiere-a.akamaihd.net/v1/images/sa_pixar_virtualbg_coco_16x9_9ccd7110.jpeg"
            }
            alt="Slide Background"
          />
        ) : (
          <div
            style={{
              backgroundColor: configs.background[0].color,
              width: "20vw",
              height: "11vw",
            }}
          ></div>
        )}
        <CardContent className="m-2 p-0">
          <GridContainer>
            <GridItem xs={8}>
              <Typography sx={{ fontSize: 14 }} component="div">
                {title}
              </Typography>
              <Typography
                sx={{ fontSize: 12 }}
                color="text.secondary"
                gutterBottom
              >
                {create_time}
              </Typography>
            </GridItem>
            <GridItem xs={4} className="text-right">
              <IconButton
                aria-label="share"
                onClick={(e) => {
                  props.deleteTemplateById(templateId);
                  e.stopPropagation();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
        </CardContent>
      </Card>
    </div>
  );
}

export default Template;
