import VoiceLineItem from "./VoiceLineItem";
import { useState } from "react";
import NoData from "../CommonSections/NoDataContainer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Grid from "@material-ui/core/Grid";

const VoicePanel = (props) => {
    const {
        voiceTemplates,
        voiceId,
        changeVoiceId,
    } = props;

    const [playing, setPlaying] = useState(null);

    return (
        <>
            <h2 className="text-center">Voices</h2>
            <List
                sx={{
                    width: "95%",
                    bgcolor: "background.gray",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: "480px",
                    "& ul": { padding: 0 },
                }}
            >
                {voiceTemplates.length ? (
                    voiceTemplates.map((voice, index) => (
                        <ListItem
                            key={index}
                            component="div"
                            disablePadding
                        >
                            <VoiceLineItem
                                musicDetails={voice}
                                playing={playing}
                                setPlaying={setPlaying}
                                audioId={voiceId}
                                changeAudioId={changeVoiceId}
                                key={index}
                            />
                        </ListItem>
                    ))
                ) : (
                    <NoData label="Name" value="name" />
                )}
            </List>
        </>
    );
};

export default VoicePanel;
