/* eslint-disable */
import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "./style";
import GridContainer from "components/Grid/GridContainer";
import CustomButton from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";
import axios from "axios";
import { getToken } from "common/Authentication";
import CustomInput from "components/CustomInput/CustomInput.js";
import DeleteSVG from "./deleteLogo.svg";
import AddBtn from "./plus-square.png";
import InitiateBtn from "./log-out.png";
import "./st.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import NavBarVertical from "../Editor/NavBarVertical";
import HomeIcon from "assets/img/iconsSidebar/Home.png";
import DatabaseIcon from "assets/img/iconsSidebar/database.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { logout } from "common/Authentication";
import PowerSettingNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import LowCreditAlert from "../CommonSections/LowCreditAlert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Template extends Component {
  constructor(props) {
    super(props);
    this.snackBarVariantsMap = {
      error: "error",
      warning: "warning",
      info: "info",
      success: "success",
    };
    this.state = {
      templateId: this.props.match.params.templateId,
      loading: false,
      template_data: [],
      template_title: "",
      create_time: "",
      rows: [],
      validationError: "",
      isSnackBarOpen: false,
      snackBarMessage: "",
      snackBarVariant: "success",
      WATemplateData: {},
      selectedWATemplateName: "",
      WATemplatePlaceholders: 0,
    };
    this.getResponseArray = this.getResponseArray.bind(this);
    this.addRow = this.addRow.bind(this);
    this.capitalize = this.capitalize.bind(this);
    this.prepareTableData = this.prepareTableData.bind(this);
    this.capitalizeList = this.capitalizeList.bind(this);
    this.initiateSynthesisRequest = this.initiateSynthesisRequest.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
  }

  setWATemplateData = (data) => {
    this.setState({ WATemplateData: data });
  };

  selectWATemplateName = (template_name) => {
    if (template_name) {
      let placeholders_list =
        this.state.WATemplateData[template_name].text.match(/\{\{[^!\}]*\}\}/g);
      let placeholders = {};
      placeholders_list.forEach((e) => {
        placeholders[e] = true;
      });
      for (let i = 0; i < placeholders_list.length; i++) {
        if (!placeholders["{{" + (i + 1) + "}}"]) {
          return;
        }
      }
      this.setState({
        selectedWATemplateName: template_name,
        WATemplatePlaceholders: Object.keys(placeholders).length,
      });
    } else {
      this.setState({
        selectedWATemplateName: null,
        WATemplatePlaceholders: 0,
      });
    }
  };

  // SnackBar
  openSnackBar = (message, variant) => {
    this.setState({
      snackBarMessage: message,
      snackBarVariant: variant,
      isSnackBarOpen: true,
    });
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isSnackBarOpen: false });
  };

  getResponseArray() {
    axios({
      method: "GET",
      url: "/t2v/template/",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        id: this.state.templateId,
      },
    })
      .then((res) => {
        console.log("RES: ", res.data.data);
        this.setState({
          ...this.state,
          template_data: res.data.data["placeholders"],
          template_title: res.data.data["title"],
          loading: false,
          create_time: res.data.data["create_time"],
        });
        console.log("Data: ", this.state.template_data);
      })
      .catch((err) => {
        console.log(err);
        this.openSnackBar(err, this.snackBarVariantsMap.error);
      });
  }

  componentDidMount() {
    this.addRow();
    this.getResponseArray();
    this.fetchWATemplates();
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  capitalizeList(list) {
    var new_list = [];
    for (var i = 0; i < list.length; ++i) {
      new_list.push(this.capitalize(list[i]));
    }
    return new_list;
  }

  deleteRow(index) {
    if (this.state.rows.length > 1) {
      const removedRow = this.state.rows.splice(index, 1);
      this.setState({
        ...this.state,
        rows: this.state.rows,
      });
      console.log("Updated Row: ", this.state.rows);
    }
  }

  addRow() {
    this.setState({
      ...this.state,
      rows: [...this.state.rows, {}],
    });
    console.log("Add Row: ", this.state.rows);
  }

  onTemplatePVChange = (event, index, name) => {
    this.setState((previousState) => {
      const rows = [...previousState.rows];
      rows[index] = {
        ...rows[index],
        placeholders: {
          ...rows[index].placeholders,
          [name]: event.target.value,
        },
      };
      return { rows };
    });
  };

  onWANumberChange = (event, index) => {
    this.setState((previousState) => {
      const rows = [...previousState.rows];
      rows[index] = { ...rows[index], wa_number: event.target.value };
      return { rows };
    });
  };

  onWATemplatePVChange = (event, index, idx) => {
    this.setState((previousState) => {
      const rows = [...previousState.rows];
      rows[index] = {
        ...rows[index],
        wa_placeholders: {
          ...rows[index].wa_placeholders,
          [idx]: event.target.value,
        },
      };
      return { rows };
    });
  };

  initiateSynthesisRequest(e) {
    e.preventDefault();
    console.log("Sending Data: ", this.state.rows);
    let placeholder_data = this.state.rows
      .filter((row) => {
        if (
          !row.placeholders ||
          !Object.values(row.placeholders).every((x) => x && x.trim()) ||
          Object.values(row.placeholders).length !==
            this.state.template_data.length
        ) {
          return false;
        }
        if (this.state.selectedWATemplateName && !row.wa_number) {
          return false;
        }
        if (
          this.state.selectedWATemplateName &&
          this.state.WATemplatePlaceholders &&
          (!row.wa_placeholders ||
            ![...Array(this.state.WATemplatePlaceholders).keys()].every(
              (x) => row.wa_placeholders[x] && row.wa_placeholders[x].trim()
            ))
        ) {
          return false;
        }
        return true;
      })
      .map((row) => {
        let new_row = { ...row };
        if (this.state.selectedWATemplateName) {
          new_row.wa_number = row.wa_number.replaceAll(/[\+|\-|\(|\)|\s]/g, "");
          new_row.wa_placeholders = [
            ...Array(this.state.WATemplatePlaceholders),
          ].map((_, idx) => row.wa_placeholders[idx]);
        }
        return new_row;
      });
    if (placeholder_data.length) {
      this.sendInitRequest(
        placeholder_data,
        this.state.templateId,
        getToken(),
        this.state.selectedWATemplateName
      );
      this.setState({ ...this.state, validationError: "" });
    } else {
      this.setState({
        ...this.state,
        validationError: "Insufficient details provided",
      });
      this.openSnackBar(
        "Insufficient details provided",
        this.snackBarVariantsMap.error
      );
    }
  }
  sendInitRequest(rows, templateId, sso_token, wa_template_name) {
    try {
      axios({
        method: "POST",
        url: "/t2v/synthesis/init-bulk",
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        data: {
          placeholder_values: rows,
          type: "t2v",
          sso_token: sso_token,
          template_id: templateId,
          wa_template_name: wa_template_name ? wa_template_name : null,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.openSnackBar(
              "Successfully initiated synthesis.",
              this.snackBarVariantsMap.success
            );
            console.log(res.data.data.request_id);
          } else {
            this.setState({ processing: false });
            throw "temp";
          }
        })
        .catch((error) => {
          this.setState({ processing: false });
          this.openSnackBar(
            "Failed to initiate request. Please try again after some time.",
            this.snackBarVariantsMap.error
          );
        });
    } catch (e) {
      console.error(e);
      this.openSnackBar(e, this.snackBarVariantsMap.error);
    }
  }

  prepareTableData() {
    return this.state.rows.map((row, row_idx) =>
      this.state.template_data
        .map((item, _) => (
          <TextField
            variant="standard"
            label={this.capitalize(item)}
            value={
              row.placeholders && row.placeholders[item]
                ? row.placeholders[item]
                : ""
            }
            onChange={(e) => {
              this.onTemplatePVChange(e, row_idx, item);
            }}
            fullWidth
            required
          />
        ))
        .concat(
          this.state.WATemplatePlaceholders
            ? [
                <TextField
                  variant="standard"
                  label={"+91 99999 99999"}
                  value={row.wa_number ? row.wa_number : ""}
                  onChange={(e) => {
                    this.onWANumberChange(e, row_idx);
                  }}
                  fullWidth
                  required
                />,
              ]
            : []
        )
        .concat(
          [...Array(this.state.WATemplatePlaceholders)].map(
            (_, wa_placeholder_id) => (
              <TextField
                variant="standard"
                label={"{{" + (wa_placeholder_id + 1) + "}}"}
                value={
                  row.wa_placeholders && row.wa_placeholders[wa_placeholder_id]
                    ? row.wa_placeholders[wa_placeholder_id]
                    : ""
                }
                onChange={(e) => {
                  this.onWATemplatePVChange(e, row_idx, wa_placeholder_id);
                }}
                fullWidth
                required
              />
            )
          )
        )
        .concat([
          <Button variant="text" onClick={() => this.deleteRow(row_idx)}>
            <img src={DeleteSVG} alt="Delete" />
          </Button>,
        ])
    );
  }

  fetchWATemplates = () => {
    axios({
      method: "GET",
      url: "/dissemination/wa-template/all",
      headers: {
        Authorization: "Bearer " + getToken(),
        RequestTime: new Date(),
      },
    }).then((res) => {
      if (res.status === 200) {
        let approved_templates = {};
        Object.entries(res.data.data).forEach((elem, _) => {
          if (elem[1].status === "approved") {
            approved_templates[elem[0]] = elem[1];
          }
        });
        this.setWATemplateData(approved_templates);
      }
    });
  };

  render() {
    const { classes } = this.props;
    let table_data = this.prepareTableData();
    return (
      <div className="d-flex" style={{ height: "100vh" }}>
        <div style={{ width: "100%", height: "100vh" }}>
          <div>
            <div style={{ width: "100%", height: "100vh" }} className="mx-auto">
              <div md={10} sm={10} style={{ width: "100%", height: "100vh" }}>
                <LowCreditAlert />
                {this.state.loading ? (
                  "Loading . . ."
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #3C02D5 0%, #84018D 100%)",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                      className={classes.tableHeader}
                    >
                      {this.state.template_title}
                      <br />
                      <span style={{ color: "#FFD8C1" }}>
                        {this.state.create_time}
                      </span>
                    </div>

                    <div style={{ height: "100%" }}>
                      <div
                        style={{ backgroundColor: "#ADD8E6", height: "100%" }}
                        className={classes.tableContent}
                      >
                        <GridContainer className="w-100 mx-0 my-1">
                          <GridItem md={11} className="mx-auto">
                            {Object.entries(this.state.WATemplateData)
                              .length ? (
                              <GridContainer className="mt-5">
                                <GridItem md={6}></GridItem>
                                <GridItem md={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id="watemplate_label">
                                      WA Template
                                    </InputLabel>
                                    <Select
                                      labelId="watemplate_label"
                                      inputProps={{
                                        name: "watemplate",
                                      }}
                                      label="WA Template"
                                      value={this.state.selectedWATemplateName}
                                      onChange={(e) => {
                                        this.selectWATemplateName(
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem value={null}>None</MenuItem>
                                      {Object.keys(
                                        this.state.WATemplateData
                                      ).map((elem, idx) => (
                                        <MenuItem value={elem} key={idx}>
                                          {elem}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </GridItem>
                              </GridContainer>
                            ) : (
                              <></>
                            )}
                            {this.state.selectedWATemplateName ? (
                              <div
                                className="mb-3"
                                style={{
                                  overflowY: "auto",
                                  maxHeight: "100px",
                                }}
                              >
                                {" "}
                                <b>Selected WA Template:</b>{" "}
                                {
                                  this.state.WATemplateData[
                                    this.state.selectedWATemplateName
                                  ].text
                                }
                              </div>
                            ) : (
                              <div className="mb-5"></div>
                            )}
                            <div
                              className={classes.table}
                              style={{
                                overflowY: "auto",
                                overflowX: "auto",
                                maxHeight: "250px",
                              }}
                            >
                              <Table
                                tableHead={this.capitalizeList(
                                  this.state.template_data.concat(
                                    this.state.selectedWATemplateName
                                      ? ["WhatsApp Number"].concat(
                                          [
                                            ...Array(
                                              this.state.WATemplatePlaceholders
                                            ),
                                          ].map(
                                            (_, id) => "{{" + (id + 1) + "}}"
                                          )
                                        )
                                      : []
                                  )
                                )}
                                tableData={table_data}
                              />
                            </div>

                            <div
                              style={{
                                position: "absolute",
                                right: 20,
                                margin: "auto",
                              }}
                              className={classes.Buttons}
                            >
                              <GridContainer>
                                <GridItem xs={6}>
                                  <CustomButton
                                    type="button"
                                    onClick={this.addRow}
                                    color="transparent"
                                    className="btn outlined"
                                  >
                                    Add a Row
                                  </CustomButton>
                                </GridItem>
                              </GridContainer>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>

                      <CustomButton
                        className="btn"
                        color="transparent"
                        type="button"
                        onClick={this.initiateSynthesisRequest}
                        style={{
                          width: "80%",
                          backgroundColor: "#6699cc",
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          margin: "auto",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                        }}
                      >
                        Initiate Creating Video
                      </CustomButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <Snackbar
              open={this.state.isSnackBarOpen}
              autoHideDuration={6000}
              onClose={this.handleSnackBarClose}
            >
              <Alert
                onClose={this.handleSnackBarClose}
                severity={this.state.snackBarVariant}
                sx={{ width: "100%" }}
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Template);
