/* eslint-disable */
import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import classNames from "classnames";
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import TagsInput from "react-tagsinput";
import moment from 'moment';
import Button from "@mui/material/Button";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getToken } from "common/Authentication";
import {GET_TEMPLATES, SERVICE_CONFIG} from "../APIs";
import download from "container/Landing/download.png";

const select_status_values = [
    {name:"All", value:"all"}, 
    {name:"Success", value:"success"}, 
    {name:"Pending", value:"pending"}, 
    {name:"Fail", value:"fail"},
    {name: "Approval Pending", value: "approval_pending"}
];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Filters extends Component {
    constructor(props){
        super(props);
        this.snackBarVariantsMap = {
            error: "error",
            warning: "warning",
            info: "info",
            success: "success",
        };
        this.state = {
            filter: {
                create_time_gt: moment().subtract(1, 'days'),
                create_time_lt: moment(),
                request_ids: [],
            },
            advanced_filter: false,
            select_template_values: [],
            select_avatar_values: [],
            select_voice_values: [],
            templateLoading: true,
            avatarLoading: true,
            voiceLoading: true,
            dataLoadingState: true,
            isSnackBarOpen: false,
            snackBarMessage: "",
            snackBarVariant: "success",
        };
    };

    openSnackBar = (message, variant) => {
        this.setState({
            snackBarMessage: message,
            snackBarVariant: variant,
            isSnackBarOpen: true,
        });
    };

    handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ isSnackBarOpen: false });
    };

    componentDidMount(){
        this.props.handleFilter(this.state.filter);

        // GET ALL TEMPLATES
        axios({
            method: GET_TEMPLATES.method,
            url: GET_TEMPLATES.endpoint,
            headers: {
                Authorization:'Bearer ' + getToken(),
                RequestTime: new Date()
            },
        })
        .then((data) => {
            let all_data = [{title: "All", id: "all"}].concat(data.data.data);
            this.setState({
                ...this.state, 
                select_template_values: all_data,
                templateLoading: false,
            });
            console.log("Templates: ", this.state.select_template_values);
        })
        .catch((error) => {
            console.log(error);
            this.openSnackBar("Unable to fetch Templates", this.snackBarVariantsMap.error);
            setTimeout(() => {
                this.handleSnackBarClose();
            }, 3000);
        });

        // GET ALL VOICE
        axios({
            method: SERVICE_CONFIG.method,
            url: SERVICE_CONFIG.endpoint,
            headers: {
                Authorization: "Bearer " + getToken(),
                RequestTime: new Date(),
            },
            params: {
                service: "t2v",
                config_type: "ENTITY-VOICE",
            },
        })
        .then((res) => {
            let all_data = [{name: "All", id: "all"}].concat(res.data.data);
            this.setState({
                ...this.state, 
                select_voice_values: all_data,
                voiceLoading: false,
            });
            console.log("Voice: ", this.state.select_voice_values);
        })
        .catch((error) => {
            console.log(error);
            this.openSnackBar("Unable to fetch Voices", this.snackBarVariantsMap.error);
            setTimeout(() => {
                this.handleSnackBarClose();
            }, 3000);
        });

        // GET ALL AVATAR
        axios({
            method: SERVICE_CONFIG.method,
            url: SERVICE_CONFIG.endpoint,
            headers: {
                Authorization: "Bearer " + getToken(),
                RequestTime: new Date(),
            },
            params: {
                service: "t2v",
                config_type: "ENTITY-AVATAR",
            },
        })
        .then((res) => {
            let all_data = [{name: "All", id: "all"}].concat(res.data.data);
            this.setState({ 
                ...this.state, 
                select_avatar_values: all_data,
                avatarLoading: false,
            });
            console.log("Avatar: ", this.state.select_avatar_values);
        })
        .catch((err) => {
            console.log(err);
            this.openSnackBar("Unable to fetch Avatars", this.snackBarVariantsMap.error);
            setTimeout(() => {
                this.handleSnackBarClose();
            }, 3000);
        })
    };

    handleSubmit(data){
        let filters = data;
        this.props.handleFilter(filters);
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classNames('my-3')} style={{marginRight: "0px", marginBottom: '0px'}}>
                <FormControl>
                    <TextField
                        inputProps={{
                            style: {
                                padding: 0,
                            },
                            placeholder: 'Search',
                            onChange: (t)=>{this.setState({...this.state, filter:{...this.state.filter, title_prefix: t.target.value}})},
                            onBlur: () => this.handleSubmit(this.state.filter),
                        }}
                    />
                </FormControl>
                <br />
                <Card style={{border: "0px solid #FFF", borderShadow: "0px", marginBottom: '0px'}}>
                    <form>
                        <CardHeader>
                            <h3>Filters</h3>
                        </CardHeader>
                        <CardBody style={{fontSize: "14px"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <GridContainer>
                                    <GridItem sm={4} md={4}>
                                            <GridContainer>
                                                <GridItem sm={4} md={4} style={{marginBottom: "0.5rem"}}>
                                                    Start Date
                                                </GridItem>
                                                <GridItem sm={8} md={8} style={{marginBottom: "0.5rem"}}>
                                                <FormControl>
                                                    <DateTimePicker
                                                        style={{ padding : '0' }}
                                                        maxDate={moment.min(this.state.filter.create_time_lt, moment())}
                                                        minDate={moment().subtract(35, 'days')}
                                                        value={this.state.filter.create_time_gt}
                                                        onChange={(t)=>{this.setState({...this.state, filter:{...this.state.filter, create_time_gt: t}})}}
                                                        onBlur={() => this.handleSubmit(this.state.filter)}
                                                    />
                                                </FormControl>
                                                </GridItem>

                                                <GridItem sm={4} md={4} style={{marginTop: "0.7rem"}}>
                                                    End Date
                                                </GridItem>
                                                <GridItem sm={8} md={8} style={{marginTop: "0.5rem"}}>
                                                    <DateTimePicker
                                                        style={{ padding : '0'}}
                                                        maxDate={moment.min(this.state.filter.create_time_lt, moment())}
                                                        minDate={moment().subtract(35, 'days')}
                                                        value={this.state.filter.create_time_lt}
                                                        onChange={(t)=>{this.setState({...this.state, filter:{...this.state.filter, create_time_lt: t}})}}
                                                        onBlur={() => this.handleSubmit(this.state.filter)}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                    </GridItem>

                                    <GridItem sm={4} md={4}>
                                        <GridContainer>
                                            <GridItem sm={3} md={3}>
                                                Request ID
                                            </GridItem>
                                            <GridItem sm={9} md={9} style={{align: "top"}}>
                                            <FormControl>
                                                <TagsInput
                                                    value={this.state.filter.request_ids}
                                                    label="Request ids"
                                                    onChange={(t)=>{
                                                        let filters = {...this.state.filter, request_ids: t};
                                                        this.setState({...this.state, filter:filters});
                                                        this.handleSubmit(filters);
                                                    }}
                                                    inputProps={{
                                                        style:{ width: '100%', borderBottom: "1px solid rgb(0,0,0,0.6)", paddingTop: '0px'},
                                                        // fullWidth: true,
                                                    }}
                                                    tagProps={{ className: "react-tagsinput-tag rose" }}
                                                />
                                            </FormControl>
                                            </GridItem>
                                            <GridItem sm={3} md={3} style={{marginTop: "0.7rem"}}>
                                                Status
                                            </GridItem>
                                            <GridItem sm={2} md={2} style={{marginTop: "0.5rem"}}>
                                            <FormControl>
                                                <Select
                                                    defaultValue={select_status_values[0].value}
                                                    inputProps={{
                                                        name: "status",
                                                        id: "select-status",
                                                    }}
                                                    onChange={(t)=>{this.setState({...this.state, filter:{...this.state.filter, response_status: t.target.value}})}}
                                                    onBlur={() => this.handleSubmit(this.state.filter)}
                                                >
                                                    {select_status_values.map(x=>
                                                        <MenuItem value={x.value}>
                                                            {x.name}
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            </GridItem>


                                            <GridItem sm={1} md={1} style={{marginTop: "0.7rem"}}>
                                                
                                            </GridItem>

                                            <GridItem sm={3} md={3} style={{marginTop: "0.7rem"}}>
                                                Template
                                            </GridItem>
                                            <GridItem sm={2} md={2} style={{marginTop: "0.5rem"}}>
                                                {this.state.templateLoading ? "" :
                                                    <FormControl>
                                                    <Select
                                                        defaultValue='all'
                                                        inputProps={{
                                                            name: "template",
                                                            id: "select-template",
                                                        }}
                                                        onChange={(t)=>{this.setState({...this.state, filter:{...this.state.filter, template_id: t.target.value}})}}
                                                        onBlur={() => this.handleSubmit(this.state.filter)}
                                                    >
                                                        {this.state.select_template_values.map(x=>
                                                            <MenuItem value={x.id}>
                                                                {x.title}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                    </FormControl>
                                                }
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>

                                    <GridItem sm={3} md={3}>
                                            <GridContainer>
                                                <GridItem sm={4} md={4} style={{marginBottom: "0.5rem"}}>
                                                    Avatar
                                                </GridItem>
                                                <GridItem sm={8} md={8} style={{marginBottom: "0.5rem"}}>
                                                {this.state.avatarLoading ? "" : 
                                                    <FormControl>
                                                    <Select
                                                        defaultValue='all'
                                                        inputProps={{
                                                            name: "avatar",
                                                            id: "select-avatar",
                                                            fullWidth: true,
                                                        }}
                                                        onChange={(t)=>{this.setState({...this.state, filter:{...this.state.filter, avatar: t.target.value}})}}
                                                        onBlur={() => this.handleSubmit(this.state.filter)}
                                                    >
                                                        {this.state.select_avatar_values.map(x=>
                                                            <MenuItem value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                    </FormControl>
                                                }
                                                </GridItem>

                                                <GridItem sm={4} md={4} style={{marginTop: "0.7rem"}}>
                                                    Voice
                                                </GridItem>
                                                <GridItem sm={8} md={8} style={{marginTop: "0.5rem"}}>
                                                {this.state.voiceLoading ? " ": 
                                                    <FormControl>
                                                    <Select
                                                        defaultValue='all'
                                                        inputProps={{
                                                            name: "voice",
                                                            id: "select-voice",
                                                            fullWidth: true,
                                                        }}
                                                        onChange={(t)=>{this.setState({...this.state, filter:{...this.state.filter, voice: t.target.value}})}}
                                                        onBlur={() => this.handleSubmit(this.state.filter)}
                                                    >
                                                        {this.state.select_voice_values.map(x=>
                                                            <MenuItem value={x.id}>
                                                                {x.name}
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                    </FormControl>
                                                }
                                                </GridItem>
                                            </GridContainer>
                                    </GridItem>
                                    <GridItem sm={1} md={1} align="center">
                                        <Button 
                                            onClick={()=>{
                                                this.props.handleDownloadAll()
                                            }}
                                        >
                                            <div>
                                                <img src={download} alt="download button" />
                                            </div>
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </MuiPickersUtilsProvider>
                        </CardBody>
                    </form>
                </Card>
                <div>
                    <Snackbar
                        open={this.state.isSnackBarOpen}
                        autoHideDuration={6000}
                        onClose={this.handleSnackBarClose}
                    >
                        <Alert
                            onClose={this.handleSnackBarClose}
                            severity={this.state.snackBarVariant}
                            sx={{ width: "100%" }}
                        >
                            {this.state.snackBarMessage}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        )
    }
}

export default Filters;