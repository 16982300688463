/* eslint-disable */
import React, { Component } from "react";
import SideBarButton from "./CustomNavBarButtons/SideBarButton";
import TopNavBarButton from "./CustomNavBarButtons/TopNavBarButton";
import Button from "@mui/material/Button";
import { PlayArrow } from "@material-ui/icons";
import { Add } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import EditIcon from "assets/img/iconsSidebar/EditIconPencil.svg";

class NavBarHorizontal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title_edit: false,
    };
  }

  render() {
    const {
      sideBarBottomButtons = [],
      title,
      setTitle,
      isSideBarOpen,
      updateSideBarOpen,
      initiateOrSaveRequest,
      saveAsTemplate,
    } = this.props;

    return (
      <div>
        <div
          class="navbars-top"
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              "linear-gradient(to right, #3C02D5 0%, #84018D 100%)",
            display: "flex",
          }}
        >
          <div
            style={{ width: "20%", display: "flex", alignItems: "center" }}
            className="d-flex flex-row"
          >
            {sideBarBottomButtons.map((buttonData, index) => (
              <TopNavBarButton
                id={index}
                key={index}
                onClickButton={buttonData.onClick || updateSideBarOpen}
                buttonData={buttonData}
              />
            ))}
          </div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.state.title_edit ? (
              <CustomInput
                inputProps={{
                  defaultValue: title,
                  onBlur: (t) => {
                    t.target.value && setTitle(t.target.value);
                    this.setState({ title_edit: false });
                  },
                  autoFocus: true,
                }}
                white={true}
                formControlProps={{
                  className: "m-0 p-0",
                }}
              />
            ) : (
              <div
                className="d-flex align-items-center"
                onClick={() => this.setState({ title_edit: true })}
              >
                <h3 className="m-0 text-white" style={{ whiteSpace: "nowrap" }}>
                  {title}
                </h3>
                <img
                  src={EditIcon}
                  className="ml-2"
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
            {this.state.title_edit ? (
              <Button
                variant="contained"
                onClick={()=>{}}
                style={{
                  backgroundColor: "#00000080",
                  marginLeft: "10px",
                  textTransform: "capitalize",
                }}
              >
                Save
              </Button>
            ) : (
              <div></div>
            )}
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#fff",
              padding: 10,
              position: "absolute",
              right: 0,
            }}
          ></div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Button
              startIcon={<PlayArrow />}
              variant="contained"
              color="primary"
              style={{
                marginRight: "10px",
                padding: "5px 15px",
                borderRadius: "8px",
                color: "white",
              }}
            >
              Preview
            </Button> */}
            <Button
              onClick={()=>{initiateOrSaveRequest()}}
              startIcon={<Add style={{ color: "#3902D8" }} />}
              style={{
                marginRight: "10px",
                backgroundColor: "white",
                padding: "5px 10px",
                borderRadius: "8px",
                color: "#3902D8",
              }}
            >
              Generate
            </Button>
            <Button
              onClick={()=>{initiateOrSaveRequest(true)}}
              style={{
                marginRight: "10px",
                border: "1px solid white",
                padding: "5px",
                borderRadius: "8px",
                color: "white",
              }}
            >
              Save
            </Button>
          </div>
        </div>

        <div style={{ background: "#E8E8E8" }}>
          {sideBarBottomButtons.map((buttonData, index) => {
            if (index === isSideBarOpen && buttonData.componentToRender)
              return (
                <buttonData.componentToRender
                  key={index}
                  closeExpandedDiv={() => updateSideBarOpen(null)}
                  {...buttonData.props}
                />
              );
            return <div key={index}></div>;
          })}
        </div>
      </div>
    );
  }
}

export default NavBarHorizontal;
