/* eslint-disable */
import { useEffect, useState } from "react";

import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { getToken } from "common/Authentication";
import Modal from "@material-ui/core/Modal";
import Dropzone from "../MainEditorComponents/Dropzone";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import HeadsetIcon from "@mui/icons-material/Headset";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import LightTooltip from "components/LightTooltip/LightTooltip";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TwitterPicker } from "react-color";
import { colorArray } from "utils/colors";
import IconButton from "@mui/material/IconButton";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Preview from "../Sections/Preview";
import PlayIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import PauseIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Popover from "@mui/material/Popover";
import Loader from "react-loader-spinner";
import GeneratePlaceHolderAndSilence from "../MainEditorComponents/GeneratePlaceholderAndSilence";
import Editor from "../MainEditorComponents/TextEditor";
import { Box, Button } from "@mui/material";
import Dropzone2 from "../MainEditorComponents/Dropzone2";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, TextField } from "@mui/material";

const useStyles = makeStyles({
  sliderStyle: {
    "&.MuiSlider-rail": {
      color: "grey",
    },
    "&.MuiSlider-track": {
      color: "#00c7e5",
    },
    "&.MuiSlider-thumb": {
      color: "#00c7e5",
    },
    "&.MuiSlider-valueLabel": {
      background: "black",
    },
  },
});

const VerticalScripts = (props) => {
  const {
    mainContentWidth,
    editorOpen,
    currentSlide,
    setEditorText,
    setAudioFile,
    editorToggle,
    isSideBarOpen,
    initiateOrSaveRequest,
    hidePreview,
    readFile,
    addAllPlaceholders,
    setAvatarEnabled,
    updateAvatarBGColor,
    initiateAudio,
    showPreview,
    slideslength,
    voiceSpeed,
    changeVoiceSpeed,
  } = props;

  const classes = useStyles();
  var currentAvatarBackgroundColor =
    slideslength !== 0 && currentSlide["backgroundColorAvatar"];

  var preview_bg_color = currentSlide.bg_color;
  var preview_bg_url = currentSlide.custom_bg_url;
  var preview_audio_processing = currentSlide.preview_audio_processing;

  var preview_audio_url = currentSlide.preview_audio_url;

  var preview_bg_thumbnail_url = currentSlide.custom_bg_thumbnail_url;

  var editorText = currentSlide.text;
  var preview_panel_display = currentSlide.preview_panel_display;

  var editorAudio = currentSlide.audio;

  const [upload_modal, setUploadModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [alert_modal, setAlertModal] = useState(false);
  let content = null;
  const [openModal, setModalOpen] = useState(false);

  const handleModalClose = () => setModalOpen(false);
  const handleSliderValue = (event, newValue) => {
    changeVoiceSpeed(newValue);
  };

  const toggleUploadModal = () => {
    setUploadModal(!upload_modal);
  };

  const toggleAlertModal = () => {
    setAlertModal(!alert_modal);
  };

  const initiateSlidesFromPpt = (slides) => {
    props.initiateSlidesFromPpt(slides);
  };
  const [avatarBGColor, setAvatarBGColor] = useState("");

  const handleModalOpen = () => setModalOpen(true);

  const [avatarBGColorToggle, setAvatarBGColorToggle] = useState(
    currentAvatarBackgroundColor ? true : false
  );

  const { insertPlaceHolder, isEditorOpen, allPlaceholders } = props;

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    height: 600,
    p: 4,
  };

  const sliderStyleClasses = {
    rail: classes.sliderStyle,
    track: classes.sliderStyle,
    thumb: classes.sliderStyle,
    valueLabel: classes.sliderStyle,
  };

  const handleAvatarBgCheckbox = () => {
    setAvatarBGColorToggle(!avatarBGColorToggle);
    updateAvatarBGColor(avatarBGColorToggle ? null : "#A4DD00");
    setAvatarBGColor(avatarBGColorToggle ? "" : "#A4DD00");
  };
  const doSomethingWithAudio = () => {
    if (preview_audio_processing) {
      return (
        <div
          style={{
            padding: "8px",
          }}
        >
          <Loader type="ThreeDots" color="#00BFFF" height={24} width={24} />
        </div>
      );
    }

    if (preview_audio_url === "") {
      return getCompWithOnClick("Play", initiateAudio, "Play Audio");
    }

    if (preview_panel_display) {
      return getCompWithOnClick("Pause", hidePreview);
    }

    return getCompWithOnClick("Play", showPreview);
  };

  const uploadPpt = (file) => {
    setUploadStatus("pending");
    setUploadFile(file);
    let data = new FormData();
    data.append("file", file);
    data.append("fetch_thumbnail", false);
    axios
      .post("/util/resource", data, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          getSlidesFromPpt(res.data.data.url);
        } else {
          throw "temp";
        }
      })
      .catch(() => {
        setUploadStatus("fail");
      });
  };

  const getSlidesFromPpt = (input_file) => {
    axios
      .post(
        "/util/toslide",
        {
          input_file: input_file,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((res) => {
        setUploadStatus("success");
        initiateSlidesFromPpt(res.data.data);
      })
      .catch(() => {
        setUploadStatus("fail");
      });
  };

  const getCompWithOnClick = (CurrentComponent, onClick, tooltipTitle) => (
    <div>
      <Tooltip title={tooltipTitle} placement="top" arrow>
        <IconButton
          onClick={onClick}
          style={{
            outline: "none",
            fontSize: "12px",
            color: "grey",
            marginRight: "6px",
          }}
        >
          {CurrentComponent}
        </IconButton>
      </Tooltip>
    </div>
  );

  const [popoverData, setPopoverData] = useState("");
  const [popoverInputString, setPopoverInputString] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event, inputString) => {
    setPopoverData("");
    setPopoverInputString(inputString);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (!popoverData) {
      return;
    }

    const isPlaceHolder = popoverInputString.includes("Placeholder");
    if (isPlaceHolder) {
      insertPlaceHolder(popoverData);
      return;
    }

    content.insertSilence(popoverData);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      className="d-flex flex-column align-items-center h-100"
      style={{ width: "100%" }}
    >
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        marginThreshold={100}
        PaperProps={{ style: { overflow: "visible" } }}
      >
        <form
          style={{ background: "grey" }}
          className="d-flex flex-column align-items-center p-2 mb-0"
        >
          {popoverInputString.includes("Placeholder") ? (
            <Autocomplete
              disablePortal
              id="popOverInput"
              options={
                allPlaceholders.includes(popoverData)
                  ? allPlaceholders
                  : [popoverData].concat(allPlaceholders)
              }
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Placeholder" />
              )}
              inputValue={popoverData}
              onInputChange={(event, newInputValue) => {
                setPopoverData(newInputValue);
              }}
            />
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <b>Seconds</b>
              </div>
              <TextField
                id="popOverInput"
                type="number"
                InputLabelProps={{
                  style: {
                    color: "red",
                    fontSize: "20px",
                    fontWeight: "bold",
                  },
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    backgroundColor: "#f2f2f2",
                    border: "none",
                    borderRadius: "5px",
                    padding: "10px",
                  },
                }}
                value={popoverData}
                onChange={(e) =>
                  e.target.value > 0 && setPopoverData(e.target.value)
                }
              />
            </div>
          )}
          {/* <input
            id="popOverInput"
            style={{
              borderRadius: "5px",
              border: "0.5px solid #d7d7d7",
              background: "#d7d7d7",
              padding: "5px 10px",
            }}
            value={popoverData}
            onChange={(e) => setPopoverData(e.target.value)}
          /> */}
          <button
            type="submit"
            className="btn btn-dark mt-2 btn-sm"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            style={{ width: "80%" }}
          >
            Insert
          </button>
        </form>
      </Popover>
      <Modal
        onClose={toggleUploadModal}
        open={upload_modal}
        style={{
          position: "absolute",
          color: "black",
          height: "85vh",
          width: "80vh",
          margin: "auto",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <Dropzone
            containerStyle={{ height: "100%" }}
            onFilesAdded={uploadPpt}
            status={uploadStatus}
            file={uploadFile}
          />
        </div>
      </Modal>
      <Modal
        open={alert_modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "10px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            background: "white",
            border: "2px solid #000",
            boxShadow: 24,
            padding: "20px",
          }}
          className="d-flex flex-column align-items-center"
        >
          <p className="h3 fw-bold">Please confirm the change.</p>
          <p className="">
            Uploading slides will result in loss of current slides
          </p>
          <GridContainer className="my-1">
            <GridItem md={6}>
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={() => {
                  toggleAlertModal();
                  toggleUploadModal();
                }}
              >
                Confirm
              </button>
            </GridItem>
            <GridItem md={6}>
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={toggleAlertModal}
              >
                Discard
              </button>
            </GridItem>
          </GridContainer>
        </div>
      </Modal>
      <div style={{ width: "100%", flexDirection: "column" }}>
        <div className=" d-flex flex-column mr-4" style={{ width: "100%" }}>
          <div
            className="d-flex flex-grow-1 justify-content-end m-0"
            style={{
              border: "2px solid #D7D7D7",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <div className="row m-0">
              {/* <LightTooltip
                title={
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <TwitterPicker
                      color={avatarBGColor}
                      colors={colorArray}
                      triangle="hide"
                      className="m-2"
                      width="240px"
                      onChangeComplete={(color) => {
                        setAvatarBGColorToggle(true);
                        setAvatarBGColor(color.hex);
                        updateAvatarBGColor(color.hex);
                      }}
                      styles={{
                        default: {
                          card: {
                            backgroundColor: "#D7D7D7",
                          },
                          label: {
                            display: "block",
                            marginTop: "10px",
                          },
                        },
                      }}
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={handleAvatarBgCheckbox}
                            checked={avatarBGColorToggle}
                          />
                        }
                        label="Add background color in avatar"
                      />
                    </FormGroup>
                  </div>
                }
                placement="top"
                TransitionProps={{ timeout: 600 }}
                leaveDelay={200}
                arrow
              >
                <IconButton style={{ outline: "none" }}>
                  <AccountCircleIcon />
                </IconButton>
              </LightTooltip> */}

              <Tooltip title="Silence" placement="top" arrow>
                <IconButton
                  onClick={(event) => openPopover(event, "Silence")}
                  style={{ outline: "none", fontSize: "12px", color: "grey" }}
                >
                  Insert Silence
                </IconButton>
              </Tooltip>
              <Tooltip title="Upload Script" placement="top" arrow>
                <IconButton
                  onClick={handleModalOpen}
                  style={{ outline: "none", fontSize: "12px", color: "grey" }}
                >
                  Upload
                </IconButton>
              </Tooltip>
              {doSomethingWithAudio()}
            </div>
          </div>
          <div
            className="flex-grow-1"
            style={{
              border: "2px solid #D7D7D7",
              height: "100%",
              padding: "5px",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <Editor
              text={editorText}
              setText={setEditorText}
              isSideBarOpen={isSideBarOpen}
              ref={(instance) => {
                content = instance;
              }}
            />

            {!editorOpen && (
              <Dropzone
                file={editorAudio && editorAudio.file}
                onFilesAdded={setAudioFile}
                status={editorAudio && editorAudio.status}
                containerStyle={{
                  margin: "0",
                  height: `${isSideBarOpen !== null ? "270px" : "100px"}`,
                }}
              />
            )}
          </div>
          {openModal ? (
            <Modal
              open={openModal}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Button
                  onClick={handleModalClose}
                  style={{ marginLeft: "90%", marginBottom: "2%" }}
                >
                  <img
                    src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                    style={{ width: "30px" }}
                  />
                </Button>
                <Dropzone2 onFilesAdded={readFile} className="flex-grow-1" />
              </Box>
            </Modal>
          ) : (
            ""
          )}
          <Preview
            open={preview_panel_display}
            toggle={hidePreview}
            audio_url={preview_audio_url}
            background={{
              preview_bg_color: preview_bg_color,
              preview_bg_thumbnail_url: preview_bg_thumbnail_url,
              preview_bg_url: preview_bg_url,
            }}
          ></Preview>
        </div>
        <GeneratePlaceHolderAndSilence
          isSideBarOpen={isSideBarOpen}
          initiateRequest={initiateOrSaveRequest}
          isEditorOpen={editorOpen}
          allPlaceholders={allPlaceholders}
          insertPlaceHolder={(val) => {
            content.insertPlaceHolder(val);
            addAllPlaceholders(val);
          }}
        />
        <div style={{ marginTop: "8px" }}>
          <Stack spacing={2} direction="row" sx={{ mb: 2 }} alignItems="center">
            <p
              style={{
                whiteSpace: "nowrap",
                alignItems: "center",
                color: "#19c7e5",
                alignContent: "center",
              }}
              className="h6 single-line"
            >
              Audio Speed
            </p>
            <Slider
              aria-label="Voice Speed"
              min={0.5}
              max={2}
              marks
              step={0.1}
              value={voiceSpeed}
              onChange={handleSliderValue}
              classes={sliderStyleClasses}
              valueLabelDisplay="auto"
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default VerticalScripts;
