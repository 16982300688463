/* eslint-disable */
import "./GenerateMenuButton.css";

const GenerateMenuButton = (props) => {
  const classes = props.isRow ? " flex-row " : " flex-column ";
  const { boxStyle, iconStyle, textStyle } = props;

  return (
    <div
      className={
        "d-flex flex-column "
      }
      style={{
        backgroundColor : "#e4e4e4",
        borderRadius: "4px",
        padding: "10px",
        cursor:"pointer",
        ...boxStyle,
      }}
      onClick={props.buttonData.onClick}
    >
    
      <div
        className="text-center flex-column "
        style={{
          fontSize: "17px",
          lineHeight: "14.22px",
          fontWeight: "normal",
          ...textStyle,
          whiteSpace: "pre-wrap",
          color:'grey'
        }}
      >
        {props.buttonData.name}
      </div>
    </div>
  );
};

export default GenerateMenuButton;
