import { styled } from "@mui/material/styles";
import TextField from "material-ui/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const SideBarButton = (props) => {
  const { onClickButton, id, buttonData } = props;
  return (
    <div className="initial"
      style={{
        padding: "8px",
        margin: "20px",
        borderRadius: "10px",
        cursor: "pointer",
        width: '100%',
       
      }}
      onClick={() => onClickButton(id)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: '100%',
        }}
      >
        <img
          src={buttonData.icon}
          alt="example"
          style={{ width: "50%", height: "35px"}}
        />
        <p style={{ color: "white",marginTop:'10px' }}> {buttonData.name}</p>
      </div>
    </div>
  );
};

export default SideBarButton;
