import React from "react";
import styles from "./style";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);
const VerticalNavBarListItem = (props) => {
  const classes = useStyles();
  const hoverColor = "info";
  const hover = classNames({
    [classes[hoverColor + "Hover"]]: true,
  });

  const navbarItemFlexClasses = classNames(
    "d-flex",
    "justify-content-start",
    "align-items-center"
  );

  const CurrentComponent = props.currentComponent;

  return (
    <div
      style={{ cursor: "pointer", marginTop: "12px", padding: "5px" }}
      className={hover}
    >
      <Link
        style={{ color: "white" }}
        to={props.redirectLink}
        onClick={props.onClick}
        className={navbarItemFlexClasses}
      >
        <CurrentComponent />
        {props.isOpen ? (
          <div
            className="ml-1 mr-1"
            style={{ whiteSpace: "nowrap", overflow: "hidden", cursor: "pointer" }}
          >
            {props.text}
          </div>
        ) : (
          <div></div>
        )}
      </Link>
    </div>
  );
};

VerticalNavBarListItem.propTypes = {
  redirectLink: PropTypes.string.isRequired,
  onClick: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentComponent: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
};

export default VerticalNavBarListItem;
