/* eslint-disable */
import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import { Button } from "reactstrap";

const useStyles = makeStyles({
  sliderStyle: {
    "&.MuiSlider-rail": {
      color: "grey",
    },
    "&.MuiSlider-track": {
      color: "#00c7e5",
    },
    "&.MuiSlider-thumb": {
      color: "#00c7e5",
    },
    "&.MuiSlider-valueLabel": {
      background: "black",
    },
  },
});

const TextEditor = (props) => {
  const {
    title,
    editorText,
    setEditorText,
    previewAudioProcessing,
    previewAudioUrl,
    initiateAudio,
    voiceSpeed,
    changeVoiceSpeed,
  } = props;

  const [playing, setPlaying] = useState(false);
  const classes = useStyles();
  const handleSliderValue = (event, newValue) => {
    changeVoiceSpeed(newValue);
  };

  const sliderStyleClasses = {
    rail: classes.sliderStyle,
    track: classes.sliderStyle,
    thumb: classes.sliderStyle,
    valueLabel: classes.sliderStyle,
  };

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ width: "100%" }}
    >
      <div className="d-flex flex-row m-1 w-100" style={{justifyContent: "flex-end"}}>
        <Button
          disabled={previewAudioProcessing}
          onClick={initiateAudio}
          style={{
              minWidth: "0px",
              display: "flex",
              justifyContent: "flex-end",
              background: "#2fbdb8",
              color: "#ffffff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              fontSize: "14px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
          }}
        >
          {previewAudioProcessing ? "Generating" : "Generate"}
        </Button>

        <audio
          onEnded={(event)=>setPlaying(false)}
          id="preview-audio"
          src={previewAudioUrl}
          width="0"
          height="0"
          controls={false}
        />
        <Button
          onClick={(event) => {
            event.stopPropagation();
            if (playing) {
              document.getElementById("preview-audio").pause();
              document.getElementById("preview-audio").currentTime = 0;
              setPlaying(false);
            } else {
              document.getElementById("preview-audio").play();
              setPlaying(true);
            }
          }}
          disabled={!Boolean(previewAudioUrl)}
          style={{
              minWidth: "0px",
              display: "flex",
              justifyContent: "flex-end",
              background: "#2fbdb8",
              color: "#ffffff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              fontSize: "14px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
          }}
        >
          {playing?"Stop":"Play"}
        </Button>
        <Button
          disabled={!Boolean(previewAudioUrl)}
          style={{
              minWidth: "0px",
              display: "flex",
              justifyContent: "flex-end",
              background: "#2fbdb8",
              color: "#ffffff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              fontSize: "14px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
          }}
          onClick={() => {
            fetch(previewAudioUrl, {
              method: "GET",
            })
            .then((resp) => resp.blob())
            .then((blob) => {
              let a =
                document.createElement(
                  "a"
                );
              a.href =
                window.URL.createObjectURL(
                  blob
                );
              a.download =
                title +
                "." +
                previewAudioUrl
                  .split(".")
                  .pop();
              console.log(a);
              a.click();
            });
          }}
        >
          Download
        </Button>
      </div>
      <div className="w-100" style={{ marginTop: "8px" }}>
        <Stack spacing={2} direction="row" sx={{ mb: 2 }} alignItems="center">
          <p
            style={{
              whiteSpace: "nowrap",
              alignItems: "center",
              color: "#19c7e5",
              alignContent: "center",
            }}
            className="h6 single-line"
          >
            Audio Speed
          </p>
          <Slider
            aria-label="Voice Speed"
            min={0.5}
            max={2}
            marks
            step={0.1}
            value={voiceSpeed}
            onChange={handleSliderValue}
            classes={sliderStyleClasses}
            valueLabelDisplay="auto"
          />
        </Stack>
      </div>
      <TextField
          value={editorText}
          onChange={(e)=>{setEditorText(e.target.value)}}
          multiline
          variant="outlined"
          fullWidth
          rows={20}
          InputProps={{
              style: { overflow: 'auto'}
          }}
      />
    </div>
  );
};

export default TextEditor;
