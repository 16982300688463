import React from 'react'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export default function NoDataContainer() {
    return (
        <div className="container text-center">
            <SentimentDissatisfiedIcon fontSize="large"></SentimentDissatisfiedIcon>
            <h4>No data Found!</h4>
        </div>
    )
}
