/* eslint-disable */
import React, { Component } from "react";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";

class PreviewScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // templates:[{id:0,name:"bg1",image:'https://cdn.wallpapersafari.com/48/21/lNELiM.jpg'},{ id:1,name:"bg2",image:'https://th.bing.com/th/id/OIP.KW01HW281sjQve3HvEUGPQHaEK?pid=ImgDet&rs=1'},{id:2,name:"bg3",image:'https://s3.amazonaws.com/spoonflower/public/design_thumbnails/0281/4593/rSimple_Dark_Beige_shop_preview.png'},{id:3,name:"bg4",image:'https://th.bing.com/th/id/OIP.RxfjL6YRjSwrArxkfhAu8AHaEK?pid=ImgDet&rs=1'},{id:4,name:"bg5",image:'https://images.freecreatives.com/wp-content/uploads/2016/04/Teal-Solid-Color-Background-.jpg'},{id:5,name:"bg6",image:'https://www.setaswall.com/wp-content/uploads/2017/04/Eggplant-Solid-Color-Background-Wallpaper-5120x2880.png'}],
      avatar: { width: 0.2, x: 0, y: -0.2 },
      previewSize: { width: 1000 },
    };
    this.avatarRef = React.createRef();
    this.onDragOver = this.onDragOver.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }
  onDragOver(e) {
    e.preventDefault();
  }
  handleStop(e, data) {
    var x = data.x;
    var y = data.y;
  }
  componentDidMount() {
    const previewElement = document.querySelector("#preview");
    const resize_ob = new ResizeObserver((entries) => {
      let area = entries[0].contentRect;
      this.props.setPreview(area);
      // this.setState({...this.state, previewSize:area});
      // console.log(entries);
      // console.log(this.avatarRef.current?this.avatarRef.current.size:null, this.state.avatar)
    });
    resize_ob.observe(previewElement);
  }
  handleEnter(event) {
    // console.log(event);
  }
  render() {
    var avatar_id = this.props.avatar_id;
    var bg_id = this.props.slides[this.props.currentSlide].bg_id;
    var bg_color = this.props.slides[this.props.currentSlide].bg_color;
    var bg_selected;
    var avatar_selected;
    this.props.bg_templates.find((element) => {
      if (element.id === bg_id) {
        bg_selected = (element.image || element.thumbnail);
      }
    });
    if (this.props.slides[this.props.currentSlide].custom_bg_thumbnail_url) {
      bg_selected =
        this.props.slides[this.props.currentSlide].custom_bg_thumbnail_url;
    }
    this.props.avatar_templates.find((element) => {
      if (element.id === avatar_id) {
        avatar_selected = element.thumbnail;
      }
    });
    return (
      <div
        style={{
          position: "relative",
          width: this.props.mainContentWidth,
        }}
      >
        <div
          style={{
            maxWidth: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          {bg_color ? <div id="preview" style={{ backgroundColor: bg_color, width: this.props.mainContentWidth, height: this.props.previewContentHeight }}></div>
          :<div id="preview" style={{ backgroundImage: `url(${bg_selected})`, width: this.props.mainContentWidth, backgroundSize: "100% 100%", height: this.props.previewContentHeight }}></div>
          }
          <Draggable
            bounds="#preview"
            onStop={(e, data) => {
              this.props.setAvatarPosition(
                Math.min(Math.max(data.x / this.props.previewSize.width, 0), 1-this.props.slides[this.props.currentSlide].avatarPosition.width),
                Math.min(Math.max((this.props.previewSize.height + data.y) / this.props.previewSize.width, 0), this.props.previewSize.height/this.props.previewSize.width-this.props.slides[this.props.currentSlide].avatarPosition.width),
              );
            }}
            position={{
              x: this.props.slides[this.props.currentSlide].avatarPosition.x * this.props.previewSize.width,
              y: this.props.slides[this.props.currentSlide].avatarPosition.y * this.props.previewSize.width - this.props.previewSize.height,
            }}
          >
            <Resizable
              size={{
                width:
                  this.props.slides[this.props.currentSlide].avatarPosition
                    .width * this.props.previewSize.width,
                height:
                  this.props.slides[this.props.currentSlide].avatarPosition
                    .width * this.props.previewSize.width,
              }}
              onResizeStop={(e, direction, ref, d) => {
                  let new_width =
                    this.props.slides[this.props.currentSlide].avatarPosition
                      .width +
                    d.width / this.props.previewSize.width;
                  new_width = Math.min(new_width, 1-this.props.slides[this.props.currentSlide].avatarPosition.x, this.props.previewSize.height/this.props.previewSize.width-this.props.slides[this.props.currentSlide].avatarPosition.y)
                  this.props.setAvatarSize(new_width);
                }
              }
              ref={this.avatarRef}
              style={{position: "absolute"}}
              lockAspectRatio={true}
            >
                  <div style={{border:"1px solid black",outline: "1px solid white",width:"100%",height:"100%"}}>
                      <div style={{
                          filter: `${!this.props.avatarVisible ? "blur(3px)" : ""}`,
                          backgroundImage: `url(${avatar_selected})`,
                          outlineOffset: "0",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          width:"100%",
                          height:"100%",
                          borderRadius:this.props.currentAvatarBackgroundColor ? "50%" : 0,
                          backgroundColor:this.props.currentAvatarBackgroundColor 
                        }}/>
                  </div>
            </Resizable>
          </Draggable>
        </div>
      </div>
    );
  }
}
export default PreviewScreen;