/* eslint-disable */
import React, { Component } from "react";
// import axios from "axios";
import PropTypes from "prop-types";
// import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import classNames from "classnames";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// import CircularProgress from "@mui/material/CircularProgress";
// import Box from "@mui/material/Box";
// import VerticalNav  from "container/NavBar/VerticalNav";
// import { getToken } from "common/Authentication";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.videoLink = atob(props.match.params.videoUrl);
    try {
      if (!this.videoLink.startsWith('http') && process.env.REACT_APP_VIDEO_URL_PTTRS) {
        let parts = this.videoLink.split('|');
        let pttrs = process.env.REACT_APP_VIDEO_URL_PTTRS.split(',');
        this.videoLink = pttrs[parseInt(parts[0])].replace('{}', parts[1]);
      }
    } catch {
      console.log('invalid video link');
    }
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem md={10} sm={10}>
              
          </GridItem>
          
        </GridContainer>
          <div className="d-flex justify-content-center align-items-center" style={{ height: 95 + "vh" }}>
            <video height="auto" controls style={{maxWidth:"100%",maxHeight:"450px"}}>
              <source src={this.videoLink} type="video/mp4" />
            </video>
          </div>
      </div>
    );
  }
}

UserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      videoUrl: PropTypes.string.isRequired,
    }),
  }),
};

export default UserPage;
