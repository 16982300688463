import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getToken } from "common/Authentication";

const useStyles = makeStyles({
    radio: {
        "&$checked": {
            color: "#E58200",
        },
    },
    checked: {},
    label: {
        "&.Mui-focused": {
            color: "#E58200",
        },
    },
    select: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E58200",
        },
    },
});

export default function FilterSelector({ section, categories,avatarProperties,updateAvatarProperties }) {

    const classes = useStyles();

    return (
        <div className="container p-3">
                        
            <br />
            <FormControl fullWidth>
               
                {section === "voice" && (
                    <>
                        <InputLabel className={classes.label}>
                            Language
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={avatarProperties.language}
                            label="Language"
                            onChange={(e) => {updateAvatarProperties({...avatarProperties,language:e.target.value})}}
                            className={classes.select}
                        >
                            {categories.languages.map((language, index) => (
                                <MenuItem
                                    key={index}
                                    className="text-capitalize"
                                    value={language}
                                >
                                    {language.toUpperCase()}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}
            </FormControl>
        </div>
    );
}
