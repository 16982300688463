import React from 'react';
import {Form, FormGroup,Input} from 'reactstrap';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import "./ChangePassword.css"
import { getToken } from 'common/Authentication';
import VideoCreatorLogo from 'container/CommonSections/VideoCreatorLogoContainer';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ChangePassword() {
  const [change_password, setChange_password] = React.useState({"old_password": "","new_password":"","confirm_password":""});
  const [open , setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  let token = getToken();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const changePassword = () => {
    console.log(change_password)
    if(change_password.old_password === "")
    {
      setMessage("old password should not be empty");
      setSeverity("error")
      setOpen(true);
      return;
    }
    if(change_password.new_password === "")
    {
      setMessage("new password should not be empty");
      setSeverity("error")
      setOpen(true);
      return;
    }
    if(change_password.confirm_password === "")
    {
      setMessage("confirm password should not be empty");
      setSeverity("error")
      setOpen(true);
      return;
    }
    if(change_password.new_password !== change_password.confirm_password)
    {
      setMessage("new password and confirm password should be same");
      setSeverity("error")
      setOpen(true);
      return;
    }

    let jsonRequest = JSON.stringify({old_password: change_password.old_password,new_password: change_password.new_password})
    let URL = "/auth/change_password";
    fetch(URL, {method:'POST', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest }).then(res=>{
      if(res.status === 200){
          return res.json();
      } else if(res.status === 401)
      {
        setMessage("old password is incorrect");
        setSeverity("error")
        setOpen(true);
        throw res.json()
      }  else if(res.status === 400)
      {
        setMessage("please try again");
        setSeverity("error")
        setOpen(true);
        throw res.json()
      } else {
        setMessage("Failed to update the password! Please check the credentials and try again after sometime.");
        setSeverity("error")
        setOpen(true);
        throw res.json()
      }
    })
    .then((res) => {
        console.log(res);
        setMessage("Updated your password successfully.");
        setSeverity("success")
        setOpen(true);
    }).catch(e=>{
        console.log(e);
    })
  }

  return (
      <>
        <VideoCreatorLogo />
        <div className='changePassword'>          
          <Form className='changePassword_form'>
            <FormGroup>
              <Input 
                type="password" 
                name="password" 
                id="old_password" 
                value={changePassword.old_password} 
                placeholder='Old Password' 
                className='input_style_profile'
                onChange={ (e)=>{
                  setChange_password({...change_password, "old_password": e.target.value})
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input type="password" 
                name="password" 
                id="new_password" 
                value={changePassword.new_password} 
                placeholder="New Password" 
                className='input_style_profile' 
                onChange={ (e)=>{
                  setChange_password({...change_password, "new_password": e.target.value})
                }}
              />
            </FormGroup>
            <FormGroup>
              <Input type="password" 
                name="password" 
                id="confirm_password" 
                value={changePassword.confirm_password} 
                placeholder="Confirm Password" 
                className='input_style_profile' 
                onChange={ (e)=>{
                  setChange_password({...change_password, "confirm_password": e.target.value})
                }}
              />
            </FormGroup>
            
            <div onClick={() => {
                changePassword();
              }}
              className='change_button'
            >
                Confirm
            </div>
          </Form>
        </div>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

      </>
  );
}

export default ChangePassword;
