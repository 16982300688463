import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const useStyles = makeStyles({
    tabsStyle: {
        "&.MuiTabs-indicator": {
            padding: 0 ,
            backgroundColor: 'grey',
            textTransform: "none",
            fontWeight:"700"
        },
    },
    tabStyle: {
        "&.MuiTab-root": {
            padding: 2,
            color: "#c0c0c0",
            outline: "none",
            textTransform: "none",
            fontWeight:"700",
            height: "20px"
        },
        "&.Mui-selected": {
            padding: 2,
            color: "#00c7e5",
            backgroundColor: 'white',
            textTransform: "none",
            fontWeight:"700"
        },
    },
});

const ThemeTabView = (props) => {
    const handleTabChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    const classes = useStyles();

    const tabsStyle = {
        indicator: classes.tabsStyle,
    };

    const tabStyleClasses = {
        root: classes.tabStyle,
        selected: classes.tabStyle,
    };

    return (
        <>
            <Tabs
            style={{background: "#8E8E8E",borderRadius: "8px" ,marginLeft:'20px',marginRight:'20px'}}
                value={props.tabValue}
                onChange={handleTabChange}
                aria-label="icon position tabs example"
                classes={tabsStyle}
                variant="fullWidth"
            >
                {props.tabs.map((tab, index) => (
                    <Tab
                    sx={{ px: 1 }} 
                    style={{borderRadius: "8px",margin:'7px'}}
                        key={index}
                        value={tab.value} 
                        label={tab.label}
                        classes={tabStyleClasses}
                    />
                ))}
            </Tabs>
        </>
    );
};

export default ThemeTabView;
