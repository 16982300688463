import React from 'react';
import NavBarVertical from "../VerticalNavBar/NavBarVertical";
import HomeIcon from "assets/img/iconsSidebar/Home.png";
import DatabaseIcon from "assets/img/iconsSidebar/database.png";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import {checkUser} from "common/Authentication";

import ChangePassword from './ProfileSections/ChangePassword';
import AdminSettings from './ProfileSections/AdminSettings';
import DisseminationSettings from './ProfileSections/DisseminationSettings';
import BillingInfo from './ProfileSections/BillingInfo';
import SideNavBar from './ProfileSections/SideNavBar';
import PowerSettingNewIcon from '@mui/icons-material/PowerSettingsNew';
import { logout } from "common/Authentication";
import LowCreditAlert from 'container/CommonSections/LowCreditAlert.js'
import {useParams, useHistory} from 'react-router-dom'

function Main() {
  // const [section, setSection] = React.useState(1);
  const [userDetails, setUserDetails] = React.useState([]);

  React.useEffect( () => {
      async function fetchInfo() {
          let data = await checkUser();
          // console.log(data)
          setUserDetails(data)
      }
      fetchInfo()
  }, [])
  // console.log(userDetails);

  const {section} = useParams()
  const history = useHistory()

  return (
      <div className="d-flex" style={{ height: "100vh" }}>
      
      <NavBarVertical
        styleNavBarVertical = {{
          cursor: "pointer"
        }}
        // updateSideBarOpen={(ele) => {
        //   // console.log(ele)
        //   if(ele === 1)
        //   {
        //     window.location.href = '/app';
        //   }
        //   if(ele === 2)
        //   {
        //     window.location.href = '/app/report';            
        //   }
        // }}
        sideBarBottomButtons={[      
          {
            name:"logout",
            icon_component: PowerSettingNewIcon,
            onClick: () => {
              logout();
              window.location.href = "/app"},
          } ,
          {
            name: "settings",
            icon_component: SettingsOutlinedIcon,
            onClick: () => {window.location.href = "/app/profile"},
          },
          {
            name: "home",
            icon: HomeIcon,
            onClick:() => {window.location.href = "/app"},
          },
          {
            name: "database",
            icon: DatabaseIcon,
            onClick:() => {window.location.href = "/app/report"},
          }
        ]}
      />
      
      <div style={{ background: "#E8E8E8", paddingTop: "65px" }}>
        <SideNavBar setSection = {(section)=>history.push('/app/profile/'+section)} userDetails={userDetails} section = {section || 'changePassword'} />
      </div>
      <div style={{width:'100%', height:'100%'}}>
        <LowCreditAlert />
        {
          !section && <ChangePassword />
        }
        {
          section === 'changePassword' &&
          <ChangePassword />
        }
        {
          section === 'billingInfo' &&
          <BillingInfo user={userDetails}/>
        }
        {
          section === 'adminSettings' && userDetails.admin &&
          <AdminSettings user={userDetails}/>
        }
        {
          section === 'disseminationSettings' && userDetails.admin &&
          <DisseminationSettings user={userDetails}/>
        }
      </div>

      </div>
  );
}

export default Main;
