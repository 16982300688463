/* eslint-disable */
import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { getToken } from "common/Authentication";
import ListSection from "./Sections/ListSection";
import VideoCreatorLogo from "assets/img/video_icon.png";
import {GENERATE_REPORT} from "./APIs";
import moment from 'moment';
import Filters from "./Sections/Filters";
import "./st.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import classNames from "classnames";
import NavBarVertical from "../Editor/NavBarVertical";
import HomeIcon from "assets/img/iconsSidebar/Home.png";
import DatabaseIcon from "assets/img/iconsSidebar/database.png";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { logout } from "common/Authentication";
import PowerSettingNewIcon from "@mui/icons-material/PowerSettingsNew";
import LowCreditAlert from "container/CommonSections/LowCreditAlert";
import WhatsAppModel from "container/CommonSections/WhatsAppModel/WhatsAppModel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Report(props) {
    const [arr, setArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openWhatsAppModel,setWhatsAppModel]=useState(false);
    const [shareButtonRequestId,setShareButtonRequestId]=useState(null);
  
    const snackBarVariantsMap = {
      error: "error",
      warning: "warning",
      info: "info",
      success: "success",
    };
    const [snackBarDetails, setSnackBarDetails] = useState([]);
    const [selected_urls, setSelectedUrls] = useState({});

    const {classes} = props;

    function requestForReport(filters) {
      let formdata = {};
      if(filters.response_status && filters.response_status !== "all"){
        formdata.response_status = filters.response_status;
      }
      if(filters.template_id && filters.template_id !== "all"){
        formdata.template_id = filters.template_id;
      }
      if(filters.title_prefix && filters.title_prefix !== ""){
        formdata.title_prefix = Buffer.from(filters.title_prefix).toString('base64');
      }
      if(filters.avatar && filters.avatar !== "all"){
        formdata.avatar = filters.avatar;
      }
      if(filters.voice && filters.voice !== "all"){
        formdata.voice = filters.voice;
      }
      if(filters.request_ids && filters.request_ids.length !== 0){
        formdata.request_ids = filters.request_ids.join('|');
      }

      formdata.create_time_gt = moment(filters.create_time_gt).format('YYYY-MM-DDTHH:mm:ss');
      formdata.create_time_lt = moment(filters.create_time_lt).format('YYYY-MM-DDTHH:mm:ss');
      formdata.type = 't2v';

      console.log("Formdata: ", formdata);
      axios({
        method: GENERATE_REPORT.method,
        url: GENERATE_REPORT.endpoint,
        headers: {
          Authorization: 'Bearer ' + getToken(),
          RequestTime: new Date()
        },
        params: formdata,
      })
      .then(r => {
          if(r.status === 200){
            return r.data.data;
          } 
          else {
            return null;
          }
      })
      .then(r => {
        let data = [];
        if(r !== null){
          data = r.synthesis_requests;
        }
        setArr(data);
        setSelectedUrls([]);
        openSnackBar("Retrieved reports succesfully", snackBarVariantsMap.success);
      })
      .catch(e => {
        console.error('Failed to retrieve report', e, snackBarVariantsMap.error);
        openSnackBar("Failed to retrieve report", snackBarVariantsMap.error);
      })
      console.log('requested for report', filters);
    }

    function downloadAll() {
      console.log('sel: ', selected_urls);
      openSnackBar("Please allow pop-up window for bulk downloads, if not allowed already!", snackBarVariantsMap.info);
      let invalidSelect = false;
      Object.entries(selected_urls).map((elem)=>{
        if(elem[1].url){
          fetch(elem[1].url, {method: 'GET'}).then((resp)=>resp.blob()).then((blob)=>{
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = elem[1].title+"."+elem[1].url.split(".").pop();
            console.log(a);
            a.click();
          });
        }else{
          invalidSelect = true;
        }
      });
      if(invalidSelect){
        openSnackBar("One or more selected requests are not in success state!", snackBarVariantsMap.error);
      }
    }
    useEffect(() => {
        // SYNTHESIS REQUEST LIST
      let formdata = {};
      formdata.create_time_gt = moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
      formdata.create_time_lt = moment().format('YYYY-MM-DDTHH:mm:ss');
      formdata.type = 't2v';
      formdata.limit = 10;
        axios({
          method: GENERATE_REPORT.method,
          url: GENERATE_REPORT.endpoint,
          headers: {
            Authorization:'Bearer ' + getToken(),
            RequestTime:new Date()
          },
          params: formdata
        })
        .then((res) => {
            console.log("Res: ", res.data.data.synthesis_requests);
            setArr(res.data.data.synthesis_requests);
            // if empty, NO VIDEOS TO SHOW
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            openSnackBar(err, snackBarVariantsMap.error);
        })
    }, []);

    // SnackBar
    function openSnackBar (message, variant) {
      setSnackBarDetails((prevSnackBarDetails)=>[...prevSnackBarDetails, {open:true, message:message, variant:variant}]);
    };
    const updateShareButtonRequestId=(request_id)=>{
      setWhatsAppModel(!openWhatsAppModel);
      setShareButtonRequestId(request_id);
    }
    return(
      <>
        <GridContainer>
            <WhatsAppModel 
                shareButtonRequestId={shareButtonRequestId}
                openWhatsAppModel={openWhatsAppModel} 
                setWhatsAppModel={setWhatsAppModel}
            />
        </GridContainer>
        <div className="d-flex" style={{height: "100vh"}}>
            <NavBarVertical
                sideBarBottomButtons={[
                {
                  name: "logout",
                  icon_component: PowerSettingNewIcon,
                  onClick: () => {
                      logout();
                      window.location.href = "/app"},
                  },
                {
                    name: "settings",
                    icon_component: SettingsOutlinedIcon,
                    onClick: () => {window.location.href = "/app/profile"},
                },
                {
                    name: "home",
                    icon: HomeIcon,
                    onClick: () => props.history.push("/app"),
                },
                {
                    name: "database",
                    icon: DatabaseIcon,
                    onClick: () => props.history.push("/app/report"),
                },
                ]}
            />
            <div className={classNames('_font', 'bg-light')} style={{overflowY:"scroll", overflowX: 'hidden'}}>
              <LowCreditAlert />
              <GridContainer>
                  {/* ROW 1 */}
                  <GridItem md={10} sm={10} xs={2}>
                  </GridItem>
                  <GridItem md={2} sm={2} xs={10}>
                      <img src={VideoCreatorLogo} alt="VideoCreatorLogo" style={{marginTop: "20%", marginBottom: "45%"}} />
                  </GridItem>

                  {/* ROW 2 */}
                  <GridItem md={1} sm={1}>
                  </GridItem>
                  <GridItem md={10} sm={10} xs={11} style={{marginBottom: '0px'}}>
                      <Filters classes={classes} handleFilter={requestForReport} handleDownloadAll={downloadAll} />
                  </GridItem>
                  <GridItem md={1} sm={1} xs={1}>
                  </GridItem>

                  {/* ROW 3 */}
                  <GridItem md={1} sm={1} xs={1}>
                  </GridItem>
                  <GridItem md={10} sm={10} xs={10} style={{marginTop: '0px'}}>
                      {loading ? "Loading . . " : !arr.length ? <h2 className="m-5 text-center">No reports to show for the given query!</h2> : <ListSection updateShareButtonRequestId={updateShareButtonRequestId} arr={arr} selected_urls={selected_urls} setSelectedUrls={setSelectedUrls} />}
                  </GridItem>
                  <GridItem md={1} sm={1} xs={1}>
                  </GridItem>
              </GridContainer>
              <div>
                <Stack sx={{ position: "absolute", bottom: 0, left: 0 }} spacing={2}>
                {snackBarDetails.map((snackbar, idx)=>
                  <Snackbar
                      open={snackbar.open}
                      autoHideDuration={6000}
                      style={{position:"relative"}}
                      onClose={(event, reason)=>{if (reason === "clickaway") return; snackbar.open=false; setSnackBarDetails([...snackBarDetails])}}
                  >
                    <Alert
                        onClose={(event, reason)=>{if (reason === "clickaway") return; snackbar.open=false; setSnackBarDetails([...snackBarDetails])}}
                        severity={snackbar.variant}
                        sx={{ width: "100%" }}
                    >
                        {snackbar.message}
                    </Alert>
                  </Snackbar>
                )}
                </Stack>
              </div>
            </div>
        </div>
      </>
    )
}

export default Report;