import {useEffect} from "react";
import { Modal,ModalBody,ModalHeader } from "reactstrap";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import React,{useState} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from '@mui/material/TextField';

/*eslint-disable*/
const GenerateBackground = ({ pexelBackgrounds, onBgChange,updatePexelPageOrSearch,isPexelImagesOrVideos,isPexelsSuccessVideos,isPexelsSuccessImg }) => {
  const [open,setOpen]=useState(false);
  const [currentVideoLink,setCurrentVideoLink]=useState(null);
  const [searchText,setSearchText]=useState(null);
  const currentVideo=(video,image)=>{
      if(isPexelImagesOrVideos === false){
        setCurrentVideoLink(video);
        setOpen(true);
        onBgChange({custom_bg_url:video,custom_bg_thumbnail_url:image},"custom_bg");
      } else {
        onBgChange({custom_bg_url:image,custom_bg_thumbnail_url:image},"custom_bg");
      }
  }
  return (
    <>
    
        <div
            style={{
                display: "grid",
                height: "100%"
            }}
            className="panel-entity-container"
        >           
            {(isPexelsSuccessVideos || isPexelsSuccessImg) && 
               <TextField 
               id="standard-basic" 
               label={isPexelImagesOrVideos ? "Search Images" : "Search Videos"}
               variant="standard"
               style={{width:"100%"}}
               onChange={(e)=>setSearchText(e.target.value)}
               InputProps={{
                 endAdornment: (
                   <InputAdornment>
                       <SearchIcon 
                         style={{cursor:"pointer"}}
                         onClick={()=>updatePexelPageOrSearch(searchText,true,isPexelImagesOrVideos)}
                       />
                   </InputAdornment>
                 )
               }}            
               />
            }
            <div/>
            <div  style={{
                marginTop: "10px",
                display: "grid",
                gridTemplateColumns: "245px 245px",
                gridGap: "10px",
                height: "100%"
            }}>
            {pexelBackgrounds.map((background) => {
              const { id, name, image, thumbnail,video} = background;
              return (
                <div key={id}>
                  <img
                    style={{ borderRadius: "5px",cursor:"pointer"}}
                    src={image || thumbnail}
                    width={245}
                    height={100}
                    alt={name}
                    onClick={()=>currentVideo(video,image)}
                  />
                </div>
              );
            })}<br/>
            </div>
            
            <div
                style={{
                    marginBottom:"5px",
                    gridColumn: "1 / 3",
                    textAlign: "center"
                }}
            >
                {(isPexelsSuccessVideos || isPexelsSuccessImg) && 
                   <Button 
                   variant="contained"
                   style={{
                       borderRadius:"50%",
                       backgroundColor:"#0a0a23",
                       width:"60px",
                       height:"60px",
                       fontSize:"10px",
                       letterSpacing:"2px"
                     }}
                   onClick={()=>updatePexelPageOrSearch("",false,isPexelImagesOrVideos)}
                 >
                   See More
                 </Button>
                }
            </div>
          </div>
          <Modal isOpen={open} toggle={()=>setOpen(!open)}
                  style={{width:"100vw",height:"500px"}}
                  centered
            >
                <ModalHeader className='m-3'>
                      <Button 
                          style={{position:"absolute",right:"10px",top:"12px"}}
                          onClick={()=>setOpen(!open)}
                      >
                      <CloseIcon
                          style={{textAlign:"center",color:"#E7901F"}}
                      />
                      </Button>
                </ModalHeader>
                <ModalBody style={{height:"300px"}}>
                    <video width="100%" height="100%" controls>
                      <source src={currentVideoLink} type="video/mp4"></source>
                    </video>  
                </ModalBody>
          </Modal>
      </>
  );
};

export default GenerateBackground;