/* eslint-disable */
import React, { Component } from "react";
import "./DropzoneStyle.css";
import Loader from  'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hightlight: false,
      file: null,
    };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    this.props.onFilesAdded(files[0]);
    this.setState({ file: files[0] });
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabled) return;
    const files = event.dataTransfer.files;
    this.props.onFilesAdded(files[0]);
    this.setState({ hightlight: false, file: files[0] });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const upload_status_loader = ()=>{
      switch(this.props.status){
        case "pending":
          return <Loader type="Oval" height="24" width="24" color="grey"/>
        case "success":
          return <CheckCircleOutlineIcon/>
        case "fail":
          return <ErrorOutlineIcon/>
        default:
          return <></>
      }
    }
    return (
      <div
        style={{
          width: "100%",
          margin: "10px",
          ...this.props.containerStyle,
        }}
      >
        <div
          className="dropzone-container"
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{
            height: "100%",
            cursor: this.props.disabled ? "default" : "pointer",
            ...this.props.containerStyle,
          }}
        >
          <input
            ref={this.fileInputRef}
            className="file-input"
            type="file"
            multiple={false}
            onChange={this.onFilesAdded}
          />
          <div className="d-flex flex-row">
            {this.props.status && upload_status_loader()}
            {this.props.file && this.props.file.name}
          </div>
          <img
            alt="upload"
            className="upload-icon"
            src="https://img.icons8.com/ios/50/000000/upload-to-cloud--v1.png"
          />
          <span>Upload Files</span>
        </div>
      </div>
    );
  }
}

export default Dropzone;
