/* eslint-disable */
import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Delete, Add } from "@material-ui/icons";
import Fade from "@mui/material/Fade";
import SlideUp from "@mui/material/Slide";
import CloseIcon from '@mui/icons-material/Close';
class GenerateSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onHover: false,
    };
    this.refToParentNode = React.createRef();
  }

  toggleOnHoverOverSlide = () => {
    this.setState((prevState) => {
      return { onHover: !prevState.onHover };
    });
  };

  render() {
    return (
      <div
        style = {{position: "relative", paddingTop: "10px",marginLeft:"20px"}}
        onMouseEnter={this.toggleOnHoverOverSlide}
        onMouseLeave={this.toggleOnHoverOverSlide}
      >
      
       
        <div ref={this.refToParentNode}>
          <div
           
            style={{
              background: this.props.backColor ? this.props.backColor : `url('${this.props.backUrl}') center /cover no-repeat`,
              width: "200px",
              height: "120px",
              minHeight: "120px",
              borderRadius: "10px",
              outline:
                this.props.isCurrent === this.props.slideNumber
                  ? "2px solid black"
                  : "2px solid transparent",
              outlineOffset: "2px",
              margin: "5px 0px",
            }}
            onClick={() =>
              this.props.changeCurrentSlide(this.props.slideNumber - 1)
            }
          >
            <Button
             
              style = {{
                marginLeft:"70%",
                backgroundColor: "#0000080",
                visibility: this.state.onHover?"visible":"hidden",
                outline: "0px",
              }}
              onClick={(e) => {
                this.props.deleteSlide(this.props.slideNumber - 1);
                e.stopPropagation();
              }}
            >
              <CloseIcon  style={{ color: "grey" }}/>
            </Button>
          </div>
        </div>
        <p
            style={{
              fontFamily: "Rubik",
              fontStyle: "italic",
              fontWeight: "normal",
              fontSize: "15px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#000000",
              marginTop: "8px",
            }}
          >
            Slide {this.props.slideNumber}
        </p>
       
          <div style={{
              visibility: this.state.onHover?"visible":"hidden",
              content: "",
              position: "absolute",
              top: "63%",
              left: "47%",
              borderWidth: "5px",
              borderStyle: "solid",
              borderColor: "transparent transparent #00000080 transparent",
            }}>
          </div>
      </div>
    );
  }
}

export default GenerateSlide;
