/* eslint-disable */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import axios from "axios";
import Templates from "./Sections/Templates";
import { getToken } from "common/Authentication";
import CreateNew from "./Sections/CreateNew";
import ListSection from "./Sections/ListSection";
import NavBarVertical from "../Editor/NavBarVertical";
import HomeIcon from "assets/img/iconsSidebar/Home.png";
import DatabaseIcon from "assets/img/iconsSidebar/database.png";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import classNames from "classnames";
import { logout } from "common/Authentication";
import PowerSettingNewIcon from "@mui/icons-material/PowerSettingsNew";
import LowCreditAlert from "container/CommonSections/LowCreditAlert";
import InfiniteScroll from "react-infinite-scroll-component";
import Table from "components/Table/Table.js";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { format } from "date-fns";

import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { Button } from "reactstrap";
import WhatsAppModel from "container/CommonSections/WhatsAppModel/WhatsAppModel";
import PlayButton from "container/CommonSections/PlayButton";
import ShareButton from "container/CommonSections/ShareButton";
import download from "./download.png";

const useStyles = makeStyles(imagesStyles);

function Landing(props) {
  const classes = useStyles();
  const [arr, setArr] = useState([]);
  const [rows, setRow] = useState([]);
  const [openWhatsAppModel, setWhatsAppModel] = useState(false);
  const [templateList, setTemplateList] = useState(null);
  const [shareButtonRequestId, setShareButtonRequestId] = useState(null);
  const [postNumber, setPostNumber] = useState(0);

  const fetchData = (num) => {
    axios({
      method: "GET",
      url: "/t2v/report/fetch",
      headers: {
        Authorization: "Bearer " + getToken(),
        RequestTime: new Date(),
      },
      params: {
        limit: 10,
        type: "t2v",
        start: num,
      },
    }).then((res) => {
      console.log("Res: ", res.data.data.synthesis_requests);
      setPostNumber((prevPostNumber) => prevPostNumber + 10); // setArr(res.data.data.synthesis_requests);
      setArr((prevArr) => prevArr.concat(res.data.data.synthesis_requests));
    });
  };

  useEffect(() => {
    fetchData(postNumber);
  }, []);

  const updateShareButtonRequestId = (request_id) => {
    setWhatsAppModel(!openWhatsAppModel);
    setShareButtonRequestId(request_id);
  };

  const parseDate = (request_id) => {

    try {
     return format(
        new Date(request_id),
        "dd:MM:yyyy"
      )
    } catch (error) {
      return 'Invalid date';
    }
    
  };

  const parseTime = (request_id) => {

    try {
     return format(
      new Date(request_id),
      "hh:mm a"
    )
    } catch (error) {
      return '';
    }
    
  };

  async function deleteTemplateById(templateId) {
    try {
      const response = await axios.delete("/t2v/template/", {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          id: templateId,
        },
      });

      if (response.status === 200) {
        setTemplateList(
          templateList.filter((template) => template.id !== templateId)
        );
      }
    } catch (error) {
      alert("Failed to delete item " + error.message);
    }
  }

  // window.addEventListener("scroll", handleScroll);


  return (
    <div className="d-flex" style={{ height: "90%" }}>
      <div
        style={{ width: "90%", height: "85%" }}
        className={classNames("_font", "bg-light", "w-100")}
      >
        <LowCreditAlert />
        <CreateNew  />
        <div
          style={{ display: "flex", height: "90%", flexDirection: "column" }}
        >
          <div
            style={{
              marginTop: "20px",
              marginLeft: "40px",
              color: "grey",
              fontSize: "20px",
              fontWeight: "900",
            }}
          >
            Generated Videos
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "scroll",
            }}
          >
            <div style={{ paddingTop: "2vh" }}>
              <InfiniteScroll
                dataLength={arr.length}
                next={() => {
                  fetchData(postNumber);
                }}
                hasMore={true}
                loader={
                  <Loader
                    style={{ alignItems: "center", marginLeft: "140px" }}
                    type="ThreeDots"
                    color="#00BFFF"
                    height={54}
                    width={54}
                  />
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {/* <div style={{ height: "74vh", width: "100%" }}>
              <ListSection
                    arr={arr}
                    updateShareButtonRequestId={updateShareButtonRequestId}
                  />
              </div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",

                    minWidth: "100vh",
                  }}
                >
                  {arr.map((req, idx) => {
                    // return (
                    //   <>

                    //   </>)
                    return (
                      <>
                        <div style={{ marginTop: "15px" }} key={idx}>
                          <div
                            style={{
                              marginLeft: "20px",
                              display: "flex",
                              flexDirection: "column",
                              width: "95%",
                              minWidth: "300px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#e4e4e4",
                                fontFamily: "Rubik",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "16px",
                                lineHeight: "19px",
                                borderRadius: "8px",
                                marginLeft: "10px",

                                width: "100%",
                                display: "flex",
                                borderRadius: "10px",
                                flexDirection: "column",
                              }}
                            >
                              <GridContainer style={{ padding: "15px" }}>
                                <GridItem sm={4} mmd={4}>
                                  {req.thumbnail &&
                                  req.thumbnail.split(":")[0] == "color" ? (
                                    <div
                                      style={{
                                        borderRadius: "10px",
                                        backgroundColor: req.thumbnail
                                          .split(":")
                                          .slice(1)
                                          .join(":"),
                                          border: "1.5px solid grey", 
                                        width: "400px",
                                        height: "200px",
                                      }}
                                    ></div>
                                  ) : (
                                    <div
                                      style={{
                                        borderRadius: "10px",
                                        width: "400px",
                                        height: "240px",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderColor: 'grey', borderWidth: '5px',
                                        backgroundImage: `url(${
                                          req.thumbnail &&
                                          req.thumbnail.split(":")[0] == "url"
                                            ? req.thumbnail
                                                .split(":")
                                                .slice(1)
                                                .join(":")
                                            : "https://images.unsplash.com/photo-1500530855697-b586d89ba3ee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
                                        })`,
                                      }}
                                    />
                                  )}
                                </GridItem>
                                <GridItem
                                  sm={3}
                                  md={3}
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    style={{ marginTop: "10px", width: "100%" }}
                                  >
                                    <div
                                      style={{
                                        fontFamily: "Rubik",
                                        fontStyle: "normal",
                                        fontWeight: "normal",
                                        fontSize: "22px",
                                      }}
                                    >
                                      {req.title}
                                    </div>
                                    <br />
                                    <div style={{marginBottom:"3px",color:"grey"}}>
                                      {" "}
                                      { parseDate(req.create_time)}{" "}
                                      {parseTime(req.create_time)}
                                    </div>

                                    <p
                                      style={
                                        req.response_status === "success"
                                          ? {
                                              color: "white",
                                              border: "none",
                                              background: "none",
                                              borderRadius: "8px",
                                              background: "#499037",
                                              display: "inline-block",
                                              paddingLeft:"6px",
                                              paddingRight:"6px"
                                            }
                                          : req.response_status === "pending"
                                          ? {
                                            background: "#00c7e5",
                                              border: "none",
                                              borderRadius: "8px",
                                              color: "white",
                                              display: "inline-block",
                                              paddingLeft:"6px",
                                              paddingRight:"6px"
                                            }
                                          : req.response_status === "SAVED"
                                          ? {
                                            background: "#68BC00",
                                              border: "none",
                                              borderRadius: "8px",
                                              color: "white",
                                              display: "inline-block",
                                              paddingLeft:"6px",
                                              paddingRight:"6px"
                                            }
                                          : {
                                            background: "red",
                                              border: "none",
                                              borderRadius: "8px",
                                              color: "white",
                                              display: "inline-block",
                                              paddingLeft:"6px",
                                              paddingRight:"6px"
                                            }
                                      }
                                    >
                                      {req.response_status}
                                    </p>
                                  </div>
                                </GridItem>
                                <GridItem md={5} sm={5}>
                                  <GridContainer
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      padding: "15px",
                                    }}
                                  >
                                    <GridItem sm={12} mmd={12}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "start",
                                          width: "100%",

                                          flexWrap: "wrap",
                                          height: "100%",
                                        }}
                                      >
                                        {/* <br /> */}
                                        {req.response_status === "success" ? (
                                          <>
                                            <PlayButton
                                              style={{
                                                width: "100%",
                                              }}
                                              videoUrl={req.response_url}
                                            >
                                              Play
                                            </PlayButton>
                                            <Button
                                              onClick={() => {
                                                fetch(req.response_url, {
                                                  method: "GET",
                                                })
                                                  .then((resp) => resp.blob())
                                                  .then((blob) => {
                                                    let a =
                                                      document.createElement(
                                                        "a"
                                                      );
                                                    a.href =
                                                      window.URL.createObjectURL(
                                                        blob
                                                      );
                                                    a.download =
                                                      req.title +
                                                      "." +
                                                      req.response_url
                                                        .split(".")
                                                        .pop();
                                                    console.log(a);
                                                    a.click();
                                                  });
                                              }}
                                              style={{
                                                minWidth: "0px",
                                                justifyItems: "right",
                                                marginRight: "10px",
                                                background: "#2196F3",
                                                color: "#ffffff",
                                                border: "none",
                                                padding: "7px 15px",
                                                borderRadius: "12px",
                                                fontSize: "14px",
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                marginRight: "10px",
                                              }}
                                            >
                                              DOWNLOAD
                                              <div>
                                              <svg style={{marginLeft:"10px",fill:"white"}} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/></g></svg>
                                              </div>
                                            </Button>

                                            <ShareButton
                                              downloadLink={req.response_url}
                                              shareButtonRequestId={req.id}
                                              updateShareButtonRequestId={
                                                updateShareButtonRequestId
                                              }
                                              style={{
                                                minWidth: "0px",
                                                display: "inline-block",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </GridItem>

                                    <GridItem sm={12} md={12}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          // alignItems: "center",
                                          width: "100%",
                                          justifyContent: "flex-end",
                                          alignItems: "end",
                                          flexWrap: "wrap",
                                          height: "100%",
                                        }}
                                      >
                                        {/* <br /> */}
                                        {req.response_status === "success" ? (
                                          <>
                                            <Link to={`/app/editor/${req.id}`}>
                                              <Button
                                                style={{
                                                  minWidth: "0px",
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#2fbdb8",
                                                  color: "#ffffff",
                                                  border: "none",
                                                  padding: "10px 20px",
                                                  borderRadius: "5px",
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                Edit in Console
                                                <svg style={{marginLeft:"10px",fill:"white"}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.41 5.8L17.2 4.59c-.78-.78-2.05-.78-2.83 0l-2.68 2.68L3 15.96V20h4.04l8.74-8.74 2.63-2.63c.79-.78.79-2.05 0-2.83zM6.21 18H5v-1.21l8.66-8.66 1.21 1.21L6.21 18zM11 20l4-4h6v4H11z"/></svg>

                                              </Button>
                                            </Link>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {req.response_status !== "success" ? (
                                          <>
                                            <Link to={`/app/editor/${req.id}`}>
                                              <Button
                                                style={{
                                                  minWidth: "0px",
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  background: "#2fbdb8",
                                                  color: "#ffffff",
                                                  border: "none",
                                                  padding: "10px 20px",
                                                  borderRadius: "5px",
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                Edit in Console
                                                <svg style={{marginLeft:"10px",fill:"white"}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.41 5.8L17.2 4.59c-.78-.78-2.05-.78-2.83 0l-2.68 2.68L3 15.96V20h4.04l8.74-8.74 2.63-2.63c.79-.78.79-2.05 0-2.83zM6.21 18H5v-1.21l8.66-8.66 1.21 1.21L6.21 18zM11 20l4-4h6v4H11z"/></svg>
                                              </Button>
                                            </Link>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Landing;
