import React from 'react';
import "./AdminSettings.css";
import { Modal, ModalBody, Form, FormGroup, Input } from "reactstrap";
import TrashLogo from "assets/img/trash.svg";
import EditLogo from "assets/img/edit.svg";
import Switch from '@mui/material/Switch';
import { getToken } from 'common/Authentication';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VideoCreatorLogo from 'container/CommonSections/VideoCreatorLogoContainer';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AdminSettings(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [addUser, setAddUser] = React.useState({"email":"","password":"","admin":false,"is_active":false});
  const [users, setUsers] = React.useState([]);
  const [editUser, setEditUser] = React.useState({"uid" : "", "email_id":"", "password":"", "admin":false, "is_active":false});
  const [refresh, setRefresh] = React.useState(false);
  const userInfo = props.user;
  // console.log(userInfo);
  
  //snackbar options
  const [snackOpen , setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("");

  let token = getToken();
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  const toggleEdit = () => {
    setEditModalOpen(!editModalOpen);
  };

  const handleAdminChange = (event) => {
    // console.log(event.target.checked);
    setAddUser({...addUser, "admin": event.target.checked })
  };

  const handleActiveChange = (event) => {
    setAddUser({...addUser, "is_active": event.target.checked })
  };

  const handleEditAdminChange = (event) => {
    // console.log(event.target.checked);
    setEditUser({...editUser, "admin": event.target.checked })
  };

  const handleEditActiveChange = (event) => {
    setEditUser({...editUser, "is_active": event.target.checked })
  };

  React.useEffect(() => {
    fetch("/admin/users", 
        {
            method:'GET', 
            headers: 
                { 
                    Authorization: `Bearer ${token}`,
                    RequestTime:new Date()
                }
        })
    .then(res => {
        if(res.status === 200){
            return res.json();
        }}
    )
    .then(data => {
        let res = data.data.users.filter(user => {return user.uid !== userInfo.uid})
        
        // console.log(res)      
        setUsers(res);  
    })
  },[refresh])

  const refreshList = () => {
    setRefresh(!refresh);
  }

  const submitEdit = () => {
    let editURL = "/admin/edit_user";
    let jsonRequest;
    if(editUser.password !== "")
    {
      jsonRequest = JSON.stringify(editUser);
    }
    else
    {
      jsonRequest = JSON.stringify({uid: editUser.uid , admin: editUser.admin, is_active: editUser.is_active})
    }
    console.log(jsonRequest); 
    fetch(editURL, {method:'POST', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest}).then(res=>{
        if(res.status === 201){
            return res.json();
        } else {
          setSnackMessage("error occured while editing");
          setSnackOpen(true);
          setSnackSeverity("error");
          throw "temp";
        }
      })
    .then((res) => {   
        console.log(res);
        setEditModalOpen(false);
        setSnackMessage("edit was successful");
        setSnackOpen(true);
        setSnackSeverity("success");
        refreshList(); 
    }).catch(e=>{
        console.log(e);
    })
  }

  const handleAddUser = () => {
    if(addUser.email === "")
    {
      setSnackMessage("email id is required");
      setSnackOpen(true);
      setSnackSeverity("error");
      return;
    }
    
    var re = /\S+@\S+\.\S+/;
    if(!re.test(addUser.email))
    {
        setSnackMessage("enter proper email");
        setSnackOpen(true);
        setSnackSeverity("error");
        return;
    }

    if(addUser.password === "")
    {
      setSnackMessage("password is required");
      setSnackOpen(true);
      setSnackSeverity("error");
      return;
    }
    let jsonRequest = JSON.stringify(addUser);
    console.log(jsonRequest);
    let URL = "/admin/add_user";
    let token = getToken();
    fetch(URL, {method:'POST', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest}).then(res=>{
      if(res.status === 201){
          return res.json();
      } else {
        setSnackMessage("error occured");
        setSnackOpen(true);
        setSnackSeverity("error");
        toggle();
        setAddUser({"email":"","password":"","admin":false,"is_active":false});
        throw "temp";
      }
    })
    .then((res) => {
        console.log(res); 
        refreshList();
        setSnackMessage("user added successfully");
        setSnackOpen(true);
        setSnackSeverity("success");
        setAddUser({"email":"","password":"","admin":false,"is_active":false});
        toggle();
    }).catch(e=>{
        console.log(e);
    })
  }

  const deleteUser = (index) => {
    //taking confirmation from user
    if (window.confirm('Are you sure to delete the user '+index)) {
      // Delete it!
      let jsonRequest = JSON.stringify({uid: users[index].uid})
      console.log(jsonRequest);
      let URL = "/admin/delete_user/";
      fetch(URL, {method:'DELETE', headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, body:jsonRequest}).then(res=>{
      if(res.status === 200){
          setSnackMessage("user deleted successfully");
          setSnackOpen(true);
          setSnackSeverity("success");
          return res.json();
      } else {
        setSnackMessage("error occured while deleting user");
        setSnackOpen(true);
        setSnackSeverity("error");
        throw "temp";
      }
      })
      .then((res) => {
          console.log(res);
          refreshList();
      }).catch(e=>{
          console.log(e);
          refreshList();
      })
    } 
    else {
      // Do nothing!
      // console.log('deletion aborted');
      setSnackMessage("deletion of user aborted");
      setSnackOpen(true);
      setSnackSeverity("info");
    }
  }

  const handleEditUser = (index) => {
    setEditUser({...editUser,"uid":users[index].uid, "email_id": users[index].email_id,"password": "", "admin":users[index].admin, "is_active":users[index].is_active});
    setEditModalOpen(true);
  }

  // console.log(users);
  return (
      <>
      <VideoCreatorLogo />
      <div className='adminSettings'>    

        <div className='user_info_all_admin'>
          {
            users.map((user,index)=>
            // users.map((user,index)=>
               (
                <div className="user_info_row" key={user.uid}>
                  <div className='user_info_details'>
                    <div className='user_info_email'>
                      {user.email_id}
                    </div>
                    <div className='user_info_uid'>
                      {user.uid}
                    </div>
                    <div className='user_info_status' style={user.is_active ? {color: "#499037"} : {color: "#3C423B"}}>
                      {user.is_active ? "Active": "Inactive"}
                    </div>
                  </div>
                  <div className='user_info_other_details'>
                    <div className='user_info_type' style={user.admin ? {color: "#FF8E09"} : {color: "#3C423B"}}>
                      {user.admin ? "Admin" : "Member"}
                    </div>
                    <div className='user_info_edit_options'>
                      <img src = {EditLogo} alt = "editLogo" className='admin_sett_editLogo' onClick={() => {handleEditUser(index)}}/>
                      <img src = {TrashLogo} alt = "trashLogo" className='admin_sett_trashLogo' onClick={() => {deleteUser(index)}}/>
                    </div>
                  </div>
                </div>
                )
            )
          }

        </div> 
        <div 
            className='change_button'
            onClick={(e) => {
              toggle()
            }}
            style={{marginTop: "20px"}}
        >
          Add user
        </div>

      </div>
      <div>
        <Modal 
          isOpen={modalOpen}
          toggle={toggle}
          // size="sm"
          className ='adminSettings_modal'
        >
          <ModalBody
            className='add_user_modal'
          >
            <h2>Add user</h2>
            <Form className='add_user_modal_form'>
              <FormGroup>
                <Input 
                  type="text" 
                  name="email" 
                  id="newUser_Email"
                  value={addUser.email} 
                  placeholder='Email id' 
                  className='input_style_admin'
                  onChange={ (e)=>{
                    setAddUser({...addUser, "email": e.target.value})
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Input type="password" 
                  name="password" 
                  id="newUser_password" 
                  value={addUser.password} 
                  placeholder="Password" 
                  className='input_style_admin' 
                  onChange={ (e)=>{
                    setAddUser({...addUser, "password": e.target.value})
                  }}
                />
              </FormGroup>
              <div className='add_user_toggle'>
                <p>Admin</p>
                <Switch
                  checked={addUser.admin}
                  onChange={handleAdminChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="warning"
                />                
              </div>
              <div className='add_user_toggle'>
                <p>Active</p>
                <Switch
                  checked={addUser.is_active}
                  onChange={handleActiveChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="warning"
                />                
              </div>
            
              <div onClick={() => {
                  // console.log(addUser);
                  handleAddUser();
                }}
                className='change_button'
              >
                  Add User
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div >
      <div>
        <Modal 
          isOpen={editModalOpen}
          toggle={toggleEdit}
          // size="sm"
          className ='adminSettings_modal'
        >
          <ModalBody
            className='add_user_modal'
          >
            <h2>Edit user</h2>
            <Form className='add_user_modal_form'>
              <FormGroup>
                <Input 
                  type="text" 
                  name="email" 
                  id="newUser_Email"
                  value={editUser.email_id} 
                  placeholder='Email id' 
                  className='input_style_admin'
                  onChange={ (e)=>{
                    setEditUser({...editUser, "email_id": e.target.value})
                  }}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Input type="password" 
                  name="password" 
                  id="newUser_password" 
                  value={editUser.password} 
                  placeholder="Password" 
                  className='input_style_admin' 
                  onChange={ (e)=>{
                    setEditUser({...editUser, "password": e.target.value})
                  }}
                />
              </FormGroup>
              <div className='add_user_toggle'>
                <p>Admin</p>
                <Switch
                  checked={editUser.admin}
                  onChange={handleEditAdminChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="warning"
                />                
              </div>
              <div className='add_user_toggle'>
                <p>Active</p>
                <Switch
                  checked={editUser.is_active}
                  onChange={handleEditActiveChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="warning"
                />                
              </div>
            
              <div onClick={() => {
                  // console.log(editUser);
                  submitEdit()
                }}
                className='change_button'
              >
                  Edit User
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div >
      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
      </>
  );
}

export default AdminSettings;
