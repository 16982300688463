/* eslint-disable */
import React, { Component } from "react";
import SideBarButton from "./CustomNavBarButtons/SideBarButton";

class NavBarVertical extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      sideBarTopButtons = [],
      sideBarBottomButtons = [],
      isSideBarOpen,
      updateSideBarOpen,
      styleNavBarVertical
    } = this.props;

    return (
      <div className="d-flex">
        <div className="d-flex flex-column" style={{"backgroundColor": "#FFF"}}>
          <div className="d-flex flex-column pt-5">
            {sideBarTopButtons.map((buttonData, index) => (
              <SideBarButton
                id={index}
                key={index}
                onClickButton={updateSideBarOpen}
                buttonData={buttonData}
                style={{...styleNavBarVertical}}
              />
            ))}
          </div>
          <div className="flex-grow-1"></div>
          <div className="d-flex flex-column-reverse mb-5 pb-5">
            {sideBarBottomButtons.map((buttonData, index) => (
              <SideBarButton
                id={index + sideBarTopButtons.length}
                key={index}
                onClickButton={buttonData.onClick ||updateSideBarOpen}
                buttonData={buttonData}
                style={{...styleNavBarVertical}}
              />
            ))}
          </div>
        </div>
        <div style={{ background: "#E8E8E8"}}>
          {sideBarTopButtons.map((buttonData, index) => {
            if (index === isSideBarOpen && buttonData.componentToRender)
              return (
                <buttonData.componentToRender
                  key={index}
                  closeExpandedDiv={() => updateSideBarOpen(null)}
                  {...buttonData.props}
                />
              );
            return <div key={index}></div>;
          })}
          {sideBarBottomButtons.map((buttonData, index) => {
            if (index + sideBarTopButtons.length === isSideBarOpen &&
              buttonData.componentToRender
              )
              return (
                <buttonData.componentToRender
                  key={index}
                  closeExpandedDiv={() => updateSideBarOpen(null)}
                  {...buttonData.props}
                />
              );
            return <div key={index}></div>;
          })}
        </div>
      </div>
    );
  }
}

export default NavBarVertical;
