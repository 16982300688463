/* eslint-disable */
import { useState } from "react";
import ThemeTabView from "../../CommonSections/ThemeTabView";
import CollectionsIcon from "@mui/icons-material/Collections";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import VerticalScripts from "./VerticalScripts";
import ScriptsAudio from "./ScriptsAudio";

const VerticalScriptTabs = (props) => {
  const {
    mainContentWidth,
    editorOpen,
    currentSlide,
    setEditorText,
    setAudioFile,
    editorToggle,
    isSideBarOpen,
    initiateOrSaveRequest,
    hidePreview,
    readFile,
    allPlaceholders,
    addAllPlaceholders,
    setAvatarEnabled,
    updateAvatarBGColor,
    initiateAudio,
    showPreview,
    slideslength,
    uploadAudioFile,
    openEditor,
    voiceSpeed,
    changeVoiceSpeed,
  } = props;

  const [tabValue, setTabValue] = useState("colors");

  const tabs = [
    {
      value: "colors",
      label: "Narration",
      icon: <CollectionsIcon />,
    },
    {
      value: "videos",
      label: "Upload Audio",
      icon: <VideoLibraryIcon />,
    },
  ];

  return (
    <div
      className="d-flex flex-column align-items-center h-100"
      style={{ width: "100%" }}
    >
      <div style={{ width: "100%" }}>
        <ThemeTabView
          tabs={tabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
        <div
          className="container mt-3"
          style={{
            height: "160vh",
          }}
        >
          {tabValue === "colors" && (
            <VerticalScripts
              mainContentWidth={mainContentWidth}
              editorOpen={editorOpen}
              currentSlide={currentSlide}
              setEditorText={setEditorText}
              setAudioFile={setAudioFile}
              uploadAudioFile={uploadAudioFile}
              editorToggle={editorToggle}
              openEditor={openEditor}
              isSideBarOpen={isSideBarOpen}
              initiateOrSaveRequest={initiateOrSaveRequest}
              initiateAudio={initiateAudio}
              showPreview={showPreview}
              hidePreview={hidePreview}
              allPlaceholders={allPlaceholders}
              addAllPlaceholders={addAllPlaceholders}
              setAvatarEnabled={setAvatarEnabled}
              updateAvatarBGColor={updateAvatarBGColor}
              slideslength={slideslength}
              readFile={readFile}
              voiceSpeed={voiceSpeed}
              changeVoiceSpeed={changeVoiceSpeed}
            />
          )}
          {tabValue === "videos" &&
            (editorOpen,
            currentSlide,
            setEditorText,
            setAudioFile,
            isSideBarOpen,
            slideslength,
            (
              <ScriptsAudio
                editorOpen={editorOpen}
                currentSlide={currentSlide}
                setEditorText={setEditorText}
                setAudioFile={setAudioFile}
                uploadAudioFile={uploadAudioFile}
                initiateAudio={initiateAudio}
                showPreview={showPreview}
                hidePreview={hidePreview}
                allPlaceholders={allPlaceholders}
                addAllPlaceholders={addAllPlaceholders}
                setAvatarEnabled={setAvatarEnabled}
                updateAvatarBGColor={updateAvatarBGColor}
                slideslength={slideslength}
                readFile={readFile}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalScriptTabs;
