/* eslint-disable */
import { useEffect, useState } from "react";

import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { getToken } from "common/Authentication";
import Modal from "@material-ui/core/Modal";
import Dropzone from "../MainEditorComponents/Dropzone";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import HeadsetIcon from "@mui/icons-material/Headset";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import LightTooltip from "components/LightTooltip/LightTooltip";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TwitterPicker } from "react-color";
import { colorArray } from "utils/colors";
import IconButton from "@mui/material/IconButton";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Preview from "../Sections/Preview";
import PlayIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import PauseIcon from "@material-ui/icons/PauseCircleOutlineOutlined";

import Loader from "react-loader-spinner";
import GeneratePlaceHolderAndSilence from "../MainEditorComponents/GeneratePlaceholderAndSilence";
import Editor from "../MainEditorComponents/TextEditor";
import { Box, Button } from "@mui/material";
import Dropzone2 from "../MainEditorComponents/Dropzone2";

const ScriptsAudio = (props) => {
  const {
    editorOpen,
    currentSlide,
    setEditorText,
    setAudioFile,
    isSideBarOpen,
    slideslength,
  } = props;

  var currentAvatarBackgroundColor =
    slideslength !== 0 && currentSlide["backgroundColorAvatar"];

  var preview_audio_processing = currentSlide.preview_audio_processing;

  var preview_audio_url = currentSlide.preview_audio_url;

  var editorText = currentSlide.text;
  var preview_panel_display = currentSlide.preview_panel_display;

  var editorAudio = currentSlide.audio;

  const [upload_modal, setUploadModal] = useState(false);

  const [alert_modal, setAlertModal] = useState(false);
  let content = null;
  const [openModal, setModalOpen] = useState(false);

  const handleModalClose = () => setModalOpen(false);

  const toggleUploadModal = () => {
    setUploadModal(!upload_modal);
  };

  const toggleAlertModal = () => {
    setAlertModal(!alert_modal);
  };

  const initiateSlidesFromPpt = (slides) => {
    props.initiateSlidesFromPpt(slides);
  };

  const handleModalOpen = () => setModalOpen(true);

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    height: 600,
    p: 4,
  };

  return (
    <div
    
      style={{ width: "100%" }}
    >
      <div
        className="flex-grow-1"
        style={{
          border: "2px solid #D7D7D7",
          height: "100%",
          padding: "5px",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Dropzone
          file={editorAudio && editorAudio.file}
          onFilesAdded={setAudioFile}
          status={editorAudio && editorAudio.status}
          containerStyle={{
            margin: "0",
            height: `${isSideBarOpen !== null ? "270px" : "100px"}`,
          }}
        />
      </div>
    </div>
  );
};

export default ScriptsAudio;
