import React, { useState, useEffect } from 'react'
import Alert from '../../components/Alert/Alert.js'
import { getPlanDetails } from "common/BillingPlan";

const LowCreditAlert = () => {
    const [credits, setCredits] = useState();
    const [lowCredits, setLowCredits] = useState(false)

    useEffect(() => {
        getPlanDetails().then((res) => {
            setCredits(res?.credits)
            setLowCredits(res?.lowCredits)
        }).catch(err => console.log(err))
    }, [])
    if (lowCredits) {
        return (
            <Alert
                type="warning"
                variant="standard"
                title="Low Credit!"
                text={`You have only ${credits} credits left.`}
                redirectText="Topup"
                redirectPath="/app/profile/billingInfo"
                style={{position:"fixed"}}
            />
        )
    } else {
        return <></>
    }
}

export default LowCreditAlert