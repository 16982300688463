import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import start from "./start.png";

const PlayButton = (props) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const buttonStyle = {
    background: '#2196F3',
    color: '#ffffff',
    border: 'none',
    padding: '7px 15px',
    borderRadius: '12px',
    fontSize: '14px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginRight:"10px"
  };

  const iconStyle = {
    marginLeft: '10px',
  };

  return (
    <>
      <Button 
        onClick={()=>{setModalOpen(true)}}
        style={buttonStyle}
      >
           Play <span style={iconStyle}>&#9654;</span>
      </Button>
      <Modal
        open={isModalOpen}
        onClose={()=>{setModalOpen(false)}}
        style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}
      >
        <div>
          <video src={props.videoUrl} style={{width: "70vw", maxHeight: "70vh"}} controls/>
        </div>
      </Modal>
    </>
  )
}
export default PlayButton;