import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { Modal, ModalBody, ModalHeader,FormGroup} from "reactstrap";
import MenuItem from '@mui/material/MenuItem';
import React,{useEffect,useState} from 'react';
import { getToken } from "common/Authentication";
import Button from '@mui/material/Button';
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import MuiPhoneNumber from 'material-ui-phone-number';
import CircularProgress from '@mui/material/CircularProgress';
import { isValidPhoneNumber } from 'react-phone-number-input';

const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const WhatsAppModel = ({shareButtonRequestId,openWhatsAppModel,setWhatsAppModel}) => {
    const [placeholderValues,setPlaceHoldervalues]=useState([]);
    const [templateName,setTemplateName]=useState("");
    const [whatsAppNumber,setWhatsAppNumber]=useState("");
    const [namespace,setNamespace]=useState("");
    const [isLoading,setLoading]=useState(false);
    const [isErrorSuccess,setErrorSuccess]=useState("default");
    const [errorSuccessMessage,setErrorSuccessMessage]=useState("");
    const [whatsAppTemplates,setWhatsAppTemplateData]=useState(null);

    useEffect(()=>{
        //fetching whatsapp templates
        axios({
            method: 'GET',
            url: '/dissemination/wa-template/all',
            headers: {
                Authorization:'Bearer ' + getToken(),
                RequestTime: new Date()
            }
            }).then((res)=>{
              if(res.status===200){
                let approved_templates = {};
                Object.entries(res.data.data).forEach((elem, _)=>{
                  if(elem[1].status === "approved"){
                      approved_templates[elem[0]]=elem[1];
                  }
                });
                setWhatsAppTemplateData(approved_templates);
            }
        });
    },[]);

    const reset=(resetErrorSuccess=false)=>{
        if(resetErrorSuccess){
            setErrorSuccess("default");
            setErrorSuccessMessage("");
            return;
        }
        setWhatsAppNumber("");
        setTemplateName("");
        setNamespace("");
        setPlaceHoldervalues([]);
    }
    const settingErrorMessage=(errorMessage)=>{
        setErrorSuccess("error");
        setErrorSuccessMessage(errorMessage);
    }
    const validation=()=>{
        if(templateName === ""){
            settingErrorMessage("please select one template");
            return false;
        }
        let isAnyPlaceholderValueEmpty=false;
        for(let i=0;i<placeholderValues.length;i++){
              if(placeholderValues[i] === ""){
                  isAnyPlaceholderValueEmpty=true;
                  break;
              }
        }
        if(isAnyPlaceholderValueEmpty){
            settingErrorMessage("placeholder value can't be empty");
            return false;
        }
        if(whatsAppNumber === ""){
            settingErrorMessage("whatsapp number can't be empty");
            return false;
        }
        return true;
    }
    const selectTemplate=(e)=>{
        reset();
        reset(true);
        const selectedTemplateIndex=parseInt(e.target.value);

        const templateDetailsObject=Object.entries(whatsAppTemplates)[selectedTemplateIndex][1];
        setTemplateName(templateDetailsObject.name);
        setNamespace(templateDetailsObject.namespace);
        const placeholder=templateDetailsObject.text;
        const arrayOfPlaceholderValues=placeholder.match(/[^{{\}]+(?=}})/g);
        const maxPlaceHolder=arrayOfPlaceholderValues !== null ? Math.max(...arrayOfPlaceholderValues) : 0;
        const tempPlaceHolderValues=[];
        for(let j=1;j<=maxPlaceHolder;j++){
            tempPlaceHolderValues.push("");
        }
        setPlaceHoldervalues(tempPlaceHolderValues);
    }
    const getPlaceholdervalue=(e,index)=>{
         let copied=[...placeholderValues];
         copied[index]=e.target.value;
         setPlaceHoldervalues(copied);
    }
    const closeModel=()=>{
        setWhatsAppModel(!openWhatsAppModel);
        setErrorSuccess("default");
        setErrorSuccessMessage("");
        setLoading(false);
        reset();
    }
    const sendTemplatetoWhatsApp=async ()=>{
        if(!validation()){
            return;
        }
        let validTrim=whatsAppNumber.replaceAll(/[\-|\(|\)|\s]/g, '');
        const isPhoneLengthValid = isValidPhoneNumber(validTrim);
        if(!isPhoneLengthValid){
            settingErrorMessage("enter valid phone number");
            return;
        }
        //making post request
        const trimmedPlaceholderValues=[];
        for(let i=0;i<placeholderValues.length;i++){
            trimmedPlaceholderValues[i]=placeholderValues[i].trim();
        }
        let whatsAppNumberTrimed=whatsAppNumber.replaceAll(/[\+|\-|\(|\)|\s]/g, '');
        setLoading(true);
        try{
            const results=await axios.post("/t2v/post-process/send_whatsapp",{
                                                wa_template_name:templateName,
                                                wa_placeholders:trimmedPlaceholderValues,
                                                wa_number:whatsAppNumberTrimed,
                                                namespace:namespace,
                                                request_id:shareButtonRequestId
                                            },
                                            {
                                                headers:{
                                                            Authorization:'Bearer ' + getToken(),
                                                            RequestTime:new Date()
                                                        }
                                            }
                                        );
            setErrorSuccess("success");
            setErrorSuccessMessage("video sent successfully");
            setTimeout(()=>{
                closeModel();
            },2000);
        }catch(err){
            setErrorSuccess("error");
            setErrorSuccessMessage("something went wrong,please try again");
        }
        setLoading(false);
    }
    const gridTwo={display:"grid",gridTemplateColumns:"auto auto"};
    const gridOne={display:"grid",gridTemplateColumns:"auto"};
    return (
    <>
    {whatsAppTemplates !== null &&  <Modal 
                                      toggle={()=>closeModel()} 
                                      isOpen={openWhatsAppModel}
                                      size="lg">
            <ModalHeader className='m-3'>
                <Button 
                    style={{position:"absolute",right:"10px",top:"12px"}}
                    onClick={()=>closeModel()}
                >
                    <CloseIcon
                       style={{textAlign:"center",color:"#E7901F"}}
                    />
                </Button>
            </ModalHeader>
            <ModalBody className='m-3'>
                <FormControl fullWidth>
                        <InputLabel id="category_label">Select Template</InputLabel>
                        <Select
                            labelId='category_label'
                            inputProps={{
                                name: "category"
                            }}
                            label="Select Template"
                            onChange={(e)=>selectTemplate(e)}
                        >
                        
                            {Object.entries(whatsAppTemplates).map((name,index) => (
                                    <MenuItem 
                                        value={index}
                                        key={name[1].name}
                                    >
                                        {name[1].name}
                                    </MenuItem>
                            ))}
                        </Select>
                        <br/>
{placeholderValues.length !== 0 && <FormGroup>    
                        <div style={placeholderValues.length === 1 ? gridOne:gridTwo}>           
                            {
                                placeholderValues.map((placeholder,index)=>{
                                    return  <FormGroup key={index} className="fullWidth">   
                                                <TextField 
                                                        variant="outlined"
                                                        label={`{{${index+1}}}`}
                                                        fullWidth
                                                        placeholder={`{{${index+1}}}`}
                                                        name={`placeholder${index+1}`}
                                                        value={placeholder}
                                                        onChange={(e)=>getPlaceholdervalue(e,index)}
                                                />
                                            </FormGroup> 
                                })
                            }
                        </div> 
                    </FormGroup>}
                    {templateName !== "" && <FormGroup>
                        <MuiPhoneNumber 
                            defaultCountry={'in'} 
                            fullWidth
                            variant="outlined"
                            onChange={(e)=>setWhatsAppNumber(e)}
                        />
                    </FormGroup>}
                    <div className='text-center m-4'>
                        {
                            isLoading ? <CircularProgress style={{color:"#E7901F"}}/>:
                            <Button className='send' 
                                style={{backgroundColor:"#E7901F",color:"white",padding:"15px"}}
                                onClick={()=>sendTemplatetoWhatsApp()}
                            >
                                SEND ON WHATSAPP</Button>
                        }
                    </div>
                </FormControl>
            </ModalBody>
                {isErrorSuccess !== "default" && <Alert severity={isErrorSuccess} style={{width:"40%",margin:"10px"}}>{errorSuccessMessage}</Alert>}
            </Modal>
        }
    </>
)
}
export default WhatsAppModel;