import React from 'react'
import VideoCreatorIcon from "assets/img/video_icon.png";

const VideoCreatorLogoContainer = () => {
  return (
    <div className='w-100 text-end'>
        <img className="login_title_profile" src={VideoCreatorIcon} alt="logo" />
    </div>
  )
}

export default VideoCreatorLogoContainer