const Pexels_default_Videos = [
  {
      "id": 2098988,
      "url": "https://www.pexels.com/video/beautiful-sight-of-nature-2098988/",
      "image": "https://images.pexels.com/videos/2098988/free-video-2098988.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/328427476.hd.mp4?s=ddd13e964fd6c13d9b61cc52513a545533ad1c02&profile_id=172&oauth2_token_id=57447761"
  },
  
  {
      "id": 1723017,
      "url": "https://www.pexels.com/video/view-of-a-beautiful-horizon-1723017/",
      "image": "https://images.pexels.com/videos/1723017/free-video-1723017.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/308162286.hd.mp4?s=aaa2aefa193a0530581ec131626451131182e89d&profile_id=174&oauth2_token_id=57447761"
  },
  {
      "id": 3611031,
      "url": "https://www.pexels.com/video/black-and-white-video-3611031/",
      "image": "https://images.pexels.com/videos/3611031/free-video-3611031.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/386511208.hd.mp4?s=0774dafc4379b0b6ef892b7e4aa9bd9608ebb6af&profile_id=175&oauth2_token_id=57447761"
  },
  {
      "id": 2646392,
      "url": "https://www.pexels.com/video/a-sky-full-of-stars-at-night-2646392/",
      "image": "https://images.pexels.com/videos/2646392/free-video-2646392.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/347584603.sd.mp4?s=c0d5cc4d28790bdb56bffcdccc9fbde0a4a8f926&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 5818973,
      "url": "https://www.pexels.com/video/starry-night-sky-timelapse-5818973/",
      "image": "https://images.pexels.com/videos/5818973/background-videos-little-star-night-night-sky-5818973.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/476642450.hd.mp4?s=6eaf840471cbcf97c514b7b372d30206a819ef55&profile_id=170&oauth2_token_id=57447761"
  },
  {
      "id": 6933752,
      "url": "https://www.pexels.com/video/close-up-shot-of-snow-falling-6933752/",
      "image": "https://images.pexels.com/videos/6933752/background-beautiful-beauty-branch-6933752.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/515972707.hd.mp4?s=0a89d37ca02ed776fbaecf29a929c1f33ea28d65&profile_id=174&oauth2_token_id=57447761"
  },
  {
      "id": 2141798,
      "url": "https://www.pexels.com/video/waves-crashing-to-the-rocks-2141798/",
      "image": "https://images.pexels.com/videos/2141798/free-video-2141798.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/330033045.hd.mp4?s=bf2e49bf669b15008032159306991d94bd8f41ec&profile_id=175&oauth2_token_id=57447761"
  },
  {
      "id": 2344545,
      "url": "https://www.pexels.com/video/pebbles-on-the-shore-2344545/",
      "image": "https://images.pexels.com/videos/2344545/free-video-2344545.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/337468577.sd.mp4?s=05b83446b8174369307c34c48883603faedd4f1c&profile_id=164&oauth2_token_id=57447761"
  },
  {
      "id": 9150545,
      "url": "https://www.pexels.com/video/close-up-video-of-a-wavy-plastic-9150545/",
      "image": "https://images.pexels.com/videos/9150545/3d-3d-render-abstract-airy-9150545.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/586046400.sd.mp4?s=5e3745a9d72caeb4d91f01b4ff2a345797de15d3&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 4471213,
      "url": "https://www.pexels.com/video/scenery-of-relaxing-farm-field-during-daytime-4471213/",
      "image": "https://images.pexels.com/videos/4471213/4k-background-farm-field-field-4471213.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/422094058.hd.mp4?s=52144f6d0d1ed2fce56b96fc8c9d6cbefb51ba66&profile_id=173&oauth2_token_id=57447761"
  },
  {
      "id": 5736053,
      "url": "https://www.pexels.com/video/waterfall-in-the-forest-5736053/",
      "image": "https://images.pexels.com/videos/5736053/background-beautiful-beauty-borneo-5736053.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/473657335.hd.mp4?s=e1d392e9c8222c337de1b7801a9dbb2489222751&profile_id=175&oauth2_token_id=57447761"
  },
  {
      "id": 5490419,
      "url": "https://www.pexels.com/video/macro-photography-of-grass-leaves-with-the-sunset-in-the-background-5490419/",
      "image": "https://images.pexels.com/videos/5490419/background-biology-botany-closeup-5490419.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/463731166.sd.mp4?s=47fda7208c0963a7774cf388b66543bfc2c344a6&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 5607650,
      "url": "https://www.pexels.com/video/horseback-riding-on-top-of-plateau-meadow-5607650/",
      "image": "https://images.pexels.com/videos/5607650/activity-adventure-aerial-aerial-view-5607650.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/468454040.hd.mp4?s=5d02fb404dee31475bd72cfaa1dfe2e57fff31be&profile_id=174&oauth2_token_id=57447761"
  },
  {
      "id": 905045,
      "url": "https://www.pexels.com/video/905045/",
      "image": "https://images.pexels.com/videos/905045/free-video-905045.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/257722028.sd.mp4?s=be540c556a9f8c5b185ecbf7d36a969fc22fb1bb&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 4884242,
      "url": "https://www.pexels.com/video/laptop-computer-on-white-surface-near-white-lampshade-4884242/",
      "image": "https://images.pexels.com/videos/4884242/4k-apple-background-blank-4884242.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/439043642.hd.mp4?s=ca110d687b2d4646ede19bc1b4155f372ff2e019&profile_id=170&oauth2_token_id=57447761"
     
      
  },
  {
      "id": 4182910,
      "url": "https://www.pexels.com/video/digital-illustration-of-a-galaxy-4182910/",
      "image": "https://images.pexels.com/videos/4182910/astro-astronomy-august-background-4182910.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/409130143.sd.mp4?s=36ac0761cb27de33c903b501267c87d38e5dca3f&profile_id=164&oauth2_token_id=57447761"
  },
  {
      "id": 5608091,
      "url": "https://www.pexels.com/video/drone-footage-of-sunflower-field-under-blue-sky-5608091/",
      "image": "https://images.pexels.com/videos/5608091/above-aerial-agricultural-agriculture-5608091.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/468472559.sd.mp4?s=bacba592aa89a2e87426af7599ed40cbc6aae2b2&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 5651047,
      "url": "https://www.pexels.com/video/scenery-of-starry-night-sky-5651047/",
      "image": "https://images.pexels.com/videos/5651047/pexels-photo-5651047.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/470202385.sd.mp4?s=c03a7ba6a54a7d61019574a1834cc76ae7d09f8d&profile_id=164&oauth2_token_id=57447761"
  },
  {
      "id": 7677115,
      "url": "https://www.pexels.com/video/wet-colorful-flowers-7677115/",
      "image": "https://images.pexels.com/videos/7677115/pexels-photo-7677115.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/542219634.hd.mp4?s=bf6be4d9c1f444ac18149914f39e784291242f46&profile_id=175&oauth2_token_id=57447761"
  },
  {
      "id": 2217343,
      "url": "https://www.pexels.com/video/view-of-beach-with-blue-waters-2217343/",
      "image": "https://images.pexels.com/videos/2217343/free-video-2217343.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/332093649.hd.mp4?s=8f82d8ac18d1ed6cec255d459261d0c3605e65d6&profile_id=175&oauth2_token_id=57447761"
  },
 
  {
      "id": 4508069,
      "url": "https://www.pexels.com/video/slow-motion-footage-of-liquid-paint-4508069/",
      "image": "https://images.pexels.com/videos/4508069/pexels-photo-4508069.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/423827155.sd.mp4?s=b755cb9838e95e8efaa588dfa80bdfb1fcdbd40a&profile_id=139&oauth2_token_id=57447761"
  },
  {
      "id": 1723096,
      "url": "https://www.pexels.com/video/small-waves-and-ripples-of-water-1723096/",
      "image": "https://images.pexels.com/videos/1723096/free-video-1723096.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/308166373.hd.mp4?s=005592c684857c1337d510b50ead260a49ceaa1a&profile_id=170&oauth2_token_id=57447761"
  },
  {
      "id": 1730393,
      "url": "https://www.pexels.com/video/selective-focus-video-of-grass-on-a-windy-day-1730393/",
      "image": "https://images.pexels.com/videos/1730393/free-video-1730393.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/308468188.hd.mp4?s=e269e6881aa7509b074fcd8e76f0c1c6bc1bc8d2&profile_id=172&oauth2_token_id=57447761"
  },
  {
      "id": 3051489,
      "url": "https://www.pexels.com/video/dripping-of-white-liquid-on-a-transparent-surface-3051489/",
      "image": "https://images.pexels.com/videos/3051489/free-video-3051489.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/365127765.hd.mp4?s=1fd2480c57f03343b3f8443f4e3b422860467f85&profile_id=172&oauth2_token_id=57447761"
  },
  {
      "id": 2223417,
      "url": "https://www.pexels.com/video/view-of-sea-with-beautiful-horizon-2223417/",
      "image": "https://images.pexels.com/videos/2223417/free-video-2223417.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/332205067.sd.mp4?s=9881e16a8b08452ea4cf8a021d92b145728c5c25&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 3536308,
      "url": "https://www.pexels.com/video/the-calm-surface-of-a-deep-water-3536308/",
      "image": "https://images.pexels.com/videos/3536308/free-video-3536308.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/383214298.hd.mp4?s=3b523bb666669ac3ab3355e2ec5ac94dc98d0f01&profile_id=172&oauth2_token_id=57447761"
      
  },
  {
      "id": 4835993,
      "url": "https://www.pexels.com/video/video-footage-of-rising-smokes-in-black-and-white-film-4835993/",
      "image": "https://images.pexels.com/videos/4835993/animation-background-effects-loop-4835993.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/436783268.sd.mp4?s=c90fd85889fa735483bb068f3ace1d93ac9cd1ab&profile_id=139&oauth2_token_id=57447761"
  },
  {
      "id": 2344546,
      "url": "https://www.pexels.com/video/ship-sailing-across-the-sea-2344546/",
      "image": "https://images.pexels.com/videos/2344546/free-video-2344546.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/337468788.hd.mp4?s=9d2297cc0d2ac66d6004a4513aaa0bd88e213692&profile_id=172&oauth2_token_id=57447761"
  },
  {
      "id": 5738601,
      "url": "https://www.pexels.com/video/tree-trunk-5738601/",
      "image": "https://images.pexels.com/videos/5738601/pexels-photo-5738601.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/473759876.sd.mp4?s=d26174ab949ec7bdcff06fda8c4aa2547d824967&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 5738708,
      "url": "https://www.pexels.com/video/holding-a-hand-towards-the-trees-5738708/",
      "image": "https://images.pexels.com/videos/5738708/adult-adventure-alone-australia-5738708.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/473766283.hd.mp4?s=0015c158078ef8ca7eeb467596f927e6ee5d04e1&profile_id=174&oauth2_token_id=57447761"
  },
  {
      "id": 3662280,
      "url": "https://www.pexels.com/video/people-spending-their-day-in-a-beach-3662280/",
      "image": "https://images.pexels.com/videos/3662280/free-video-3662280.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/388712350.sd.mp4?s=b2194da25b929b1c5aa2ff0152671a361ce69b09&profile_id=165&oauth2_token_id=57447761"
  },
  {
      "id": 3290093,
      "url": "https://www.pexels.com/video/rocks-formation-on-a-sea-coast-3290093/",
      "image": "https://images.pexels.com/videos/3290093/free-video-3290093.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
      "link": "https://player.vimeo.com/external/375838238.hd.mp4?s=6409c975980fc086bc1c78371b490e086c333be8&profile_id=172&oauth2_token_id=57447761"
  },

]
export {Pexels_default_Videos}