/*eslint-disable*/
import { Typography } from "@mui/material";
import lostTourist from "assets/img/LostTourist.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      style={{ height: "100vh", backgroundColor: "#55efc4" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <img src={lostTourist} width={400} alt="" />
      <Typography variant="h3" className="mt-4">
        Hmm! Seems like this page is not on the map.
      </Typography>
      <Typography variant="subtitle2" className="mt-1">
        The page you are looking for either don't exist or just don't wanna show
        up.
      </Typography>
      <Link to="/app" className="btn btn-dark mt-3">Go Back To HomePage</Link>
    </div>
  );
};

export default NotFound;
