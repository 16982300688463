/* eslint-disable */
import React, { useState } from "react";
import classNames from "classnames";
import Header from "./header";
import ListElement from "./listElement";
import { Button } from "@material-ui/core";

function AudioLanding(props) {
  return (
    <div className="d-flex" style={{ height: "90%" }}>
      <div
        style={{ width: "90%", height: "85%" }}
        className={classNames("_font", "bg-light", "w-100")}
      >
        <Header/>
        <ListElement/>
      </div>
    </div>
  );
}
export default AudioLanding;
